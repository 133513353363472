import { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IAdmin } from '../types';

type TReturnValue = {
  isLoading: boolean;
  admin?: IAdmin;
};

export default function useFetchAdmin(adminId?: string): TReturnValue {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [admin, setAdmin] = useState<IAdmin>();

  const fetchAdmin = useCallback(async () => {
    if (!adminId) {
      return;
    }

    setIsLoading(true);

    const adminRef = doc(db, 'admins', adminId);
    const adminDoc = await getDoc(adminRef);

    setAdmin({
      uid: adminDoc.id,
      ...adminDoc.data(),
    } as IAdmin);
    setIsLoading(false);
  }, [adminId]);

  useEffect(() => {
    fetchAdmin();

    return () => {
      setAdmin(undefined);
    };
  }, [fetchAdmin]);

  return {
    admin,
    isLoading,
  };
}
