import { Box, Text } from '@chakra-ui/react';
import { IPatient } from '../../../../../../types';
import PatientRow from '../PatientRow';

interface IProps {
  onRemovePatient: (patientUid: string) => void;
  patients: IPatient[];
}

export default function SelectedPatients({
  onRemovePatient,
  patients,
}: IProps): JSX.Element {
  if (!patients.length) {
    return (
      <Box p={4} textAlign="center">
        <Text fontWeight="medium">No Patients Selected</Text>
        <Text color="gray.600" fontSize="sm">
          Search for patients to add to this broadcast.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        borderBottom="1px solid"
        borderColor="gray.200"
        color="gray.600"
        display="grid"
        fontSize="xs"
        fontWeight="bold"
        gridTemplateColumns="1.5fr .75fr 1fr .5fr"
        overflow="auto"
        p={3}
        px={4}
        textTransform="uppercase"
      >
        <Box>Patient Name</Box>
        <Box>DOB</Box>
        <Box>Start Date</Box>
        <Box />
      </Box>
      {patients.map((patient, idx) => (
        <PatientRow
          key={patient.uid}
          hasBorder={idx !== patients.length - 1}
          hasHover={false}
          onRemove={() => onRemovePatient(patient.uid)}
          patient={patient}
        />
      ))}
    </Box>
  );
}
