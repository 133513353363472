import { Icon, IconProps } from '@chakra-ui/react';

export default function ExternalLinkFilled(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="open">
          <g id="Vector">
            <path
              d="M5.25 7.125C5.17586 7.12497 5.10339 7.10295 5.04175 7.06175C4.98012 7.02054 4.93208 6.96198 4.90371 6.89348C4.87534 6.82498 4.86791 6.74961 4.88237 6.67689C4.89682 6.60417 4.93251 6.53737 4.98492 6.48492L8.68664 2.7832C8.49507 2.67919 8.28049 2.6248 8.0625 2.625H2.4375C2.08952 2.62537 1.75589 2.76377 1.50983 3.00983C1.26377 3.25589 1.12537 3.58952 1.125 3.9375V9.5625C1.12537 9.91048 1.26377 10.2441 1.50983 10.4902C1.75589 10.7362 2.08952 10.8746 2.4375 10.875H8.0625C8.41048 10.8746 8.74411 10.7362 8.99017 10.4902C9.23623 10.2441 9.37463 9.91048 9.375 9.5625V3.9375C9.3752 3.71951 9.32082 3.50493 9.2168 3.31336L5.51508 7.01508C5.48034 7.04999 5.43902 7.07768 5.39353 7.09654C5.34803 7.11541 5.29925 7.12508 5.25 7.125Z"
              fill="black"
              fillOpacity="0.92"
            />
            <path
              d="M10.5 1.125H7.875C7.77554 1.125 7.68016 1.16451 7.60983 1.23483C7.53951 1.30516 7.5 1.40054 7.5 1.5C7.5 1.59946 7.53951 1.69484 7.60983 1.76516C7.68016 1.83549 7.77554 1.875 7.875 1.875H9.59461L8.68664 2.7832C8.91086 2.905 9.095 3.08914 9.2168 3.31336L10.125 2.40539V4.125C10.125 4.22446 10.1645 4.31984 10.2348 4.39016C10.3052 4.46049 10.4005 4.5 10.5 4.5C10.5995 4.5 10.6948 4.46049 10.7652 4.39016C10.8355 4.31984 10.875 4.22446 10.875 4.125V1.5C10.875 1.40054 10.8355 1.30516 10.7652 1.23483C10.6948 1.16451 10.5995 1.125 10.5 1.125Z"
              fill="black"
              fillOpacity="0.92"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
}
