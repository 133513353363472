import { useState } from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { Events, mixpanel } from '../../../analytics';
import { clinicalReviewSnooze } from '../../../api';
import { Moon } from '../../../assets';
import { ClinicalReviewStates, IPatient } from '../../../types';

interface Props {
  onUpdate: () => void;
  patient: IPatient;
}

export default function SnoozeButton({
  onUpdate,
  patient,
}: Props): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const { clinicalReviewState } = patient;

  if (clinicalReviewState.state !== ClinicalReviewStates.CHASE_LIST) {
    return null;
  }

  async function handleOnClick() {
    setIsLoading(true);

    try {
      await clinicalReviewSnooze({ patientUid: patient.uid });

      mixpanel.track(Events.PATIENT_CLINICAL_REVIEW_STATE_SNOOZED);

      toast({
        description: 'Patient snoozed',
        status: 'success',
        title: 'Success',
      });

      await onUpdate();
    } catch (error) {
      toast({
        description: 'Failed to snooze patient',
        status: 'error',
        title: 'Error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      isLoading={isLoading}
      onClick={handleOnClick}
      variant="ghost"
      size="lg"
    >
      <Box>
        <Box alignItems="center" display="flex" gap={1.5} mb={1}>
          <Moon />
          <Text>Snooze</Text>
        </Box>
        <Text fontSize="xs" fontWeight="normal">
          Move patient back to "Automatic Outreach"
        </Text>
      </Box>
    </Button>
  );
}
