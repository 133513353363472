import {
  Text,
  Button,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { IBroadcast } from '../../../types';
import { useRef, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  broadcast?: IBroadcast;
  fetchBroadcast: () => Promise<void>;
}

export default function DismissConfirmationModal({
  isOpen,
  onClose,
  broadcast,
  fetchBroadcast,
}: IProps): JSX.Element {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cancelRef = useRef(null);

  async function onConfirm() {
    await dismissBroadcastErrors();
    onClose();
  }

  async function dismissBroadcastErrors() {
    if (!broadcast) return;
    setIsLoading(true);

    try {
      const broadcastRef = doc(db, 'broadcasts', broadcast.id);

      await updateDoc(broadcastRef, {
        errorsByPatientUid: [],
      });

      await fetchBroadcast();

      toast({
        description: 'Broadcast errors dismissed.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description: 'An error occurred dismissing the broadcast errors.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <AlertDialog
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Dismiss broadcast errors?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              We recommend manually checking whether the broadcast message was
              sent to each patient with an error notification by checking their
              Source profile. If the broadcast was not sent, you can manually
              send it to them in Source. We recommend addressing all errors
              before dismissing error notifications. All errors will be removed
              once dismissed.
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} disabled={isLoading}>
              No, cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={onConfirm}
              ml={3}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Yes, dismiss
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
