import { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Grid,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import { IPatient } from '../../types';
import { Events, mixpanel } from '../../analytics';

export enum PatientPrograms {
  DIABETES = 'Diabetes',
  HYPERTENSION = 'Hypertension',
  WEIGHT = 'Weight',
}

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

export default function EnrollmentPrompt({
  onUpdate,
  patient,
}: IProps): JSX.Element | null {
  const toast = useToast();
  const { isActive, isEnrolled, uid } = patient;
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false);
  const [showConfirmDeactivation, setShowConfirmDeactivation] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [selectedPrograms, setSelectedPrograms] = useState<PatientPrograms[]>(
    [],
  );
  const hasSelectedProgram = Boolean(selectedPrograms.length);

  if (!isActive || isEnrolled) {
    return null;
  }

  async function handleOnDeactivate() {
    setIsDeactivating(true);

    const updatePatientStatus = httpsCallable(functions, 'updatePatientRecord');

    try {
      await updatePatientStatus({
        data: {
          isActive: false,
        },
        patientUid: uid,
      });

      mixpanel.track(Events.PATIENT_DEACTIVATED);

      toast({
        description: 'Patient deactivated.',
        status: 'success',
        title: 'Success',
      });

      onUpdate();
    } catch (error) {
      toast({
        description: 'An error occurred deactivating the patient.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsDeactivating(false);
    }
  }

  async function handleOnEnroll() {
    setIsEnrolling(true);
    const hypertensionSelected = selectedPrograms.includes(
      PatientPrograms.HYPERTENSION,
    );
    const weightSelected = selectedPrograms.includes(PatientPrograms.WEIGHT);
    const diabetesSelected = selectedPrograms.includes(
      PatientPrograms.DIABETES,
    );

    const enrollPatient = httpsCallable(functions, 'patientEnrollment');

    try {
      await enrollPatient({
        uid,
        enrollInDiabetesProgram: diabetesSelected,
        enrollInHypertensionProgram: hypertensionSelected,
        enrollInWeightProgram: weightSelected,
      });

      mixpanel.track(Events.PATIENT_ENROLLED);

      const description = formatSuccessMessage(selectedPrograms);

      toast({
        description,
        status: 'success',
        title: 'Success',
      });

      onUpdate();
    } catch (error) {
      toast({
        description: 'An error occurred enrolling the patient.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsEnrolling(false);
    }
  }

  return (
    <Box
      bg="gray.50"
      border="1px solid"
      borderColor="blackAlpha.200"
      boxShadow="lg"
      borderRadius="md"
      mx={2}
      mb={4}
    >
      <Box p={4}>
        <Text mb={2} fontWeight="bold">
          Enroll Patient in
        </Text>
        <Grid alignItems="center" templateColumns="auto 1fr max-content">
          <CheckboxGroup
            defaultValue={selectedPrograms}
            value={selectedPrograms}
            onChange={(selectedPrograms: PatientPrograms[]) => {
              // reset deactivate state if clinician selects a program again
              if (showConfirmDeactivation) {
                setShowConfirmDeactivation(false);
              }
              setSelectedPrograms(selectedPrograms);
            }}
          >
            <HStack>
              <Checkbox
                border="1px solid"
                borderColor=" blackAlpha.300"
                borderRadius="md"
                pl={2}
                pr={3}
                py={1.5}
                bg="white"
                value={PatientPrograms.HYPERTENSION}
              >
                <Text fontSize="sm" color="blackAlpha.800">
                  {PatientPrograms.HYPERTENSION}
                </Text>
              </Checkbox>
              <Checkbox
                border="1px solid"
                borderColor=" blackAlpha.300"
                borderRadius="md"
                pl={2}
                pr={3}
                py={1.5}
                bg="white"
                value={PatientPrograms.WEIGHT}
              >
                <Text fontSize="sm" color="blackAlpha.800">
                  {PatientPrograms.WEIGHT}
                </Text>
              </Checkbox>
              <Checkbox
                border="1px solid"
                borderColor=" blackAlpha.300"
                borderRadius="md"
                pl={2}
                pr={3}
                py={1.5}
                bg="white"
                value={PatientPrograms.DIABETES}
              >
                <Text fontSize="sm" color="blackAlpha.800">
                  {PatientPrograms.DIABETES}
                </Text>
              </Checkbox>
            </HStack>
          </CheckboxGroup>
          <Text
            fontSize="xs"
            color="blackAlpha.600"
            fontWeight="bold"
            justifySelf="center"
            letterSpacing="0.48px"
          >
            OR
          </Text>
          <Button
            size="sm"
            bg="white"
            color="red.500"
            isDisabled={!!hasSelectedProgram || isEnrolling}
            justifySelf="flex-end"
            variant="outline"
            onClick={() => setShowConfirmDeactivation(true)}
          >
            Deactivate
          </Button>
        </Grid>
      </Box>
      {hasSelectedProgram && (
        <>
          <Divider borderTop="1px solid" color="blackAlpha.200" />
          <Box p={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="blackAlpha.700" fontSize="sm">
                Enroll patient in {selectedPrograms.length} program
                {selectedPrograms.length > 1 && 's'}?
              </Text>
              <HStack>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={handleOnEnroll}
                  isLoading={isEnrolling}
                  isDisabled={!hasSelectedProgram || isDeactivating}
                >
                  Enroll
                </Button>
                <Button
                  size="sm"
                  bg="white"
                  variant="outline"
                  onClick={() => setSelectedPrograms([])}
                >
                  Cancel
                </Button>
              </HStack>
            </Box>
          </Box>
        </>
      )}
      {showConfirmDeactivation && (
        <>
          <Divider borderTop="1px solid" color="blackAlpha.200" />
          <Box p={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="blackAlpha.700" fontSize="sm">
                Deactivate patient?
              </Text>
              <HStack>
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={handleOnDeactivate}
                  isLoading={isDeactivating}
                >
                  Deactivate
                </Button>
                <Button
                  size="sm"
                  bg="white"
                  variant="outline"
                  onClick={() => setShowConfirmDeactivation(false)}
                >
                  Cancel
                </Button>
              </HStack>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

function formatSuccessMessage(programs: PatientPrograms[]): string {
  if (programs.length === 1) {
    return `Patient enrolled in ${programs[0]} program.`;
  }

  return `Patient enrolled in ${programs.join(' and ')} programs.`;
}
