import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { dayjs } from '../../../utils';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function DaysInStage({ patient }: Props): JSX.Element {
  const { clinicalReviewState } = patient;

  const daysInStage = useMemo(() => {
    if (!clinicalReviewState.date) {
      return '-';
    }

    return dayjs(clinicalReviewState.date.toDate()).fromNow(true);
  }, [clinicalReviewState?.date]);

  return (
    <Text color="blackAlpha.500" fontSize="xs">
      {daysInStage}
    </Text>
  );
}
