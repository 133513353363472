import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Segment } from '../types';

export default function useFetchSegments(): [Segment[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [segments, setSegments] = useState<Segment[]>([]);

  useEffect(() => {
    async function fetchSegments() {
      const segmentsCollection = collection(db, 'segments');
      const querySnapshot = await getDocs(segmentsCollection);
      const segments: Segment[] = [];

      querySnapshot.forEach((doc) => {
        segments.push({
          id: doc.id,
          ...doc.data(),
        } as Segment);
      });

      setSegments(segments);
      setIsLoading(false);
    }

    fetchSegments();
  }, []);

  return [segments, isLoading];
}
