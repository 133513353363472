import { Box, Select } from '@chakra-ui/react';

import { PatientTrendChangeType, PatientTrendTimePeriod } from '../../types';
import ProviderFilterSelector from '../ProviderFilterSelector';
import { Events, mixpanel } from '../../analytics';
import { ProviderIds } from '../../constants';

interface IProps {
  trendChangeType: PatientTrendChangeType;
  setTrendChangeType: React.Dispatch<
    React.SetStateAction<PatientTrendChangeType>
  >;
  trendPeriod: PatientTrendTimePeriod;
  setTrendPeriodType: React.Dispatch<
    React.SetStateAction<PatientTrendTimePeriod>
  >;
  selectedProviderIds: ProviderIds[];
  setSelectedProviderIds: React.Dispatch<React.SetStateAction<ProviderIds[]>>;
}

export default function TrendingFilters({
  trendChangeType,
  setTrendChangeType,
  trendPeriod,
  setTrendPeriodType,
  selectedProviderIds,
  setSelectedProviderIds,
}: IProps): JSX.Element {
  return (
    <Box display="flex" gap={2} alignItems="flex-end">
      <ProviderFilterSelector
        selectedProviderIds={selectedProviderIds}
        setSelectedProviderIds={(ids: ProviderIds[]) => {
          mixpanel.track(Events.TRENDS_FILTERED, {
            filter_type: 'Provider',
          });

          setSelectedProviderIds(ids);
        }}
      />
      <Box display="inline-block">
        <Select
          onChange={({ currentTarget }) => {
            setTrendChangeType(currentTarget.value as PatientTrendChangeType);

            const trendDirection =
              currentTarget.value === PatientTrendChangeType.BETTER
                ? 'Decreasing'
                : 'Increasing';

            mixpanel.track(Events.TRENDS_FILTERED, {
              trend_direction: trendDirection,
            });
          }}
          value={trendChangeType}
          size="sm"
          rounded="md"
        >
          <option value={PatientTrendChangeType.WORSE}>Increasing</option>
          <option value={PatientTrendChangeType.BETTER}>Decreasing</option>
        </Select>
      </Box>

      <Box display="inline-block">
        <Select
          onChange={({ currentTarget }) => {
            setTrendPeriodType(currentTarget.value as PatientTrendTimePeriod);

            const trendPeriod =
              currentTarget.value === PatientTrendTimePeriod.WEEKLY
                ? 'Week over week'
                : 'Month over month';

            mixpanel.track(Events.TRENDS_FILTERED, {
              trend_period: trendPeriod,
            });
          }}
          value={trendPeriod}
          size="sm"
          rounded="md"
        >
          <option value={PatientTrendTimePeriod.WEEKLY}>Week over week</option>
          <option value={PatientTrendTimePeriod.MONTHLY}>
            Month over month
          </option>
        </Select>
      </Box>
    </Box>
  );
}
