import { forwardRef } from 'react';
import { IPatient } from '../../../types';
import { TAB_BAR_HEIGHT } from '../../PatientDetailsPanelColumn';
import { useState } from 'react';
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { CopyFilled } from '../../../assets';
import {
  calculateTotalWeightDelta,
  calculateWeightDeltaPercent,
  constructWeightNarrativeMessage,
} from './utils';
import WeightReadingsSummary from './WeightReadingsSummary';
import WeightReadingsTable from './WeightReadingsTable';

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

const WeightReadings = forwardRef(
  (
    { onUpdate, patient }: IProps,
    ref: React.ForwardedRef<null>,
  ): JSX.Element | null => {
    const [weightNarrativeTooltip, setWeightNarrativeTooltip] =
      useState<boolean>(false);

    const startingWeight = patient.firstReadingWeight
      ? Math.round(patient.firstReadingWeight?.value)
      : null;
    const currentWeight = patient.lastReadingWeight
      ? Math.round(patient.lastReadingWeight.value)
      : null;

    const weightDelta = calculateTotalWeightDelta({
      startingWeight,
      currentWeight,
    });
    const weightDeltaPercent = calculateWeightDeltaPercent({
      startingWeight,
      currentWeight,
    });

    const { enabled, message } = constructWeightNarrativeMessage({
      startingWeight,
      currentWeight,
      weightDelta,
      weightDeltaPercent,
    });

    async function handleCopyWeightNarrativeMessage(message: string) {
      await navigator.clipboard.writeText(message);
      setWeightNarrativeTooltip(true);
    }

    const patientEnrolledInWeightProgram = patient.programs.weight.enrolled;

    if (!patientEnrolledInWeightProgram) {
      return null;
    }

    return (
      <Box ref={ref} pt={6} scrollMargin={TAB_BAR_HEIGHT}>
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontWeight="medium">Weight Data</Text>

          <Box display="flex" gap={2}>
            <Tooltip
              closeDelay={500}
              label="copied weight narrative message"
              isOpen={weightNarrativeTooltip}
              hasArrow
              onClose={() => setWeightNarrativeTooltip(false)}
              rounded="md"
              px={3}
              py={1}
              mx={4}
            >
              <Button
                isDisabled={!enabled}
                onClick={() => handleCopyWeightNarrativeMessage(message)}
                leftIcon={<CopyFilled />}
                variant="outline"
                size="sm"
              >
                Copy narrative
              </Button>
            </Tooltip>
          </Box>
        </Flex>

        <WeightReadingsSummary
          patient={patient}
          startingWeight={startingWeight}
          currentWeight={currentWeight}
          weightDelta={weightDelta}
          weightDeltaPercent={weightDeltaPercent}
        />

        <WeightReadingsTable onUpdate={onUpdate} patient={patient} />
      </Box>
    );
  },
);

export default WeightReadings;
