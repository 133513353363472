import { FocusEvent, useRef, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { AlertCircle, CheckCircle } from '../../assets';
import InputMask from 'react-input-mask';
import { db } from '../../firebase';

function isPhoneNumberValid(input: string): boolean {
  // @NOTE: A valid phone number will be 10 digits long after removing hyphens and underscores
  const parsedInput = input.replaceAll(/[-_]/g, '');

  return parsedInput.length === 10;
}

async function queryPatientsCollectionByPhoneNumber(
  phoneNumber: string,
): Promise<number> {
  const patientsRef = collection(db, 'patients');
  const q = query(patientsRef, where('phoneNumber', '==', phoneNumber));

  const patientsDocs = await getDocs(q);

  return patientsDocs.size;
}

interface IProps {
  defaultValue?: string;
  onChange: (phoneNumber: string) => void;
}

export default function PhoneNumberInput({
  defaultValue,
  onChange,
}: IProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const initialValueRef = useRef(defaultValue);

  async function handleOnChange(event: FocusEvent<HTMLInputElement>) {
    setErrorMessage('');
    setIsInputValid(false);

    const { value } = event.target;

    if (value === initialValueRef.current) {
      onChange(value);
      return;
    }

    if (!isPhoneNumberValid(value)) {
      setErrorMessage('A valid phone number is required');
      return;
    }

    const patientCollectionSize = await queryPatientsCollectionByPhoneNumber(
      value,
    );

    if (patientCollectionSize !== 0) {
      setErrorMessage('Phone number is already in use.');
      onChange('');
    } else {
      onChange(value);
      setErrorMessage('');
      setIsInputValid(true);
    }
  }

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel>Phone number</FormLabel>

      <InputGroup>
        <Input
          id="phoneNumber"
          type="tel"
          placeholder="Phone number"
          as={InputMask}
          mask="999-999-9999"
          onChange={handleOnChange}
          defaultValue={defaultValue}
        />

        {errorMessage && (
          <InputRightElement children={<AlertCircle color="red.300" />} />
        )}

        {isInputValid && (
          <InputRightElement children={<CheckCircle color="green.400" />} />
        )}
      </InputGroup>

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
