import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react';
import { IPatient } from '../../types';

interface IProps {
  patient?: IPatient;
}

export default function VendorAlerts({ patient }: IProps): JSX.Element | null {
  if (!patient) {
    return null;
  }
  const vendorState = [
    { name: 'Canvas', recordExists: patient.canvasId },
    { name: 'CustomerIO', recordExists: patient.customerIOId },
    { name: 'Impilo', recordExists: patient.impiloId },
    { name: 'Source', recordExists: patient.sourceId },
  ];
  const missingRecords = vendorState.filter((v) => !v.recordExists);

  return (
    <Box px={2}>
      {missingRecords.map((r, i) => {
        const isLastItem = i === missingRecords.length - 1;
        return (
          <Alert
            key={r.name}
            status="warning"
            mb={isLastItem ? 4 : 2}
            borderRadius="lg"
            p={2}
          >
            <AlertIcon />

            <Box>
              <AlertTitle fontSize="sm">
                Failed to create record in <Box as="span">{r.name}</Box>
              </AlertTitle>
              <AlertDescription fontSize="xs">
                Please contact the tech team in{' '}
                <Box as="span" fontWeight="semibold">
                  #tech-support
                </Box>
              </AlertDescription>
            </Box>
          </Alert>
        );
      })}
    </Box>
  );
}
