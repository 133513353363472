import { useCallback, useMemo } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { ArrowLeft, ArrowRight } from '../../../assets';

interface IProps {
  currentPage: number;
  onClickNextPage: () => Promise<void>;
  onClickPreviousPage: () => Promise<void>;
  pageResultsCount: number;
  pageSize: number;
  totalPageCount: number;
  totalResultsCount: number;
}

export default function Pagination({
  currentPage,
  onClickNextPage,
  onClickPreviousPage,
  pageResultsCount,
  pageSize,
  totalPageCount,
  totalResultsCount,
}: IProps): JSX.Element {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPageCount;

  const formatPaginationRangeText = useCallback(() => {
    let startResultNumber;
    let endResultNumber;

    if (isLastPage) {
      startResultNumber = (currentPage - 1) * pageSize + 1;
      endResultNumber = totalResultsCount;
    } else {
      startResultNumber = (currentPage - 1) * pageResultsCount + 1;
      endResultNumber = startResultNumber + pageResultsCount - 1;
    }

    return `${startResultNumber} - ${endResultNumber} of ${totalResultsCount}`;
  }, [currentPage, isLastPage, pageResultsCount, pageSize, totalResultsCount]);

  const paginationRangeText = useMemo(
    () => formatPaginationRangeText(),
    [formatPaginationRangeText],
  );

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      px={4}
      py={2}
    >
      <Text color="blackAlpha.600" fontSize="sm">
        {paginationRangeText}
      </Text>
      <Box display="flex" gap={4}>
        <Button
          onClick={onClickPreviousPage}
          cursor="pointer"
          isDisabled={isFirstPage}
          variant="ghost"
          size="sm"
        >
          <ArrowLeft cursor="pointer" />
        </Button>

        <Button
          onClick={onClickNextPage}
          cursor="pointer"
          isDisabled={isLastPage}
          variant="ghost"
          size="sm"
        >
          <ArrowRight cursor="pointer" />
        </Button>
      </Box>
    </Box>
  );
}
