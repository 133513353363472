import { IReadingBloodPressure } from '../../types';

export default function getBPAverageFromReadings(
  readings: IReadingBloodPressure[],
): { avgSystolic: number; avgDiastolic: number } | null {
  const { length } = readings;

  if (length === 0) {
    return null;
  }

  const averageSystolic = readings.reduce(
    (acc, reading) => acc + reading.systolic,
    0,
  );
  const averageDiastolic = readings.reduce(
    (acc, reading) => acc + reading.diastolic,
    0,
  );

  return {
    avgSystolic: Math.round(averageSystolic / length),
    avgDiastolic: Math.round(averageDiastolic / length),
  };
}
