import { useEffect, useState } from 'react';
import { Box, Button, Grid, Text, Tooltip, useToast } from '@chakra-ui/react';
import { IPatient, IReadingWeight } from '../../../../types';
import { ExternalLinkFilled, HelpCircle } from '../../../../assets';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../../../firebase';
import WeightReadingsListRow from './WeightReadingsListRow';
import WeightReadingsListLoader from './WeightReadingsListLoader';
import { updateWeightReadingOutlierStatus } from '../utils';

export const WEIGHT_READINGS_LIST_TEMPLATE_COLUMNS = '1fr 5fr 32px';

const TOOL_TIP_LABEL = `Any reading outside of +/- 18.5% of the first reading or the last non-outlier weight reading is automatically marked as an outlier and excluded from summary stats above. To toggle a reading as an outlier or valid reading, click the “X” or “+” button for that row.`;

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

export default function WeightReadingsTable({
  onUpdate,
  patient,
}: IProps): JSX.Element | null {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [weightReadings, setWeightReadings] = useState<IReadingWeight[]>([]);

  useEffect(() => {
    async function fetchIndividualWeightReadings() {
      try {
        if (!patient.uid) {
          return;
        }

        setIsLoading(true);

        const q = query(
          collection(db, 'readingWeights'),
          where('patientUid', '==', patient.uid),
          orderBy('readingTime', 'desc'),
        );
        const querySnapshot = await getDocs(q);
        const weightReadings: IReadingWeight[] = [];

        querySnapshot.forEach((doc) =>
          weightReadings.push({
            id: doc.id,
            ...doc.data(),
          } as IReadingWeight),
        );

        if (weightReadings.length) {
          setWeightReadings(weightReadings);
        }
      } catch (error) {
        toast({
          description: 'Error fetching weight readings.',
          status: 'error',
          title: 'Uh oh!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchIndividualWeightReadings();
  }, [patient.uid, toast]);

  if (isLoading) {
    return <WeightReadingsListLoader />;
  }

  if (!weightReadings.length) {
    return null;
  }

  async function handleUpdateOutlierStatus(
    readingId: string,
    outlier: boolean,
  ) {
    try {
      await updateWeightReadingOutlierStatus(readingId, outlier);
      await onUpdate();
    } catch (error) {
      toast({
        description:
          'An error occurred updating the weight reading. Please try again.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh!',
        variant: 'left-accent',
      });
    }
  }

  return (
    <Box
      border="1px solid"
      borderColor="blackAlpha.200"
      rounded="md"
      boxShadow="sm"
      mt={4}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={3}
      >
        <Box alignItems="center" display="flex" gap={2}>
          <Text
            textTransform="uppercase"
            fontSize="xs"
            fontWeight="bold"
            color="blackAlpha.700"
          >
            All Weight Readings
          </Text>

          <Tooltip hasArrow label={TOOL_TIP_LABEL} placement="top">
            <span>
              <HelpCircle />
            </span>
          </Tooltip>
        </Box>
        <Button
          variant="ghost"
          as={Link}
          size="xs"
          to={`${process.env.REACT_APP_IMPILO_URL}/patient/${patient.impiloId}`}
          target="_blank"
        >
          <ExternalLinkFilled w={3} h={3} />
        </Button>
      </Box>
      <Grid
        color="blackAlpha.600"
        fontWeight="bold"
        fontSize="xs"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        templateColumns={WEIGHT_READINGS_LIST_TEMPLATE_COLUMNS}
        justifyItems="flex-start"
        py={3}
        px={4}
        bg="gray.50"
      >
        <Text>LBS</Text>
        <Text justifySelf="flex-end">Date, Time</Text>
      </Grid>
      <Box maxH={465} overflowY="scroll">
        {weightReadings.map((r, i) => {
          const isLastItem = i === weightReadings.length - 1;
          return (
            <WeightReadingsListRow
              isLastItem={isLastItem}
              key={r.readingTime.toString()}
              onUpdateOutlierStatus={handleUpdateOutlierStatus}
              reading={r}
            />
          );
        })}
      </Box>
    </Box>
  );
}
