import { useMemo } from 'react';
import { Box, Button, FormLabel, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import EmptyState from './EmptyState';
import ClientFilters from './Filters';
import TableRow from './TableRow';
import { useFetchPatientsByProvider } from '../../../hooks';
import { ProviderFilterSelector, TableLoader } from '../../../components';
import { filterPatients, IClientFilters } from '../../../utils/patientUtils';
import { ProviderIds, PROVIDERS_LIST } from '../../../constants';
import { CancelCircle } from '../../../assets';
import { Events, mixpanel } from '../../../analytics';

interface IProps {
  clientFilters: IClientFilters;
  onClearFilters: () => void;
  onClientFiltersChange: (filterValue: { [key: string]: string }) => void;
  selectedProviderIds: ProviderIds[];
  setSelectedProviderIds: React.Dispatch<React.SetStateAction<ProviderIds[]>>;
}

export const PATIENTS_TABLE_COLUMN_LAYOUT =
  '1.5fr 1fr 1fr minmax(125px, 1fr) minmax(50px, .5fr) max(75px)';

export default function PatientsTable({
  clientFilters,
  onClearFilters,
  onClientFiltersChange,
  selectedProviderIds,
  setSelectedProviderIds,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const resetProviderSelectionEnabled =
    selectedProviderIds.length !== PROVIDERS_LIST.length;

  const [patients, isLoading] = useFetchPatientsByProvider(selectedProviderIds);

  const filteredPatients = useMemo(() => {
    return filterPatients(patients, clientFilters);
  }, [clientFilters, patients]);

  function handleClick(uid: string) {
    navigate(`/patients/${uid}`);
  }

  const showClearButton = useMemo(() => {
    const { state, status } = clientFilters;

    return state !== 'all' || status !== 'all' || resetProviderSelectionEnabled;
  }, [clientFilters, resetProviderSelectionEnabled]);

  function handleOnClearFilters() {
    onClearFilters();
    mixpanel.track(Events.PATIENTS_FILTERED, {
      filter_type: 'Clear filters',
    });
  }

  function handleOnPractitionerChange(value: ProviderIds[]) {
    setSelectedProviderIds(value);

    mixpanel.track(Events.PATIENTS_FILTERED, {
      filter_type: 'Provider',
    });
  }

  return (
    <Box>
      <Box
        px={6}
        py={3}
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Box display="flex" gap={2} alignItems="flex-end">
          <Box>
            <FormLabel fontSize="sm" mb={1}>
              Provider
            </FormLabel>
            <ProviderFilterSelector
              selectedProviderIds={selectedProviderIds}
              setSelectedProviderIds={handleOnPractitionerChange}
            />
          </Box>
          <ClientFilters
            onChange={onClientFiltersChange}
            values={clientFilters}
          />
          {showClearButton && (
            <Button
              alignSelf="flex-end"
              size="sm"
              onClick={handleOnClearFilters}
              leftIcon={<CancelCircle />}
              variant="ghost"
            >
              Clear filters
            </Button>
          )}
        </Box>

        {!isLoading && (
          <Text fontSize="sm" color="blackAlpha.700" textAlign="right">
            {filteredPatients.length} / {patients.length} Matches
          </Text>
        )}
      </Box>

      <Box
        textTransform="uppercase"
        color="blackAlpha.700"
        fontWeight="bold"
        fontSize="sm"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="grid"
        gap={2}
        gridTemplateColumns={PATIENTS_TABLE_COLUMN_LAYOUT}
        py={4}
        px={6}
      >
        <Text>Patient</Text>
        <Text>DOB</Text>
        <Text>Status</Text>
        <Text>Enrolled On</Text>
        <Text>State</Text>
        <Text justifySelf="flex-end">Provider</Text>
      </Box>

      {isLoading && <TableLoader />}

      {!isLoading && (
        <Box h="calc(100vh - 193px)" overflow="scroll">
          {filteredPatients.map((patient) => (
            <Box
              key={patient.uid}
              onClick={() => handleClick(patient.uid)}
              cursor="pointer"
              _hover={{
                bg: 'blue.50',
              }}
              overflow="scroll"
            >
              <TableRow patient={patient} />
            </Box>
          ))}

          {filteredPatients.length === 0 && (
            <EmptyState onClick={handleOnClearFilters} />
          )}
        </Box>
      )}
    </Box>
  );
}
