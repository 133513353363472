import { useEffect, useState } from 'react';
import { db } from '../firebase';
import {
  IPatientTrend,
  PatientTrendChangeType,
  PatientTrendTimePeriod,
} from '../types';
import { useToast } from '@chakra-ui/react';
import {
  noProviderQueryConstraints,
  otherProvidersQueryConstraints,
  getProviderQueryConstraints,
} from './useFetchPatientsByProvider';
import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  where,
} from 'firebase/firestore';
import { ProviderIds, PROVIDERS_LIST } from '../constants';

export default function useFetchTrendingPatientsByProvider(
  selectedProviderIds: ProviderIds[],
  trendChangeType: PatientTrendChangeType,
  trendPeriod: PatientTrendTimePeriod,
): [IPatientTrend[], boolean] {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trendingPatients, setTrendingPatients] = useState<IPatientTrend[]>([]);

  useEffect(() => {
    async function fetchTrendingPatients() {
      const trendingData: IPatientTrend[] = [];
      const trendsCollection = collection(db, 'trends');
      const orderDirection =
        trendChangeType === PatientTrendChangeType.WORSE ? 'desc' : 'asc';

      const sharedTrendsConstraints: QueryConstraint[] = [
        where('trend.change.type', '==', trendChangeType),
        where('trend.period', '==', trendPeriod),
        orderBy('trend.last.averageSystolic', orderDirection),
      ];

      try {
        const allProvidersSelected =
          selectedProviderIds.length === PROVIDERS_LIST.length;

        // query without any constraints
        if (allProvidersSelected) {
          const q = query(trendsCollection, ...sharedTrendsConstraints);
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            trendingData.push(doc.data() as IPatientTrend);
          });
        } else {
          const specificSelectedProviders = selectedProviderIds.filter(
            (id) => id !== 'other',
          );
          // only specific providers selected
          if (Boolean(specificSelectedProviders.length)) {
            const q = query(
              trendsCollection,
              ...getProviderQueryConstraints(specificSelectedProviders),
              ...sharedTrendsConstraints,
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              trendingData.push(doc.data() as IPatientTrend);
            });
          }

          // "Other/Unassigned" is selected
          if (selectedProviderIds.includes(ProviderIds.PROVIDER_OTHER)) {
            const otherProvidersQuery = query(
              trendsCollection,
              ...otherProvidersQueryConstraints,
              ...sharedTrendsConstraints,
            );

            let otherProvidersQuerySnapshot = await getDocs(
              otherProvidersQuery,
            );

            otherProvidersQuerySnapshot.forEach((doc) => {
              trendingData.push(doc.data() as IPatientTrend);
            });

            const noProviderQuery = query(
              trendsCollection,
              noProviderQueryConstraints,
              ...sharedTrendsConstraints,
            );

            let noProviderQuerySnapshot = await getDocs(noProviderQuery);

            noProviderQuerySnapshot.forEach((doc) => {
              trendingData.push(doc.data() as IPatientTrend);
            });
          }
        }
      } catch (e) {
        toast({
          description: 'Error fetching patient trends.',
          status: 'error',
          title: 'Uh oh!',
        });
      } finally {
        setTrendingPatients(trendingData);
        setIsLoading(false);
      }
    }

    fetchTrendingPatients();
  }, [selectedProviderIds, toast, trendChangeType, trendPeriod]);

  return [trendingPatients, isLoading];
}
