import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProviderIds, PROVIDERS_LIST } from '../../../constants';
import { ClinicalReviewStates } from '../../../types';

export enum FilterPriority {
  DAYS_IN_STAGE = 'days_in_stage',
  DISTANCE_FROM_DIASTOLIC_GOAL = 'distance_from_diastolic_goal',
  DISTANCE_FROM_SYSTOLIC_GOAL = 'distance_from_systolic_goal',
  LAST_PHONE_OUTREACH = 'last_phone_outreach',
}

export interface FilterValues {
  priority: FilterPriority;
  providerIds: ProviderIds[];
  reviewState: ClinicalReviewStates;
  segmentIds: string[];
}

type SearchParams = {
  priority: FilterPriority;
  providerIds: string;
  reviewState: ClinicalReviewStates;
  segmentIds?: string;
};

interface Context {
  setFieldValues: (values: Partial<FilterValues>) => void;
  values: FilterValues;
}

const FilterContext = createContext<Context | undefined>(undefined);

interface Provider {
  children: React.ReactNode;
}

export default function FilterProvider({ children }: Provider) {
  const [, setSearchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<FilterValues>(
    getInitialFilterValues(),
  );

  useEffect(() => {
    const { priority, providerIds, reviewState, segmentIds } = filterValues;

    const searchParams: SearchParams = {
      priority,
      providerIds: providerIds.join(','),
      reviewState,
    };

    if (segmentIds.length) {
      searchParams.segmentIds = segmentIds.join(',');
    }

    setSearchParams(searchParams);
  }, [filterValues, setSearchParams]);

  function setFieldValues(values: Partial<FilterValues>) {
    setFilterValues({
      ...filterValues,
      ...values,
    });
  }

  const value = {
    setFieldValues,
    values: filterValues,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilters must be used within FilterProvider');
  }

  return context;
}

function getInitialFilterValues(): FilterValues {
  const searchParams = new URLSearchParams(window.location.search);

  const priority = searchParams.get('priority') as FilterPriority;
  const providerIds = searchParams
    .get('providerIds')
    ?.split(',') as ProviderIds[];
  const reviewState = searchParams.get('reviewState') as ClinicalReviewStates;
  const segmentIds = searchParams.get('segmentIds')?.split(',');

  const filterValues: FilterValues = {
    priority: priority || FilterPriority.DAYS_IN_STAGE,
    providerIds: providerIds || PROVIDERS_LIST.map((provider) => provider.id),
    reviewState: reviewState || ClinicalReviewStates.NOT_APPLICABLE,
    segmentIds: segmentIds || [],
  };

  return filterValues;
}
