import dayjs from './dayjs';

export default function formatDate(
  date: Date | string | undefined | null,
): string | undefined {
  if (!date) {
    return undefined;
  }

  const d = dayjs(date);

  if (!d.isValid()) {
    return undefined;
  }

  return dayjs(date).format('MM-DD-YYYY');
}
