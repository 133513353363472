import dayjs from 'dayjs';
import formatDate from './formatDate';
import { IPatient } from '../types';

export enum PatientFilterStatus {
  All = 'all',
  PENDING = 'pending',
  ENROLLED = 'enrolled',
  UNENROLLED = 'unenrolled',
}

export const StatusColors: Record<
  Exclude<PatientFilterStatus, PatientFilterStatus.All>,
  string
> = {
  [PatientFilterStatus.PENDING]: 'yellow.600',
  [PatientFilterStatus.ENROLLED]: 'green.600',
  [PatientFilterStatus.UNENROLLED]: 'red.600',
};

export interface IClientFilters {
  status: PatientFilterStatus;
  state: string;
}

function isPatientPending(patient: IPatient): boolean {
  return patient.enrolledOn === null && patient.deactivatedOn === null;
}

function isPatientEnrolled(patient: IPatient): boolean {
  return patient.enrolledOn !== null && patient.deactivatedOn === null;
}

function isPatientUnenrolled(patient: IPatient): boolean {
  return patient.deactivatedOn !== null;
}

export function filterPatients(
  patients: IPatient[],
  filters: IClientFilters,
): IPatient[] {
  const { status, state } = filters;

  return patients
    .filter((patient) => {
      if (status === PatientFilterStatus.All) {
        return true;
      }

      if (status === PatientFilterStatus.PENDING) {
        return isPatientPending(patient);
      }

      if (status === PatientFilterStatus.ENROLLED) {
        return isPatientEnrolled(patient);
      }

      if (status === PatientFilterStatus.UNENROLLED) {
        return isPatientUnenrolled(patient);
      }

      return false;
    })
    .filter((patient) => {
      if (state === 'all') {
        return true;
      }

      return patient.address.state === state;
    });
}

export function getPatientStatus(
  patient: IPatient,
): Exclude<PatientFilterStatus, PatientFilterStatus.All> {
  if (isPatientPending(patient)) {
    return PatientFilterStatus.PENDING;
  }

  if (isPatientEnrolled(patient)) {
    return PatientFilterStatus.ENROLLED;
  }

  return PatientFilterStatus.UNENROLLED;
}

export function formatEnrolledOn({
  isEnrolled,
  enrolledOn,
}: IPatient): string | undefined {
  if (!isEnrolled || !enrolledOn) return 'N/A';

  return formatDate(enrolledOn.toDate());
}

export function formatWeeksEnrolled({
  isEnrolled,
  enrolledOn,
}: IPatient): null | string {
  if (!isEnrolled || !enrolledOn) return null;

  const weeksEnrolled = dayjs().diff(dayjs(enrolledOn.toDate()), 'weeks');

  return `${weeksEnrolled === 0 ? '< 1' : weeksEnrolled} ${
    weeksEnrolled === 1 ? 'week' : 'weeks'
  }`;
}

export function formatPatientAge(dateOfBirth: string): number | undefined {
  if (!dateOfBirth) {
    return;
  }

  return dayjs().diff(dayjs(dateOfBirth), 'years');
}
