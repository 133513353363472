import { Icon, IconProps } from '@chakra-ui/react';

export default function Edit(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.875 4.75001C2.57663 4.75001 2.29048 4.86854 2.0795 5.07952C1.86853 5.2905 1.75 5.57665 1.75 5.87501V17.125C1.75 17.4234 1.86853 17.7095 2.0795 17.9205C2.29048 18.1315 2.57663 18.25 2.875 18.25H14.125C14.2727 18.25 14.419 18.2209 14.5555 18.1644C14.692 18.1078 14.816 18.025 14.9205 17.9205C15.025 17.816 15.1078 17.692 15.1644 17.5555C15.2209 17.419 15.25 17.2728 15.25 17.125V8.50001C15.25 8.0858 15.5858 7.75001 16 7.75001C16.4142 7.75001 16.75 8.0858 16.75 8.50001V17.125C16.75 17.4697 16.6821 17.8111 16.5502 18.1296C16.4183 18.448 16.2249 18.7374 15.9812 18.9812C15.7374 19.2249 15.448 19.4183 15.1295 19.5502C14.8111 19.6821 14.4697 19.75 14.125 19.75H2.875C2.17881 19.75 1.51113 19.4735 1.01884 18.9812C0.526562 18.4889 0.25 17.8212 0.25 17.125V5.87501C0.25 5.17882 0.526562 4.51114 1.01884 4.01886C1.51113 3.52658 2.17881 3.25001 2.875 3.25001H10.7256C11.1398 3.25001 11.4756 3.5858 11.4756 4.00001C11.4756 4.41423 11.1398 4.75001 10.7256 4.75001H2.875Z"
          fill="currentColor"
        />
        <path
          d="M19.5597 0.496108C19.4912 0.420846 19.4081 0.360252 19.3156 0.31799C19.223 0.275727 19.1228 0.252672 19.0211 0.250219C18.9193 0.247765 18.8182 0.265964 18.7237 0.303715C18.6292 0.341466 18.5433 0.397985 18.4713 0.469857L17.8914 1.04689C17.8211 1.11721 17.7817 1.21255 17.7817 1.31197C17.7817 1.41138 17.8211 1.50673 17.8914 1.57704L18.423 2.10767C18.4578 2.14268 18.4992 2.17046 18.5448 2.18941C18.5905 2.20837 18.6394 2.21812 18.6887 2.21812C18.7381 2.21812 18.787 2.20837 18.8327 2.18941C18.8783 2.17046 18.9197 2.14268 18.9545 2.10767L19.5198 1.54517C19.8058 1.2597 19.8325 0.794701 19.5597 0.496108Z"
          fill="currentColor"
        />
        <path
          d="M16.7191 2.21876L8.25719 10.6656C8.20588 10.7167 8.16859 10.7802 8.14891 10.8499L7.7575 12.0156C7.74812 12.0473 7.74746 12.0808 7.75558 12.1128C7.7637 12.1448 7.78029 12.174 7.80362 12.1973C7.82695 12.2207 7.85615 12.2373 7.88813 12.2454C7.9201 12.2535 7.95368 12.2528 7.98531 12.2435L9.15016 11.852C9.21984 11.8324 9.28328 11.7951 9.33438 11.7438L17.7812 3.28095C17.8594 3.20197 17.9032 3.09534 17.9032 2.98423C17.9032 2.87312 17.8594 2.7665 17.7812 2.68751L17.3148 2.21876C17.2358 2.1399 17.1286 2.09562 17.017 2.09562C16.9053 2.09562 16.7981 2.1399 16.7191 2.21876Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
