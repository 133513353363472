import { Icon, IconProps } from '@chakra-ui/react';

export default function Megaphone(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.875 8.87506V8.90084C3.87408 9.06189 3.81593 9.21737 3.71094 9.33951V9.34146C3.5 9.59068 3.25 10.0368 3.25 10.7501C3.25 11.3965 3.45703 11.8528 3.70625 12.1528C3.81559 12.2859 3.87525 12.4528 3.875 12.6251C3.875 12.9566 4.0067 13.2745 4.24112 13.5089C4.47554 13.7434 4.79348 13.8751 5.125 13.8751H5.4375C5.52038 13.8751 5.59987 13.8421 5.65847 13.7835C5.71708 13.7249 5.75 13.6454 5.75 13.5626V7.93756C5.75 7.85468 5.71708 7.77519 5.65847 7.71659C5.59987 7.65798 5.52038 7.62506 5.4375 7.62506H5.125C4.79348 7.62506 4.47554 7.75675 4.24112 7.99117C4.0067 8.22559 3.875 8.54354 3.875 8.87506Z"
          fill="#EDF2F7"
        />
        <path
          d="M19.6633 9.28717L19.627 9.28053C19.5912 9.27371 19.559 9.25464 19.5358 9.22662C19.5127 9.1986 19.5 9.16337 19.5 9.12701V4.42193C19.5 3.92818 19.1723 3.48443 18.684 3.31568C18.2184 3.15514 17.7332 3.28599 17.4465 3.64889C16.8365 4.37725 16.166 5.05271 15.4422 5.66803C14.5191 6.44928 13.6359 7.00592 12.825 7.32076C12.7659 7.34331 12.715 7.38328 12.679 7.43538C12.6431 7.48749 12.6239 7.54927 12.6238 7.61256V13.6797C12.6239 13.8019 12.6598 13.9214 12.7271 14.0234C12.7944 14.1254 12.8901 14.2054 13.0023 14.2536C13.7578 14.5774 14.5742 15.1063 15.4242 15.827C16.1535 16.4479 16.8289 17.1295 17.443 17.8645C17.5414 17.9879 17.6669 18.087 17.8099 18.1538C17.9529 18.2207 18.1094 18.2536 18.2672 18.2501C18.4059 18.2494 18.5436 18.2265 18.675 18.1821C19.1762 18.0157 19.5 17.5879 19.5 17.0938V12.3723C19.5 12.3354 19.5131 12.2998 19.5369 12.2716C19.5607 12.2434 19.5937 12.2246 19.6301 12.2184L19.6633 12.2129C20.0391 12.111 20.75 11.6747 20.75 10.7501C20.75 9.82545 20.0391 9.38912 19.6633 9.28717Z"
          fill="#EDF2F7"
        />
        <path
          d="M11.375 14.5001V7.93756C11.375 7.85468 11.3421 7.77519 11.2835 7.71659C11.2249 7.65798 11.1454 7.62506 11.0625 7.62506H7.3125C7.22962 7.62506 7.15013 7.65798 7.09153 7.71659C7.03292 7.77519 7 7.85468 7 7.93756V19.8126C7 20.0612 7.09877 20.2997 7.27459 20.4755C7.4504 20.6513 7.68886 20.7501 7.9375 20.7501H9.98633C10.1811 20.7517 10.3736 20.7087 10.5492 20.6244C10.7248 20.5401 10.8787 20.4167 10.9992 20.2637C11.1228 20.1053 11.2059 19.9192 11.2413 19.7215C11.2768 19.5238 11.2636 19.3204 11.2027 19.129C11.1395 18.9266 11.0609 18.6993 10.9773 18.4575C10.6684 17.5629 10.2609 17.0094 10.1523 15.7497C10.479 15.7425 10.7899 15.6077 11.0185 15.3742C11.247 15.1406 11.375 14.8268 11.375 14.5001Z"
          fill="#EDF2F7"
        />
      </svg>
    </Icon>
  );
}
