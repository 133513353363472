import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { BloodPressure } from '../../../assets';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function DistanceFromGoal({ patient }: Props): JSX.Element {
  const distanceFromGoal = useMemo(() => {
    const {
      bloodPressureGoalsDiastolicDelta,
      bloodPressureGoalsSystolicDelta,
    } = patient;

    if (!bloodPressureGoalsDiastolicDelta || !bloodPressureGoalsSystolicDelta) {
      return null;
    }

    return `${Math.round(bloodPressureGoalsSystolicDelta)} / ${Math.round(
      bloodPressureGoalsDiastolicDelta,
    )}`;
  }, [patient]);

  return (
    <Text
      color={distanceFromGoal ? 'pink.600' : 'blackAlpha.500'}
      fontWeight="semibold"
    >
      {distanceFromGoal && <BloodPressure mr={2} />}
      {distanceFromGoal || 'N/A'}
    </Text>
  );
}
