import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IReadingBloodPressure } from '../types';

type ReturnValue = [IReadingBloodPressure[], boolean];

export default function useFetchPatientBPReadings(
  patientUid?: string,
  startDate?: Date,
): ReturnValue {
  const [readings, setReadings] = useState<IReadingBloodPressure[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();

  useEffect(() => {
    async function fetchBPReadings() {
      if (!patientUid) {
        return;
      }

      setIsLoading(true);

      const readingsCollection = collection(db, 'readingBloodPressures');
      const queryConstraints = [
        where('patientUid', '==', patientUid),
        orderBy('readingTime', 'desc'),
      ];

      if (startDate) {
        queryConstraints.push(
          where('readingTime', '>=', Timestamp.fromDate(startDate)),
        );
      }

      const q = query(readingsCollection, ...queryConstraints);

      try {
        const querySnapshot = await getDocs(q);
        const readings: IReadingBloodPressure[] = [];

        querySnapshot.forEach((doc) => {
          readings.push({
            id: doc.id,
            ...doc.data(),
          } as IReadingBloodPressure);
        });

        setReadings(readings);
      } catch (error) {
        toast({
          description: 'Error fetching patient blood pressure readings.',
          status: 'error',
          title: 'Uh Oh!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchBPReadings();
  }, [patientUid, startDate, toast]);

  useEffect(() => {
    return () => {
      setReadings([]);
      setIsLoading(true);
    };
  }, []);

  return [readings, isLoading];
}
