import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import SearchResults from './SearchResults';
import { Search } from '../../../assets';
import { useFetchPatients } from '../../../hooks';
import { Events, mixpanel } from '../../../analytics';
import { IPatient } from '../../../types';

export default function PatientSearch(): JSX.Element {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [patients] = useFetchPatients();
  const [results, setResults] = useState<IPatient[]>([]);
  const navigate = useNavigate();

  function handleClick() {
    mixpanel.track(Events.PATIENTS_SEARCHED, null);
    setIsFocused(true);
  }

  function onSelectSearchResult(patientUid: string) {
    navigate(`/patients/${patientUid}`);
    resetSearch();
  }

  function resetSearch() {
    setIsFocused(false);
    setSearchValue('');
    setResults([]);
  }

  useEffect(() => {
    function searchPatients() {
      if (!searchValue) {
        setResults([]);
        return;
      }

      const results = patients.filter((patient) => {
        const fullName =
          `${patient.firstName} ${patient.lastName}`.toLowerCase();

        return fullName.includes(searchValue.toLowerCase());
      });

      setResults(results);
    }

    searchPatients();
  }, [searchValue, patients]);

  const showEmptyState = searchValue && !results.length;

  if (!isFocused) {
    return (
      <Button
        leftIcon={<Search />}
        variant="outline"
        size="sm"
        onClick={handleClick}
      >
        Search
      </Button>
    );
  }

  return (
    <Box position="relative">
      <InputGroup size="sm">
        <InputLeftElement
          pointerEvents="none"
          children={<Search color="gray.400" />}
        />
        <Input
          rounded="md"
          placeholder="Search Patients"
          autoFocus
          onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
          w="320px"
          onBlur={resetSearch}
        />
      </InputGroup>

      {results.length > 0 && (
        <SearchResults
          onSelectSearchResult={onSelectSearchResult}
          patients={results}
        />
      )}

      {showEmptyState && (
        <Box
          position="absolute"
          mt={1}
          p={2}
          bg="gray.50"
          rounded="md"
          shadow="md"
          w="320px"
          zIndex={1}
        >
          <Text>No matching patients</Text>
        </Box>
      )}
    </Box>
  );
}
