import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { dayjs } from '../../../utils';
import { PhoneOutgoing } from '../../../assets';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function LastPhoneOutreach({ patient }: Props): JSX.Element {
  const { engagementActivity } = patient;
  const lastPhoneCallReceivedAt = useMemo(() => {
    const { lastPhoneCallReceivedAt } = engagementActivity;

    if (!lastPhoneCallReceivedAt) {
      return 'No Outreach';
    }

    return dayjs(lastPhoneCallReceivedAt.toDate()).format('MM-DD-YYYY');
  }, [engagementActivity]);

  return (
    <Box alignItems="center" display="flex" gap={1.5}>
      <PhoneOutgoing fontSize="2xs" />
      <Text>{lastPhoneCallReceivedAt}</Text>
    </Box>
  );
}
