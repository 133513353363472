import { Icon, IconProps } from '@chakra-ui/react';

export default function PaperPlane(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9334 1.06722C12.8439 0.977774 12.7305 0.916094 12.6068 0.889606C12.4831 0.863119 12.3544 0.872952 12.2361 0.917923L1.2978 5.05804H1.29562C1.1695 5.10655 1.06143 5.19281 0.986163 5.30503C0.9109 5.41726 0.872116 5.54998 0.875107 5.68508C0.878099 5.82017 0.92272 5.95105 1.00288 6.05983C1.08303 6.16862 1.19482 6.25001 1.32296 6.29288L1.33417 6.29644L5.08847 7.8996C5.16169 7.92183 5.23947 7.92447 5.31403 7.90724C5.38859 7.89002 5.45733 7.85353 5.51339 7.80144L11.5389 2.18695C11.5568 2.16899 11.5781 2.15475 11.6016 2.14503C11.625 2.13532 11.6502 2.13032 11.6756 2.13032C11.701 2.13032 11.7261 2.13532 11.7496 2.14503C11.773 2.15475 11.7943 2.16899 11.8123 2.18695C11.8302 2.2049 11.8445 2.22622 11.8542 2.24967C11.8639 2.27313 11.8689 2.29827 11.8689 2.32367C11.8689 2.34906 11.8639 2.3742 11.8542 2.39766C11.8445 2.42112 11.8302 2.44243 11.8123 2.46038L6.19753 8.48312C6.14543 8.53917 6.10895 8.60791 6.09173 8.68248C6.0745 8.75704 6.07714 8.83481 6.09937 8.90804L7.70308 12.6645C7.70472 12.67 7.70636 12.6749 7.70827 12.6801C7.79577 12.9336 8.01726 13.1124 8.28495 13.1244C8.29671 13.1244 8.30081 13.1244 8.3123 13.1244C8.44744 13.1252 8.57968 13.0853 8.69184 13.0099C8.804 12.9345 8.89089 12.8271 8.9412 12.7017L13.0808 1.7664C13.1264 1.64808 13.1367 1.51907 13.1106 1.39499C13.0844 1.27091 13.0229 1.15706 12.9334 1.06722V1.06722Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}
