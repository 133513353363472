import { useMemo } from 'react';
import { Box, Checkbox, Text } from '@chakra-ui/react';
import { useFilters } from '../context';
import { reviewStateIsSegmented } from '../../utils';
import { useFetchSegments } from '../../../../hooks';
import { Segment } from '../../../../types';

const medicationsFollowUpSegmentId = process.env
  .REACT_APP_CIO_MEDICATIONS_FOLLOW_UP_SEGMENT_ID as string;
const labsFollowUpSegmentId = process.env
  .REACT_APP_CIO_LABS_FOLLOW_UP_SEGMENT_ID as string;
const noBpReadingsSegmentId = process.env
  .REACT_APP_CIO_NO_BP_READINGS_SEGMENT_ID as string;

const ORDERED_BLOCKED_SEGMENT_IDS = [
  medicationsFollowUpSegmentId,
  labsFollowUpSegmentId,
  noBpReadingsSegmentId,
];

export default function SegmentSelect(): JSX.Element {
  const { setFieldValues, values } = useFilters();
  const [segments] = useFetchSegments();
  const activeSegments = useMemo(() => {
    return ORDERED_BLOCKED_SEGMENT_IDS.map((segmentId) =>
      segments.find((segment) => segment.id === segmentId),
    ).filter(Boolean) as Segment[];
  }, [segments]);
  const isSegmented = reviewStateIsSegmented(values.reviewState);

  function handleOnChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    const { segmentIds } = values;
    const { checked, value: segmentId } = currentTarget;
    let updatedSegmentIds: string[] = [];

    if (checked) {
      updatedSegmentIds = [...segmentIds, segmentId];
    } else {
      updatedSegmentIds = segmentIds.filter((id) => id !== segmentId);
    }

    setFieldValues({ segmentIds: updatedSegmentIds });
  }

  return (
    <Box px={3} py={1.5}>
      <Text fontSize="sm" color="gray.500" mb={2}>
        Segments
      </Text>
      <Box display="flex" flexDir="column">
        {activeSegments.map((segment) => {
          const isChecked = values.segmentIds.includes(segment.id);

          return (
            <Checkbox
              value={segment.id}
              key={segment.id}
              onChange={handleOnChange}
              isChecked={isChecked}
              isDisabled={!isSegmented}
            >
              {segment.name}
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
}
