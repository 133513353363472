import { DataFigureGridItem } from '../..';
import { IReadingBloodPressure } from '../../../types';
import { formatDate } from '../../../utils';

interface IProps {
  isLoading: boolean;
  reading?: IReadingBloodPressure | null;
}

export default function LatestReading({
  isLoading,
  reading,
}: IProps): JSX.Element {
  const { systolic, diastolic, readingTime } = reading || {};
  const readingDate = formatDate(readingTime?.toDate());

  return (
    <DataFigureGridItem
      copyEventName="Blood Pressure Latest Reading"
      label="Latest Reading"
      isLoading={isLoading}
      subtext={readingDate}
    >{`${systolic ?? 'N/A'} / ${diastolic ?? 'N/A'}`}</DataFigureGridItem>
  );
}
