import { Icon } from '@chakra-ui/react';

export default function CancelCircle(props: any): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 12 12"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0.3125C2.86395 0.3125 0.3125 2.86395 0.3125 6C0.3125 9.13605 2.86395 11.6875 6 11.6875C9.13605 11.6875 11.6875 9.13605 11.6875 6C11.6875 2.86395 9.13605 0.3125 6 0.3125ZM8.05926 7.44074C8.10159 7.48096 8.13545 7.52925 8.15883 7.58277C8.18221 7.63628 8.19464 7.69393 8.19538 7.75232C8.19613 7.81071 8.18518 7.86866 8.16318 7.92275C8.14118 7.97685 8.10857 8.02599 8.06728 8.06728C8.02599 8.10857 7.97685 8.14118 7.92275 8.16318C7.86866 8.18518 7.81071 8.19613 7.75232 8.19538C7.69393 8.19464 7.63628 8.18221 7.58277 8.15883C7.52925 8.13545 7.48096 8.10159 7.44074 8.05926L6 6.61879L4.55926 8.05926C4.47654 8.13784 4.3664 8.181 4.25232 8.17954C4.13824 8.17808 4.02924 8.13211 3.94856 8.05144C3.86789 7.97076 3.82192 7.86176 3.82046 7.74768C3.819 7.6336 3.86216 7.52346 3.94074 7.44074L5.38121 6L3.94074 4.55926C3.86216 4.47654 3.819 4.3664 3.82046 4.25232C3.82192 4.13824 3.86789 4.02924 3.94856 3.94856C4.02924 3.86789 4.13824 3.82192 4.25232 3.82046C4.3664 3.819 4.47654 3.86216 4.55926 3.94074L6 5.38121L7.44074 3.94074C7.52346 3.86216 7.6336 3.819 7.74768 3.82046C7.86176 3.82192 7.97076 3.86789 8.05144 3.94856C8.13211 4.02924 8.17808 4.13824 8.17954 4.25232C8.181 4.3664 8.13784 4.47654 8.05926 4.55926L6.61879 6L8.05926 7.44074Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
      </svg>
    </Icon>
  );
}
