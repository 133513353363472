import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  DocumentSnapshot,
} from 'firebase/firestore';
import Pagination from './Pagination';
import TableHeader from './TableHeader';
import TableLoader from './TableLoader';
import TableRow from './TableRow';
import { ExternalLinkFilled } from '../../../assets';
import { db } from '../../../firebase';
import {
  IPatient,
  IReadingBloodGlucose,
  IReadingBloodPressure,
} from '../../../types';
import { collectionNameByType } from './utils';

export type ReadingType = 'bloodPressure' | 'bloodGlucose';

export type Reading = IReadingBloodPressure & IReadingBloodGlucose;

type Readings = Reading[];

interface Props {
  patient: IPatient;
  readingType: ReadingType;
  tableTitle: string;
}

const PAGE_SIZE = 10;

const cursors: Record<number, DocumentSnapshot | null> = {
  1: null,
};

export default function ReadingsTable({
  patient,
  readingType,
  tableTitle,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [readings, setReadings] = useState<Readings>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const toast = useToast();
  const collectionName = useMemo(
    () => collectionNameByType(readingType),
    [readingType],
  );

  useEffect(() => {
    async function fetchReadingsCount() {
      const readingsCollection = collection(db, collectionName);
      const q = query(
        readingsCollection,
        where('patientUid', '==', patient.uid),
      );

      try {
        const querySnapshot = await getCountFromServer(q);
        const { count } = querySnapshot.data();

        if (count === 0) {
          setIsLoading(false);
          return;
        }

        setPageCount(Math.ceil(count / PAGE_SIZE));
        setTotalCount(count);
      } catch (error) {
        toast({
          description: "An error occurred fetching this patient's readings.",
          status: 'error',
          title: 'Uh Oh',
        });
      }
    }

    fetchReadingsCount();
  }, [patient.uid, collectionName, toast]);

  const fetchReadings = useCallback(
    async (page: number) => {
      const readingsCollection = collection(db, collectionName);

      const queryConstraints: any[] = [
        where('patientUid', '==', patient.uid),
        orderBy('readingTime', 'desc'),
        limit(PAGE_SIZE),
      ];

      if (page > 1) {
        const cursor = cursors[page];
        queryConstraints.push(startAfter(cursor));
      }

      const q = query(readingsCollection, ...queryConstraints);

      try {
        const querySnapshot = await getDocs(q);
        const readings: Readings = [];

        querySnapshot.forEach((doc) => {
          readings.push({
            id: doc.id,
            ...doc.data(),
          } as Reading);
        });

        setCurrentPage(page);
        setReadings(readings);

        const nextCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
        const nextPage = page + 1;

        cursors[nextPage] = nextCursor;
      } catch (error) {
        toast({
          description: "An error occurred fetching this patient's readings.",
          status: 'error',
          title: 'Uh Oh',
        });
      }
    },
    [patient.uid, collectionName, toast],
  );

  useEffect(() => {
    async function loadFirstPage() {
      if (totalCount === 0) {
        return;
      }

      await fetchReadings(currentPage);

      setIsLoading(false);
    }

    loadFirstPage();
  }, [currentPage, fetchReadings, totalCount]);

  async function handleClickNextPage() {
    const nextPage = currentPage + 1;

    await fetchReadings(nextPage);
  }

  async function handleClickPreviousPage() {
    const previousPage = currentPage - 1;

    await fetchReadings(previousPage);
  }

  return (
    <Box border="1px solid" borderColor="blackAlpha.200" borderRadius="md">
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor="blackAlpha.200"
        display="flex"
        justifyContent="space-between"
        px={4}
        py={3}
      >
        <Text
          color="blackAlpha.700"
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {tableTitle}
        </Text>
        <Button
          variant="ghost"
          as={Link}
          size="xs"
          to={`${process.env.REACT_APP_IMPILO_URL}/patient/${patient.impiloId}`}
          target="_blank"
        >
          <ExternalLinkFilled w={3} h={3} />
        </Button>
      </Box>

      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <TableHeader readingType={readingType} />

          <Box px={4}>
            {readings.map((reading) => (
              <TableRow
                key={reading.id}
                reading={reading}
                readingType={readingType}
              />
            ))}
          </Box>

          <Pagination
            currentPage={currentPage}
            onClickNextPage={handleClickNextPage}
            onClickPreviousPage={handleClickPreviousPage}
            pageSize={PAGE_SIZE}
            pageResultsCount={readings.length}
            totalPageCount={pageCount}
            totalResultsCount={totalCount}
          />
        </>
      )}
    </Box>
  );
}
