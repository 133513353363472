import { Skeleton, Stack, Box } from '@chakra-ui/react';

export default function WeightReadingsListLoader() {
  return (
    <Stack
      border="1px solid"
      borderColor="blackAlpha.200"
      rounded="md"
      boxShadow="sm"
      mt={4}
      gap={2}
    >
      <Box px={4} pt={4} display="flex" alignItems="center">
        <Skeleton h={2} w="100%" />
      </Box>
      <Box px={4} display="flex" alignItems="center">
        <Skeleton h={2} w="100%" />
      </Box>
      <Box px={4} pb={4} display="flex" alignItems="center">
        <Skeleton h={2} w="100%" />
      </Box>
    </Stack>
  );
}
