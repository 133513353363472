import { useState } from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { canStateBeOverridden, patientHasMedicationBlocker } from './utils';
import { Events, mixpanel } from '../../../analytics';
import { Pill } from '../../../assets';
import { clinicalReviewAcknowledgeMed } from '../../../api';
import { IPatient } from '../../../types';

interface Props {
  onUpdate: () => void;
  patient: IPatient;
}

export default function MedicationBlockerButton({
  onUpdate,
  patient,
}: Props): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const { clinicalReviewState } = patient;
  const { state } = clinicalReviewState;
  const hasMedicationBlocker = patientHasMedicationBlocker(patient);

  if (!canStateBeOverridden(state) || !hasMedicationBlocker) {
    return null;
  }

  async function handleOnClick() {
    setIsLoading(true);

    try {
      await clinicalReviewAcknowledgeMed({ patientUid: patient.uid });

      mixpanel.track(
        Events.PATIENT_CLINICAL_REVIEW_MEDICATION_BLOCKER_CLEARED,
        {
          current_state: state,
        },
      );

      toast({
        description: 'Medication Acknowledged',
        status: 'success',
        title: 'Success',
      });

      await onUpdate();
    } catch (error) {
      toast({
        description: 'Failed to acknowledge medication',
        status: 'error',
        title: 'Error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      colorScheme="blue"
      isLoading={isLoading}
      onClick={handleOnClick}
      size="lg"
      order={-1}
    >
      <Box>
        <Box alignItems="center" display="flex" gap={1.5} mb={1}>
          <Pill />
          <Text>Clear Meds Blocker</Text>
        </Box>
        <Text fontSize="xs" fontWeight="normal">
          Manually removes medication blocker
        </Text>
      </Box>
    </Button>
  );
}
