import { Box, FormLabel, Select } from '@chakra-ui/react';
import { getStateOptions } from '../../../utils';
import { PatientFilterStatus } from '../../../utils/patientUtils';
import { Events, mixpanel } from '../../../analytics';

interface IProps {
  onChange: (filterValue: { [key: string]: string }) => void;
  values: {
    state: string;
    status: string;
  };
}

export default function PatientFilters({
  onChange,
  values,
}: IProps): JSX.Element {
  return (
    <Box display="flex" gap={2}>
      <Box display="inline-block">
        <FormLabel fontSize="sm" mb={1}>
          Status
        </FormLabel>
        <Select
          onChange={({ currentTarget }) => {
            onChange({ status: currentTarget.value });

            mixpanel.track(Events.PATIENTS_FILTERED, {
              filter_type: 'Status',
            });
          }}
          value={values.status}
          size="sm"
          rounded="md"
        >
          <option value={PatientFilterStatus.All}>All statuses</option>
          <option value={PatientFilterStatus.PENDING}>Pending</option>
          <option value={PatientFilterStatus.ENROLLED}>Enrolled</option>
          <option value={PatientFilterStatus.UNENROLLED}>Unenrolled</option>
        </Select>
      </Box>

      <Box display="inline-block">
        <FormLabel fontSize="sm" mb={1}>
          State
        </FormLabel>
        <Select
          onChange={({ currentTarget }) => {
            onChange({ state: currentTarget.value });

            mixpanel.track(Events.PATIENTS_FILTERED, {
              filter_type: 'State',
            });
          }}
          value={values.state}
          size="sm"
          rounded="md"
        >
          <option value="all">All states</option>
          {getStateOptions()}
        </Select>
      </Box>
    </Box>
  );
}
