import { Icon, IconProps } from '@chakra-ui/react';

export default function Scale(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 10 10">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6752_46933)">
          <path
            d="M8.6075 0.875H1.3925C0.9 0.875 0.5 1.27719 0.5 1.76961V8.23039C0.5 8.72539 0.9 9.125 1.3925 9.125H8.61C9.1025 9.125 9.5025 8.72281 9.5025 8.23039V1.76961C9.5025 1.27461 9.1025 0.875 8.61 0.875H8.6075ZM8.365 3.25203L8.0475 4.44828C8.0025 4.62102 7.845 4.72156 7.695 4.67258C7.0575 4.46633 6.4175 4.33484 5.775 4.27555L5.0725 3.30617C5.0625 3.29328 5.05 3.28039 5.0375 3.27266C5.0225 3.26492 5.0075 3.25719 4.99 3.25719C4.9575 3.25203 4.925 3.25719 4.8975 3.27523C4.8825 3.28297 4.87 3.29586 4.8625 3.30875L4.1475 4.28844C3.55 4.35289 2.955 4.47922 2.36 4.67C2.21 4.71898 2.0525 4.62102 2.0075 4.4457L1.69 3.24945C1.6425 3.06898 1.7325 2.8782 1.8875 2.82922C3.9725 2.14602 6.0825 2.14602 8.1675 2.82922C8.3225 2.88078 8.4125 3.07156 8.365 3.24945V3.25203Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_6752_46933">
            <rect
              width="9"
              height="8.25"
              fill="white"
              transform="translate(0.5 0.875)"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
