import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';
import { IBroadcastAttachment } from '../../types';

export async function uploadFile(file: File): Promise<string> {
  const { name } = file;
  const randomUUId = crypto.randomUUID();
  const filePath = `broadcastAttachments/${name}-${randomUUId}`;

  const storageRef = ref(storage, filePath);

  const fileDoc = await uploadBytes(storageRef, file);

  return fileDoc.metadata.fullPath;
}

export function getInitialAttachmentsState(
  attachments: IBroadcastAttachment[],
): {
  fileAttachment?: IBroadcastAttachment;
  links: IBroadcastAttachment[];
} {
  const fileAttachment = attachments.find(
    (attachment) => attachment.type === 'file',
  );
  const links = attachments.filter((attachment) => attachment.type === 'link');

  return {
    fileAttachment,
    links,
  };
}
