import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowRight(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.84467 0.96967C6.13756 0.676777 6.61244 0.676777 6.90533 0.96967L11.4053 5.46967C11.6982 5.76256 11.6982 6.23744 11.4053 6.53033L6.90533 11.0303C6.61244 11.3232 6.13756 11.3232 5.84467 11.0303C5.55178 10.7374 5.55178 10.2626 5.84467 9.96967L9.06434 6.75H1.125C0.710786 6.75 0.375 6.41421 0.375 6C0.375 5.58579 0.710786 5.25 1.125 5.25H9.06434L5.84467 2.03033C5.55178 1.73744 5.55178 1.26256 5.84467 0.96967Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
