import { Skeleton, Stack } from '@chakra-ui/react';

export default function TableLoader() {
  return (
    <Stack gap={2} p={4}>
      <Skeleton h={4} />
      <Skeleton h={4} />
      <Skeleton h={4} />
      <Skeleton h={4} />
    </Stack>
  );
}
