import { Box } from '@chakra-ui/react';
import TaskMessageManager from './TaskMessageManager';
import TaskStatusManager from './TaskStatusManager';
import { IPatient, ITask } from '../../../../types';

interface IProps {
  onUpdateTask: (task: ITask) => void;
  patient: IPatient;
  task: ITask;
}

export default function TaskActions({
  onUpdateTask,
  patient,
  task,
}: IProps): JSX.Element {
  return (
    <Box
      bg="gray.50"
      borderTop="1px solid"
      borderColor="gray.200"
      borderBottomRadius="md"
    >
      <TaskMessageManager patient={patient} task={task} />
      <TaskStatusManager onUpdateTask={onUpdateTask} task={task} />
    </Box>
  );
}
