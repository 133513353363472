import { Box } from '@chakra-ui/react';
import { Navigation } from '../';

interface IProps {
  children: React.ReactNode;
}

export default function Layout({ children }: IProps): JSX.Element {
  return (
    <Box h="100vh" display="grid" gridTemplateColumns="max-content auto">
      <Navigation />

      <Box>{children}</Box>
    </Box>
  );
}
