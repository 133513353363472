import { useEffect, useRef, useState } from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { formatDate } from '../../../../utils';
import { ArrowRight } from '../../../../assets';
import { IPatient } from '../../../../types';
import { DROPDOWN_HEIGHT } from './';

interface IProps {
  isSelected: boolean;
  onClick: (patientUid: string) => void;
  patient: IPatient;
}

export default function SearchResultRow({
  isSelected,
  onClick,
  patient,
}: IProps): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    if (isSelected) {
      const element = ref.current;

      if (!element) {
        return;
      }

      const clientRect = element.getBoundingClientRect();

      // Scroll the element into view if it's not already visible
      if (clientRect.top > DROPDOWN_HEIGHT || clientRect.top < 10) {
        element.scrollIntoView(false);
      }
    }
  }, [isSelected]);

  return (
    <Box
      alignItems="center"
      bg={isSelected || isHovered ? 'gray.200' : ''}
      cursor="pointer"
      display="flex"
      justifyContent="space-between"
      key={patient.uid}
      onMouseDown={() => onClick(patient.uid)}
      p={2}
      pr={4}
      rounded="md"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      ref={ref}
    >
      <Box>
        <Text fontWeight="medium">
          {patient.firstName} {patient.lastName}
        </Text>
        <Text fontSize="sm" color="gray.700">
          DOB: {formatDate(patient.dateOfBirth)}
        </Text>
      </Box>
      {(isSelected || isHovered) && <ArrowRight />}
    </Box>
  );
}
