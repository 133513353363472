import { ChangeEvent, useRef, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { AlertCircle, CheckCircle } from '../../assets';
import { isValidEmail } from './utils';
import { db } from '../../firebase';

interface IProps {
  defaultValue?: string;
  onChange: (email: string) => void;
}

async function queryPatientsCollectionByEmail(email: string): Promise<number> {
  const patientsRef = collection(db, 'patients');
  const q = query(patientsRef, where('email', '==', email));

  const patientsDocs = await getDocs(q);

  return patientsDocs.size;
}

export default function PatientEmailInput({
  defaultValue,
  onChange,
}: IProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const initialValueRef = useRef(defaultValue);

  async function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    setErrorMessage('');
    setIsSuccess(false);

    const { value } = event.target;

    if (!isValidEmail(value)) {
      setErrorMessage('A valid email is required');
      return;
    }

    if (value === initialValueRef.current) {
      onChange(value);
      return;
    }

    const patientCollectionSize = await queryPatientsCollectionByEmail(value);

    if (patientCollectionSize !== 0) {
      setErrorMessage('Email is already in use.');
      onChange('');
    } else {
      onChange(value);
      setErrorMessage('');
      setIsSuccess(true);
    }
  }

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel>Email address</FormLabel>
      <InputGroup>
        <Input
          id="email"
          type="email"
          placeholder="Email address"
          onChange={handleOnChange}
          defaultValue={defaultValue}
        />
        {errorMessage && (
          <InputRightElement children={<AlertCircle color="red.300" />} />
        )}
        {isSuccess && (
          <InputRightElement children={<CheckCircle color="green.400" />} />
        )}
      </InputGroup>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
