import { MenuItem, useDisclosure } from '@chakra-ui/react';
import PatientForm from '../../../PatientForm';
import { Edit } from '../../../../assets';
import { IPatient } from '../../../../types';

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

export default function EditPatient({
  onUpdate,
  patient,
}: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <PatientForm
        initialValues={patient}
        isOpen={isOpen}
        onClose={onClose}
        onUpdate={onUpdate}
      />

      <MenuItem onClick={onOpen} display="flex" alignItems="center" gap={2}>
        <Edit /> Edit patient
      </MenuItem>
    </>
  );
}
