import { Avatar, AvatarProps } from '@chakra-ui/react';
import { ProviderIds } from '../../constants';

interface IProviderAvatarMap {
  [key: string]: string;
}

export const providerImages: IProviderAvatarMap = {
  [ProviderIds.PROVIDER_RB]: require('../../assets/provider-rb.png'),
  [ProviderIds.PROVIDER_ASB]: require('../../assets/provider-asb.png'),
  [ProviderIds.PROVIDER_RL]: require('../../assets/provider-rl.png'),
  [ProviderIds.PROVIDER_DL]: require('../../assets/provider-dl.png'),
  [ProviderIds.PROVIDER_KB]: require('../../assets/provider-kb.png'),
  [ProviderIds.PROVIDER_CB]: require('../../assets/provider-cb.png'),
};

type AvatarSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface IProps extends AvatarProps {
  size?: AvatarSize;
  providerName?: string;
  providerId?: string | null;
}

export default function PractitionerAvatar({
  size,
  providerName,
  providerId,
  ...styleProps
}: IProps): JSX.Element {
  return (
    <Avatar
      size={size}
      name={providerName}
      src={providerId ? providerImages[providerId] : undefined}
      {...styleProps}
    />
  );
}
