import { BroadcastDetailsTab } from '.';
import { IBroadcast } from '../../types';
// import BroadcastDetailsEngagement from './BroadcastDetailsEngagement';
import BroadcastDetailsMessage from './BroadcastDetailsMessage';
import BroadcastDetailsPatientsTable from './BroadcastDetailsPatientsTable';

interface IProps {
  broadcast: IBroadcast;
  onUpdate: () => Promise<void>;
  tab: BroadcastDetailsTab;
  setSelectedPatientUid: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function BroadcastDetailsTabSelection({
  broadcast,
  onUpdate,
  tab,
  setSelectedPatientUid,
}: IProps) {
  switch (tab) {
    case BroadcastDetailsTab.PATIENTS:
      return (
        <BroadcastDetailsPatientsTable
          setSelectedPatientUid={setSelectedPatientUid}
          broadcast={broadcast}
        />
      );

    case BroadcastDetailsTab.MESSAGE:
      return (
        <BroadcastDetailsMessage broadcast={broadcast} onUpdate={onUpdate} />
      );

    // case BroadcastDetailsTab.ENGAGEMENT:
    //   return <BroadcastDetailsEngagement />;
  }
}
