import { Avatar, Text, Box } from '@chakra-ui/react';
import { useMemo } from 'react';
import { IPatient } from '../../types';
import { dayjs, formatDate } from '../../utils';
import { formatWeeksEnrolled } from '../../utils/patientUtils';
import { ExternalLinkButton } from '../buttons';

export default function PatientDetailsSummary({
  patient,
}: {
  patient: IPatient;
}) {
  const dob = formatDate(patient.dateOfBirth);
  const age = dayjs().diff(dayjs(patient.dateOfBirth), 'year');
  const weeksEnrolled = useMemo(() => formatWeeksEnrolled(patient), [patient]);
  const fullName = `${patient.firstName} ${patient.lastName}`;

  return (
    <Box
      px={4}
      pt={6}
      pb={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Avatar
        name={fullName}
        size="lg"
        bg="brand.purple"
        color="brand.lightSky"
        mb={4}
      />
      <Text fontSize="2xl" fontWeight="extrabold" pb={2}>
        {fullName}
      </Text>
      <Box fontSize="xs" fontWeight="medium" display="flex" pb={2}>
        <Text>DOB: {dob}</Text>
        <Text px={2}>•</Text>
        <Text textTransform="capitalize">
          {age} {patient?.sexAtBirth.charAt(0)}
        </Text>
        <Text px={2}>•</Text>
        <Text>Enrolled for: {weeksEnrolled}</Text>
      </Box>

      <Box
        display="flex"
        gap={1}
        fontSize="xs"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontWeight="medium">Open in:</Text>
        {patient?.sourceId && (
          <ExternalLinkButton
            text="Source"
            mixpanelDestination="Source"
            linkUrl={`${process.env.REACT_APP_SOURCE_URL}/members/${patient?.sourceId}`}
          />
        )}
        {patient?.impiloId && (
          <ExternalLinkButton
            text="Impilo"
            mixpanelDestination="Impilo"
            linkUrl={`${process.env.REACT_APP_IMPILO_URL}/patient/${patient?.impiloId}`}
          />
        )}
        {patient?.canvasId && (
          <ExternalLinkButton
            text="Canvas"
            mixpanelDestination="Canvas"
            linkUrl={`${process.env.REACT_APP_CANVAS_URL}/patient/${patient?.canvasId}`}
          />
        )}
        {patient?.customerIOId && (
          <ExternalLinkButton
            text="CustomerIO"
            mixpanelDestination="Customer.io"
            linkUrl={`${process.env.REACT_APP_CUSTOMER_IO_URL}/people/${patient?.customerIOId}`}
          />
        )}

        {patient?.stripeId && (
          <ExternalLinkButton
            text="Stripe"
            mixpanelDestination="Stripe"
            linkUrl={`${process.env.REACT_APP_STRIPE_CUSTOMER_URL}/${patient.stripeId}`}
          />
        )}
      </Box>
    </Box>
  );
}
