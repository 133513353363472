import { Box, Text } from '@chakra-ui/react';
import { IBPDeltaStat } from '../../types';

export const isNegativeInt = (int: number) => Math.sign(int) === -1;

export default function BPDeltaStat({ delta, deltaPercent }: IBPDeltaStat) {
  return (
    <Box>
      <Text
        fontSize="sm"
        fontWeight="medium"
        color={!isNegativeInt(delta) ? 'red.600' : 'green.400'}
      >
        {!isNegativeInt(delta) && '+'}
        {delta.toFixed(1)}
      </Text>
      <Text color="black" opacity="48%" fontSize="xs">
        {!isNegativeInt(deltaPercent) && '+'}
        {deltaPercent}%
      </Text>
    </Box>
  );
}
