import { Box, Text } from '@chakra-ui/react';
import { IBroadcast } from '../../types';
import { TableLoader } from '../../components';
import useFetchPatientsByUid from '../../hooks/useFetchPatientsByUid';
import { PATIENTS_TABLE_COLUMN_LAYOUT } from '../PatientsPage/PatientsTable';
import TableRow from '../PatientsPage/PatientsTable/TableRow';

interface IProps {
  broadcast: IBroadcast;
  setSelectedPatientUid: React.Dispatch<React.SetStateAction<string | null>>;
}

// TODO create customzied broadcast patients table & rows, right now this is copied over from the Patients table
export default function BroadcastDetailsPatientsTable({
  broadcast,
  setSelectedPatientUid,
}: IProps) {
  const { patients, isLoading } = useFetchPatientsByUid(broadcast.patientUids);
  const broadcastSuccessPatientsList = patients.filter(
    (p) => broadcast.errorsByPatientUid.indexOf(p.uid) === -1,
  );
  const broadcastErrorPatientsList = patients.filter(
    (p) => broadcast.errorsByPatientUid.indexOf(p.uid) >= 0,
  );

  return (
    <>
      <Box
        textTransform="uppercase"
        color="blackAlpha.700"
        fontWeight="bold"
        fontSize="sm"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="grid"
        gap={2}
        gridTemplateColumns={PATIENTS_TABLE_COLUMN_LAYOUT}
        py={4}
        px={6}
      >
        <Text>Patient</Text>
        <Text>DOB</Text>
        <Text>Status</Text>
        <Text>Enrolled On</Text>
        <Text>State</Text>
        <Text justifySelf="flex-end">Provider</Text>
      </Box>
      {isLoading && <TableLoader />}
      {!isLoading && (
        <Box h={`calc(100vh - 155px)`} overflow="scroll">
          {broadcastErrorPatientsList.map((patient) => (
            <Box
              key={patient.uid}
              _hover={{
                bg: 'blue.50',
              }}
              overflow="scroll"
              onClick={() => setSelectedPatientUid(patient.uid)}
            >
              <TableRow hasError patient={patient} />
            </Box>
          ))}
          {broadcastSuccessPatientsList.map((patient) => (
            <Box
              key={patient.uid}
              _hover={{
                bg: 'blue.50',
              }}
              overflow="scroll"
              onClick={() => setSelectedPatientUid(patient.uid)}
            >
              <TableRow patient={patient} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
