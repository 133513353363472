import { Box, Text } from '@chakra-ui/react';

interface IProps {
  children: React.ReactNode;
  title: string;
}

export default function TrendItem({ children, title }: IProps): JSX.Element {
  return (
    <Box>
      <Text
        textTransform="uppercase"
        fontSize="sm"
        fontWeight="bold"
        color="blackAlpha.600"
        mb={1}
      >
        {title}
      </Text>

      {children}
    </Box>
  );
}
