import { useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Text,
} from '@chakra-ui/react';
import { useFetchPatients } from '../../../../../hooks';

interface IProps {
  setPatientUids: (patientUids: string[]) => void;
}

export default function PatientEmailsTextArea({
  setPatientUids,
}: IProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [patients, isLoading] = useFetchPatients();

  const validateEmailsAndConvertToPatientUids = useCallback(
    (inputValue: string) => {
      if (!inputValue.trim()) {
        return;
      }
      const emails = inputValue.split(',').map((email) => email.trim());
      const patientUids: string[] = [];
      const invalidEmails: string[] = [];

      emails.forEach((email) => {
        // protects against excess/trailing commas creating empty strings in the emails array
        // and trying to map to patientUids
        if (!email) return;

        const patient = patients.find((patient) => patient.email === email);

        if (patient) {
          patientUids.push(patient.uid);
        } else {
          invalidEmails.push(email);
        }
      });

      if (invalidEmails.length) {
        setPatientUids([]);
        setInvalidEmails(invalidEmails);
      } else {
        setPatientUids(patientUids);
      }
    },
    [patients, setPatientUids],
  );

  useEffect(() => {
    validateEmailsAndConvertToPatientUids(inputValue);
  }, [inputValue, validateEmailsAndConvertToPatientUids]);

  useEffect(() => {
    return () => setInputValue('');
  }, []);

  return (
    <FormControl isRequired>
      <FormLabel>Patient Emails</FormLabel>
      <Textarea
        isDisabled={isLoading}
        placeholder="Patient emails"
        onChange={() => setInvalidEmails([])}
        onBlur={({ currentTarget }) => setInputValue(currentTarget.value)}
      />
      <FormHelperText>
        {isLoading
          ? 'Loading patients...'
          : 'This field accepts a list of comma-separated emails.'}
      </FormHelperText>
      {invalidEmails.length ? (
        <Text mt={2} color="red.500" fontSize="xs">
          We could not find corresponding patients to the following emails:{' '}
          {invalidEmails.map((e, i) => (
            <Text key={e} as="span">
              {e}
              {i !== invalidEmails.length - 1 ? ',' : ''}
            </Text>
          ))}
          <Text>Please correct or remove these entries before continuing.</Text>
        </Text>
      ) : null}
    </FormControl>
  );
}
