import { useMemo, useRef, useState } from 'react';
import { IPanelSection } from '..';

export default function usePanelSections() {
  // tab refs
  const clinicalReviewsTabRef = useRef<HTMLAnchorElement | null>(null);
  const bpReadingsTabRef = useRef<HTMLAnchorElement | null>(null);
  const weightReadingsTabRef = useRef<HTMLAnchorElement | null>(null);
  const glucoseReadingsTabRef = useRef<HTMLAnchorElement | null>(null);
  const recentActivityTabRef = useRef<HTMLAnchorElement | null>(null);
  const personalInfoTabRef = useRef<HTMLAnchorElement | null>(null);
  const clinicInfoTabRef = useRef<HTMLAnchorElement | null>(null);
  const paymentInfoTabRef = useRef<HTMLAnchorElement | null>(null);

  // some panel setions are conditionally rendered and need refs set via useState
  // so that ref changes trigger a rerender and determine which tabs to display
  const [clinicalReviewsRef, setClinicalReviewRef] =
    useState<HTMLDivElement | null>(null);
  const [bpReadingsRef, setBpReadingsRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [weightReadingsRef, setWeightReadingsRef] =
    useState<HTMLDivElement | null>(null);
  const [glucoseReadingsRef, setGlucoseReadingsRef] =
    useState<HTMLDivElement | null>(null);
  const [recentActivityRef, setRecentActivityRef] =
    useState<HTMLDivElement | null>(null);
  const [personalInfoRef, setPersonalInfoRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [clinicInfoRef, setClinicInfoRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [paymentInfoRef, setPaymentInfoRef] = useState<HTMLDivElement | null>(
    null,
  );

  const sections: IPanelSection[] = useMemo(
    () => [
      {
        title: 'Clinical Reviews',
        event_property: 'clinical_reviews',
        hash: '#clinical-reviews',
        tabRef: clinicalReviewsTabRef,
        ref: clinicalReviewsRef,
      },
      {
        title: 'Blood Pressure Data',
        event_property: 'bp_readings',
        hash: '#bp-readings',
        tabRef: bpReadingsTabRef,
        ref: bpReadingsRef,
      },
      {
        title: 'Weight Data',
        event_property: 'weight_readings',
        hash: '#weight-readings',
        tabRef: weightReadingsTabRef,
        ref: weightReadingsRef,
      },
      {
        title: 'Blood Glucose Data',
        event_property: 'glucose_readings',
        hash: '#glucose-readings',
        tabRef: glucoseReadingsTabRef,
        ref: glucoseReadingsRef,
      },
      {
        title: 'Recent Activity',
        event_property: 'recent_activity',
        hash: '#recent-activity',
        tabRef: recentActivityTabRef,
        ref: recentActivityRef,
      },
      {
        title: 'Personal Info',
        event_property: 'personal_info',
        hash: '#personal-info',
        tabRef: personalInfoTabRef,
        ref: personalInfoRef,
      },
      {
        title: 'Clinic Info',
        event_property: 'clinic_info',
        hash: '#clinic-info',
        tabRef: clinicInfoTabRef,
        ref: clinicInfoRef,
      },
      {
        title: 'Payment Info',
        event_property: 'payment_info',
        hash: '#payment-info',
        tabRef: paymentInfoTabRef,
        ref: paymentInfoRef,
      },
    ],
    [
      bpReadingsRef,
      clinicInfoRef,
      clinicalReviewsRef,
      glucoseReadingsRef,
      paymentInfoRef,
      personalInfoRef,
      recentActivityRef,
      weightReadingsRef,
    ],
  );

  return {
    sections,
    setClinicalReviewRef,
    setBpReadingsRef,
    setWeightReadingsRef,
    setGlucoseReadingsRef,
    setRecentActivityRef,
    setPersonalInfoRef,
    setClinicInfoRef,
    setPaymentInfoRef,
  };
}
