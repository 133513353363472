import { forwardRef, useState } from 'react';
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import BloodPressureTrends from './BloodPressureTrends';
import Goal from './Goal';
import TwoWeekAverage from './TwoWeekAverage';
import LatestReading from './LatestReading';
import ReadingsTable from '../ReadingsTable';
import { useFetchPatientBPReadings } from '../../../hooks';
import { IPatient } from '../../../types';
import { CopyFilled } from '../../../assets';
import { dayjs, getBPSnapshotFromReadings } from '../../../utils';
import { constructBpNarrativeMessage } from './utils';
import { Events, mixpanel } from '../../../analytics';
import { TAB_BAR_HEIGHT } from '..';

interface IProps {
  patient: IPatient;
}

const twoWeeksAgoDate = dayjs().subtract(14, 'day').startOf('day').toDate();

const BloodPressureReadings = forwardRef(
  ({ patient }: IProps, ref: React.ForwardedRef<null>) => {
    const [bpReadings, isLoadingBPReadings] = useFetchPatientBPReadings(
      patient.uid,
      twoWeeksAgoDate,
    );
    const { averages, latestReading } = getBPSnapshotFromReadings(bpReadings);
    const [bpNarrativeTooltip, setBPNarrativeTooltip] =
      useState<boolean>(false);

    const { enabled, message } = constructBpNarrativeMessage({
      ...averages,
      patient,
      readings: bpReadings,
    });

    async function handleCopyBPNarrativeMessage(message: string) {
      await navigator.clipboard.writeText(message);

      mixpanel.track(Events.SNIPPET_COPIED, {
        snippet_name: 'Blood Pressure Narrative',
      });

      setBPNarrativeTooltip(true);
    }

    const patientEnrolledInBpProgram = patient.programs.hypertension.enrolled;

    if (!patientEnrolledInBpProgram) {
      return null;
    }

    return (
      <Box ref={ref} pt={6} scrollMargin={TAB_BAR_HEIGHT}>
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontWeight="medium">Blood Pressure Data</Text>

          <Tooltip
            closeDelay={500}
            label="Copied BP narrative message"
            isOpen={bpNarrativeTooltip}
            hasArrow
            onClose={() => setBPNarrativeTooltip(false)}
            rounded="md"
            px={3}
            py={1}
            mx={4}
          >
            <Button
              isDisabled={!enabled}
              onClick={() => handleCopyBPNarrativeMessage(message)}
              leftIcon={<CopyFilled />}
              variant="outline"
              size="sm"
            >
              Copy narrative
            </Button>
          </Tooltip>
        </Flex>

        <BloodPressureTrends patientUid={patient.uid} />

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
          <Goal patient={patient} />

          <TwoWeekAverage
            {...averages}
            isLoading={isLoadingBPReadings}
            patient={patient}
            readingsCount={bpReadings.length}
          />

          <LatestReading
            isLoading={isLoadingBPReadings}
            reading={latestReading}
          />
        </Box>

        {patientEnrolledInBpProgram && (
          <Box mt={4}>
            <ReadingsTable
              patient={patient}
              readingType="bloodPressure"
              tableTitle="All BP Readings"
            />
          </Box>
        )}
      </Box>
    );
  },
);

export default BloodPressureReadings;
