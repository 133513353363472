import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { IPatient } from '../types';
import { useToast } from '@chakra-ui/react';
import { ProviderIds, PROVIDERS_LIST } from '../constants';

export default function useFetchPatientsByProvider(
  selectedProviderIds: ProviderIds[],
): [IPatient[], boolean] {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patients, setPatients] = useState<IPatient[]>([]);

  useEffect(() => {
    async function fetchPatients() {
      const patients: IPatient[] = [];
      const patientsCollection = collection(db, 'patients');

      try {
        const allProvidersSelected =
          selectedProviderIds.length === PROVIDERS_LIST.length;

        // query without any constraints
        if (allProvidersSelected) {
          const q = query(patientsCollection);
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            patients.push({
              uid: doc.id,
              ...doc.data(),
            } as IPatient);
          });
        } else {
          const specificSelectedProviders = selectedProviderIds.filter(
            (id) => id !== 'other',
          );
          // only specific providers selected
          if (Boolean(specificSelectedProviders.length)) {
            const q = query(
              patientsCollection,
              ...getProviderQueryConstraints(specificSelectedProviders),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              patients.push({
                uid: doc.id,
                ...doc.data(),
              } as IPatient);
            });
          }

          // "Other/Unassigned" is selected
          if (selectedProviderIds.includes(ProviderIds.PROVIDER_OTHER)) {
            const otherProvidersQuery = query(
              patientsCollection,
              ...otherProvidersQueryConstraints,
            );

            let otherProvidersQuerySnapshot = await getDocs(
              otherProvidersQuery,
            );

            otherProvidersQuerySnapshot.forEach((doc) => {
              patients.push({
                uid: doc.id,
                ...doc.data(),
              } as IPatient);
            });

            const noProviderQuery = query(
              patientsCollection,
              noProviderQueryConstraints,
            );

            let noProviderQuerySnapshot = await getDocs(noProviderQuery);

            noProviderQuerySnapshot.forEach((doc) => {
              patients.push({
                uid: doc.id,
                ...doc.data(),
              } as IPatient);
            });
          }
        }
      } catch (e) {
        toast({
          description: 'Error fetching blood pressure trends.',
          status: 'error',
          title: 'Uh oh!',
        });
      } finally {
        setPatients(patients);
        setIsLoading(false);
      }
    }

    fetchPatients();
  }, [selectedProviderIds, toast]);

  return [patients, isLoading];
}

export const getProviderQueryConstraints = (
  selectedProviderIds: ProviderIds[],
): QueryConstraint[] => {
  return [
    where('careTeamLead.adminId', 'in', selectedProviderIds),
    orderBy('careTeamLead.adminId', 'desc'),
  ];
};

export const otherProvidersQueryConstraints = [
  where('careTeamLead.adminId', 'not-in', [
    ProviderIds.PROVIDER_RB,
    ProviderIds.PROVIDER_ASB,
    ProviderIds.PROVIDER_RL,
    ProviderIds.PROVIDER_DL,
    ProviderIds.PROVIDER_KB,
  ]),
  orderBy('careTeamLead.adminId', 'desc'),
];

export const noProviderQueryConstraints = where('careTeamLead', '==', null);
