import { Box, Button, TabPanel, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MessageTemplates } from '../../../../../analytics';
import { CheckCircle, PaperPlane } from '../../../../../assets';

const SuccessMessage = () => {
  const date = dayjs().format('MM-DD-YYYY [at] h:mm a');

  return (
    <Box
      alignItems="center"
      display="flex"
      gap={2}
      bg="green.100"
      rounded="md"
      px={2}
      py={1}
    >
      <CheckCircle color="green.500" />
      <Text fontSize="sm">Message sent on {date}</Text>
    </Box>
  );
};

interface IProps {
  eventType: MessageTemplates;
  isLoading: boolean;
  isSuccess: boolean;
  messageBody: string;
  threadSubject: string;
  onSendMessage(
    message: string,
    threadSubject: string,
    messageTemplate: MessageTemplates,
  ): Promise<void>;
}

export default function MessageTab({
  eventType,
  isLoading,
  isSuccess,
  messageBody,
  threadSubject,
  onSendMessage,
}: IProps): JSX.Element {
  return (
    <TabPanel>
      <Box mb={4} whiteSpace="pre-wrap">
        {messageBody}
      </Box>

      {isSuccess ? (
        <SuccessMessage />
      ) : (
        <Box display="flex" justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="blue"
            leftIcon={<PaperPlane />}
            isLoading={isLoading}
            onClick={() => onSendMessage(messageBody, threadSubject, eventType)}
          >
            Send Message
          </Button>
        </Box>
      )}
    </TabPanel>
  );
}
