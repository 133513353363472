import { Box, Button, Text, useToast } from '@chakra-ui/react';
import SelectedTaskDetails from './SelectedTaskDetails';
import { IPatient, ITask } from '../../../types';
import { ChevronLeft } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { HEADER_CONTAINER_HEIGHT, SHOW_PATIENT_QUERY_PARAM } from '..';
import { PatientName } from '../../../components';

export default function SelectedTask({
  selectedTask,
  onUpdateTask,
  showPatientDetailsPanel,
  patient,
  patientLoading,
}: {
  selectedTask: ITask;
  onUpdateTask: (task: ITask) => void;
  showPatientDetailsPanel: boolean;
  patient?: IPatient;
  patientLoading: boolean;
}) {
  const navigate = useNavigate();
  const toast = useToast();

  function handleOpenPatientDetailsPanel() {
    if (selectedTask && patient) {
      navigate(`/today/${selectedTask.id}?${SHOW_PATIENT_QUERY_PARAM}`);
    } else {
      toast({
        description: 'Could not fetch patient associated with this task.',
        status: 'error',
        title: 'Uh Oh',
      });
      return;
    }
  }

  return (
    <>
      <Box
        h={HEADER_CONTAINER_HEIGHT}
        bg="white"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={6}
        position="sticky"
        top={0}
        zIndex={1}
      >
        <PatientName
          patient={patient}
          isLoading={patientLoading}
          fontSize="lg"
          fontWeight="bold"
        />
        {!showPatientDetailsPanel && (
          <Button
            variant="ghost"
            display="flex"
            flexDirection="row"
            alignItems="center"
            onClick={handleOpenPatientDetailsPanel}
          >
            <Text fontSize="sm" fontWeight="medium" mr={1}>
              Show patient info
            </Text>
            <ChevronLeft />
          </Button>
        )}
      </Box>
      <Box p={2}>
        <SelectedTaskDetails
          onUpdateTask={onUpdateTask}
          task={selectedTask}
          patient={patient}
          patientLoading={patientLoading}
        />
      </Box>
    </>
  );
}
