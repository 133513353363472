import { Box, Link } from '@chakra-ui/react';
import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Layout,
  PatientDetailsPanelColumn,
  TableLoader,
} from '../../components';
import { db } from '../../firebase';
import { IBroadcast } from '../../types';
import BroadcastDetailsTabSelection from './BroadcastDetailsTabSelection';
import Header from './Header';

export enum BroadcastDetailsTab {
  MESSAGE = 'Message',
  PATIENTS = 'Patients',
  //   ENGAGEMENT = 'Engagement',
}
const BROADCAST_DETAILS_TABS = [
  BroadcastDetailsTab.MESSAGE,
  BroadcastDetailsTab.PATIENTS,
  //   BroadcastDetailsTab.ENGAGEMENT,
];

export default function BroadcastDetailsPage() {
  const [selectedPatientUid, setSelectedPatientUid] = useState<string | null>(
    null,
  );
  const { id: broadcastId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [broadcast, setBroadcast] = useState<IBroadcast | undefined>();
  const [selectedTab, setSelectedTab] = useState<BroadcastDetailsTab>(
    BROADCAST_DETAILS_TABS[0],
  );

  const fetchBroadcast = useCallback(async () => {
    if (!broadcastId) {
      return;
    }

    setIsLoading(true);

    const broadcastRef = doc(db, 'broadcasts', broadcastId);
    const broadcastDoc = await getDoc(broadcastRef);
    const broadcast = {
      ...broadcastDoc.data(),
      id: broadcastDoc.id,
    } as IBroadcast;

    setBroadcast(broadcast);
    setIsLoading(false);
  }, [broadcastId]);

  useEffect(() => {
    fetchBroadcast();
  }, [fetchBroadcast]);

  return (
    <Layout>
      <Box
        display="grid"
        gridTemplateColumns={`auto ${selectedPatientUid ? '600px' : null}`}
      >
        <Box>
          <Header
            broadcast={broadcast}
            isLoading={isLoading}
            fetchBroadcast={fetchBroadcast}
            setSelectedTab={setSelectedTab}
          />

          {isLoading ? (
            <TableLoader />
          ) : broadcast ? (
            <>
              <Box
                pl={6}
                display="flex"
                gap={6}
                borderBottom="1px solid"
                borderColor="gray.200"
              >
                {BROADCAST_DETAILS_TABS.map((t) => (
                  <Link
                    key={t}
                    _hover={{ textDecoration: 'none' }}
                    fontWeight="medium"
                    color={selectedTab === t ? 'blue.600' : 'blackAlpha.600'}
                    onClick={() => setSelectedTab(t)}
                    whiteSpace="nowrap"
                    borderColor="blue.600"
                    borderBottom={selectedTab === t ? '2px solid' : undefined}
                    py={2}
                  >
                    {t}
                  </Link>
                ))}
              </Box>
              <BroadcastDetailsTabSelection
                tab={selectedTab}
                broadcast={broadcast}
                onUpdate={fetchBroadcast}
                setSelectedPatientUid={setSelectedPatientUid}
              />
            </>
          ) : null}
        </Box>
        {selectedPatientUid && (
          <Box boxShadow="2xl">
            <PatientDetailsPanelColumn
              context="Broadcast"
              patientUid={selectedPatientUid}
              onClose={() => setSelectedPatientUid(null)}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}
