import { useMemo } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import {
  formatDate,
  formatEnrolledOn,
  formatPatientAge,
  formatWeeksEnrolled,
} from '../../../../../../utils';
import { IPatient } from '../../../../../../types';

interface IProps {
  hasBorder: boolean;
  hasHover?: boolean;
  onRemove?: () => void;
  patient: IPatient;
}

export default function SearchResultRow({
  hasBorder,
  hasHover = true,
  onRemove,
  patient,
}: IProps): JSX.Element {
  const { firstName, lastName, dateOfBirth } = patient;

  const name = `${firstName} ${lastName}`;
  const dob = useMemo(() => formatDate(dateOfBirth), [dateOfBirth]);
  const age = useMemo(() => formatPatientAge(dateOfBirth), [dateOfBirth]);
  const enrolledOn = useMemo(() => formatEnrolledOn(patient), [patient]);
  const weeksEnrolled = useMemo(() => formatWeeksEnrolled(patient), [patient]);

  return (
    <Box
      alignItems="center"
      borderBottom={hasBorder ? '1px solid' : 'none'}
      borderColor="gray.200"
      cursor="pointer"
      display="grid"
      fontSize="sm"
      gridTemplateColumns="1.5fr .75fr 1fr .5fr"
      py={2}
      px={4}
      _hover={{
        bg: `${hasHover ? 'gray.50' : 'none'}`,
      }}
    >
      <Box display="flex" gap={4}>
        <Text fontWeight="medium">{name}</Text>
      </Box>

      <Box>
        <Text>{dob}</Text>
        <Text color="gray.600">Age: {age}</Text>
      </Box>

      <Box>
        <Text>{enrolledOn}</Text>
        <Text color="gray.600">Enrolled: {weeksEnrolled}</Text>
      </Box>

      {onRemove && (
        <Button onClick={onRemove} size="xs" variant="ghost">
          Remove
        </Button>
      )}
    </Box>
  );
}
