import { useEffect, useState } from 'react';
import {
  collection,
  documentId,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { IPatient } from '../types';
import { useToast } from '@chakra-ui/react';

type TReturnValue = {
  isLoading: boolean;
  patients: IPatient[];
};

export default function useFetchPatientsByUid(
  patientUids?: string[],
): TReturnValue {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patients, setPatients] = useState<IPatient[]>([]);

  useEffect(() => {
    if (!patientUids || !patientUids.length) {
      return;
    }

    const fetchPatients = async () => {
      const patientUidsToBatch = [...patientUids];
      const patientsData: IPatient[] = [];

      try {
        setIsLoading(true);
        const patientsCollection = collection(db, 'patients');

        while (patientUidsToBatch.length) {
          const batch = patientUidsToBatch.splice(0, 10);

          const q = query(patientsCollection, where(documentId(), 'in', batch));
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            patientsData.push({ uid: doc.id, ...doc.data() } as IPatient);
          });
        }
      } catch (e) {
        toast({
          description: 'Error fetching patients by uid.',
          status: 'error',
          title: 'Uh oh!',
        });
      } finally {
        setPatients(patientsData);
        setIsLoading(false);
      }
    };

    fetchPatients();

    return () => {
      setPatients([]);
    };
  }, [patientUids, toast]);

  return {
    patients,
    isLoading,
  };
}
