import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { formatDate } from '../../utils';
import { CommunicationPreference, IPatient } from '../../types';
import { forwardRef } from 'react';
import { TAB_BAR_HEIGHT } from '.';

interface IProps {
  patient: IPatient;
}

const ContactInformation = forwardRef(
  ({ patient }: IProps, ref: React.ForwardedRef<null>) => {
    const {
      address,
      communicationPreference,
      dateOfBirth,
      email,
      firstName,
      lastName,
      phoneNumber,
    } = patient;
    const dob = formatDate(dateOfBirth);
    const age = dayjs().diff(dayjs(dateOfBirth), 'year');

    return (
      <Box ref={ref} pt={6} scrollMargin={TAB_BAR_HEIGHT}>
        <Text fontWeight="medium" mb={4}>
          Personal Info
        </Text>

        <Box
          display="grid"
          gridTemplateColumns="min-content auto"
          gridColumnGap={10}
          gridRowGap={2}
          border="1px solid"
          borderColor="blackAlpha.200"
          rounded="md"
          p={4}
        >
          <Text color="blackAlpha.700" fontSize="sm">
            First name
          </Text>
          <Text>{firstName}</Text>

          <Text color="blackAlpha.700" fontSize="sm">
            Last name
          </Text>
          <Text>{lastName}</Text>

          <Text color="blackAlpha.700" fontSize="sm">
            Phone
          </Text>
          <Text>{phoneNumber}</Text>

          <Text color="blackAlpha.700" fontSize="sm">
            Email
          </Text>
          <Text>{email}</Text>

          <Text color="blackAlpha.700" fontSize="sm">
            Preference
          </Text>
          <Text
            textTransform={
              communicationPreference === CommunicationPreference.SMS
                ? 'uppercase'
                : 'capitalize'
            }
          >
            {communicationPreference}
          </Text>

          <Text color="blackAlpha.700" fontSize="sm">
            Address
          </Text>
          <Box>
            <Text>{address.lineOne}</Text>
            {address.lineTwo && <Text>{address.lineTwo}</Text>}
            <Text>
              {address.city}, {address.state} {address.zipCode}
            </Text>
          </Box>

          <Text color="blackAlpha.700" fontSize="sm">
            DOB
          </Text>
          <Box>
            <Text>{dob}</Text>
            <Text color="blackAlpha.600" fontSize="sm">
              Age: {age}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  },
);

export default ContactInformation;
