import { BloodPressureSeverity } from '../../types';

export default function getBPSeverity(
  systolic?: number,
  diastolic?: number,
): BloodPressureSeverity | null {
  if (!systolic || !diastolic) {
    return null;
  }

  if (systolic < 120 && diastolic < 80) {
    return BloodPressureSeverity.NORMAL;
  }

  if (systolic < 130 && diastolic < 80) {
    return BloodPressureSeverity.ELEVATED;
  }

  if (systolic < 140 || diastolic < 90) {
    return BloodPressureSeverity.HYPERTENSION_STAGE_1;
  }

  if (systolic < 180 || diastolic < 120) {
    return BloodPressureSeverity.HYPERTENSION_STAGE_2;
  }

  return BloodPressureSeverity.HYPERTENSION_CRISIS;
}
