import { Button, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { CopyFilled } from '../../assets';
import { Events, mixpanel, TSnippetName } from '../../analytics';
interface IProps {
  copyEventName?: TSnippetName;
  label?: string;
  text: string;
}

export default function CopyButton({ copyEventName, label, text }: IProps) {
  async function handleClick() {
    await navigator.clipboard.writeText(text);

    if (copyEventName) {
      mixpanel.track(Events.SNIPPET_COPIED, {
        snippet_name: copyEventName,
      });
    }

    setDisplayTooltip(true);
  }

  const [displayTooltip, setDisplayTooltip] = useState(false);
  return (
    <Tooltip
      label={`Copied ${label ?? ''}`}
      isOpen={displayTooltip}
      hasArrow
      onClose={() => setDisplayTooltip(false)}
      rounded="md"
    >
      <Button
        left={2}
        onClick={handleClick}
        position="relative"
        size="xs"
        variant="ghost"
      >
        <CopyFilled color="blackAlpha.700" />
      </Button>
    </Tooltip>
  );
}
