import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowLeft(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.15533 0.96967C6.44822 1.26256 6.44822 1.73744 6.15533 2.03033L2.93566 5.25H10.875C11.2892 5.25 11.625 5.58579 11.625 6C11.625 6.41421 11.2892 6.75 10.875 6.75H2.93566L6.15533 9.96967C6.44822 10.2626 6.44822 10.7374 6.15533 11.0303C5.86244 11.3232 5.38756 11.3232 5.09467 11.0303L0.59467 6.53033C0.301777 6.23744 0.301777 5.76256 0.59467 5.46967L5.09467 0.96967C5.38756 0.676777 5.86244 0.676777 6.15533 0.96967Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
