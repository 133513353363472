import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  where,
} from 'firebase/firestore';
import { FilterPriority, FilterValues } from './Filters/context';
import { Events, mixpanel } from '../../analytics';
import { db } from '../../firebase';
import { ProviderIds, PROVIDERS_LIST } from '../../constants';
import { ClinicalReviewStates, IPatient } from '../../types';

type ReturnValue = [IPatient[], boolean, (patientUid?: string) => void];

export function useFetchPatients(filterValues: FilterValues): ReturnValue {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function onClinicalStateChange(patientUid?: string) {
    if (!patientUid) {
      return;
    }

    const updatedPatients = patients.filter((patient) => {
      return patient.uid !== patientUid;
    });

    setPatients(updatedPatients);
  }

  useEffect(() => {
    async function fetchPatients(filterValues: FilterValues): Promise<void> {
      const { priority, providerIds, reviewState, segmentIds } = filterValues;
      const patientsCollection = collection(db, 'patients');
      const queryConstraints: QueryConstraint[] = [
        where('clinicalReviewState.state', '==', reviewState),
        where('deactivatedOn', '==', null),
        where('isActive', '==', true),
        where('isEnrolled', '==', true),
      ];
      const orderingQueryConstraint = getOrderingQueryConstraint(priority);

      queryConstraints.push(orderingQueryConstraint);

      if (providerIds.length !== PROVIDERS_LIST.length) {
        const isOtherSelected = providerIds.includes(
          ProviderIds.PROVIDER_OTHER,
        );

        if (isOtherSelected) {
          queryConstraints.push(
            where('careTeamLead.adminId', 'not-in', [
              ProviderIds.PROVIDER_RB,
              ProviderIds.PROVIDER_ASB,
              ProviderIds.PROVIDER_RL,
              ProviderIds.PROVIDER_DL,
              ProviderIds.PROVIDER_KB,
            ]),
          );
        } else {
          queryConstraints.push(
            where('careTeamLead.adminId', 'in', providerIds),
          );
        }
      }

      if (segmentIds.length > 0) {
        queryConstraints.push(
          where('segments', 'array-contains-any', segmentIds),
        );
      }

      const q = query(patientsCollection, ...queryConstraints);

      setIsLoading(true);
      trackFilterEvent(filterValues);

      try {
        const querySnapshot = await getDocs(q);
        const patients: IPatient[] = [];

        querySnapshot.forEach((doc) => {
          const patient = {
            uid: doc.id,
            ...doc.data(),
          } as IPatient;

          if (
            segmentIds.length > 1 &&
            !isPatientInAllSegments(patient, segmentIds)
          ) {
            return;
          }

          patients.push({
            uid: doc.id,
            ...doc.data(),
          } as IPatient);
        });

        setPatients(patients);
      } catch (error) {
        console.error('Error fetching patients:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPatients(filterValues);
  }, [filterValues]);

  return [patients, isLoading, onClinicalStateChange];
}

function getOrderingQueryConstraint(priority: FilterPriority): QueryConstraint {
  switch (priority) {
    case FilterPriority.DAYS_IN_STAGE:
      return orderBy('clinicalReviewState.date', 'asc');
    case FilterPriority.DISTANCE_FROM_DIASTOLIC_GOAL:
      return orderBy('bloodPressureGoalsDiastolicDelta', 'desc');
    case FilterPriority.DISTANCE_FROM_SYSTOLIC_GOAL:
      return orderBy('bloodPressureGoalsSystolicDelta', 'desc');
    case FilterPriority.LAST_PHONE_OUTREACH:
      return orderBy('engagementActivity.lastPhoneCallReceivedAt', 'asc');
    default:
      return orderBy('clinicalReviewState.date', 'asc');
  }
}

function isPatientInAllSegments(
  patient: IPatient,
  segmentIds: string[],
): boolean {
  const { segments } = patient;

  return segmentIds.every((segmentId) => segments.includes(segmentId));
}

function trackFilterEvent(filterValues: FilterValues): void {
  const { priority, providerIds, reviewState } = filterValues;

  mixpanel.track(Events.SPOTLIGHT_PATIENTS_FILTERED, {
    clinical_review_state: reviewState,
    provider_ids: providerIds,
    priority,
  });
}

export function reviewStateIsSegmented(state: ClinicalReviewStates): boolean {
  return (
    state === ClinicalReviewStates.BLOCKED ||
    state === ClinicalReviewStates.CHASE_LIST
  );
}
