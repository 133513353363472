import { Box } from '@chakra-ui/react';
import ProviderSelect from './ProviderSelect';
import FilterMenu from './FilterMenu';

export default function Filters(): JSX.Element {
  return (
    <Box
      alignItems="end"
      gridTemplateColumns="max-content max-content"
      gap={2}
      display="grid"
    >
      <ProviderSelect />

      <FilterMenu />
    </Box>
  );
}

export { default as FilterProvider, useFilters } from './context';
