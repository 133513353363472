import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  useToast,
} from '@chakra-ui/react';
import { useGetAdmin } from '../../../contexts';
import MessageEntry from './MessageEntry';
import PatientSelection from './PatientSelection';
import Loader from './Loader';
import { createBroadcastDraft } from './utils';
import { IBroadcastAttachment } from '../../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateBroadcastModal({
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [messageBody, setMessageBody] = useState<string>('');
  const [threadSubject, setThreadSubject] = useState<string>('');
  const [attachments, setAttachments] = useState<IBroadcastAttachment[]>([]);
  const [patientUids, setPatientUids] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { uid } = useGetAdmin();

  async function handleOnSendBroadcast() {
    setIsLoading(true);

    try {
      const broadcastId = await createBroadcastDraft({
        attachments,
        messageBody,
        patientUids,
        providerUid: uid,
        threadSubject,
      });

      navigate(`/broadcasts/${broadcastId}`);

      handleOnClose();

      toast({
        description: `Broadcast draft created.`,
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      setIsLoading(false);
      toast({
        description: 'An error occurred sending the broadcast draft.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  function handleOnClose() {
    setPatientUids([]);
    setThreadSubject('');
    setMessageBody('');
    setIsLoading(false);
    setTabIndex(0);

    onClose();
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleOnClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Broadcast</ModalHeader>
        <ModalCloseButton />

        {isLoading ? (
          <Loader />
        ) : (
          <ModalBody>
            <Tabs index={tabIndex}>
              <TabList>
                <Tab>1. Select patients</Tab>
                <Tab>2. Enter message</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <PatientSelection setPatientUids={setPatientUids} />
                </TabPanel>
                <TabPanel>
                  <MessageEntry
                    isLoading={isLoading}
                    onAttachmentsChange={setAttachments}
                    onMessageBodyChange={setMessageBody}
                    onThreadSubjectChange={setThreadSubject}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        )}

        <ModalFooter>
          {tabIndex === 1 && (
            <>
              <Button
                isDisabled={isLoading}
                mr={3}
                onClick={() => setTabIndex(0)}
                variant="outline"
              >
                Back
              </Button>
              <Button
                colorScheme="blue"
                isDisabled={!messageBody || !threadSubject}
                isLoading={isLoading}
                onClick={handleOnSendBroadcast}
              >
                Create Broadcast
              </Button>
            </>
          )}

          {tabIndex === 0 && (
            <Button
              colorScheme="blue"
              isDisabled={!patientUids.length}
              isLoading={isLoading}
              onClick={() => setTabIndex(1)}
            >
              Next
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
