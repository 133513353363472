import {
  FormControl,
  FormLabel,
  Radio,
  Text,
  useRadio,
  useRadioGroup,
  Box,
} from '@chakra-ui/react';
import { BillingType } from '../../types';

interface IProps {
  onChange: (billingType: BillingType) => void;
  value: BillingType;
}

const radioOptions = [
  {
    value: BillingType.INSURANCE,
    label: 'Insurance billed',
    description: 'The patient is using their insurance to pay for services.',
  },
  {
    value: BillingType.SELF_PAY,
    label: 'Self Pay',
    description: 'The patient is paying our subscription price.',
  },
];

export default function PaymentStatusRadio({
  onChange,
  value,
}: IProps): JSX.Element {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'paymentStatus',
    defaultValue: value || BillingType.SELF_PAY,
    onChange,
  });

  const group = getRootProps();

  return (
    <FormControl isRequired>
      <FormLabel>Status</FormLabel>
      <Box {...group} border="1px solid" borderColor="gray.200" rounded="lg">
        {radioOptions.map(({ description, label, value }) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard
              key={value}
              description={description}
              label={label}
              radio={radio}
            />
          );
        })}
      </Box>
    </FormControl>
  );
}

function RadioCard({
  description,
  label,
  radio,
}: {
  description: string;
  label: string;
  radio: any;
}): JSX.Element {
  const { getInputProps, getCheckboxProps } = useRadio(radio);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { checked, value } = input;

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        py={2}
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={checked ? 'blue.50' : undefined}
        borderBottom={value === 'covered' ? '1px solid' : undefined}
        borderColor="gray.200"
      >
        <Box>
          <Text>{label}</Text>
          <Text fontSize="sm" color="gray.500">
            {description}
          </Text>
        </Box>

        <Radio isChecked={checked} />
      </Box>
    </Box>
  );
}
