import { IBloodPressureGoals } from '../../types';

interface IGetDistanceFromBPGoalParams {
  avgDiastolic: number;
  avgSystolic: number;
  goals?: IBloodPressureGoals[];
}

export function getDistanceFromBPGoal({
  avgDiastolic,
  avgSystolic,
  goals,
}: IGetDistanceFromBPGoalParams): string | null {
  if (!goals || !goals.length) {
    return null;
  }

  const { diastolic: diastolicGoal, systolic: systolicGoal } = goals[0];

  const diastolicDistance = Math.round(avgDiastolic - diastolicGoal);
  const systolicDistance = Math.round(avgSystolic - systolicGoal);

  return `${systolicDistance}/${diastolicDistance} from goal`;
}
