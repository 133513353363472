import { useEffect, useState } from 'react';
import { Box, Spinner, Text, Textarea, useToast } from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function Notes({ patient }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    const { comment } = patient.clinicalReviewState;

    if (comment) {
      setValue(comment);
    }
  }, [patient]);

  async function handleOnBlur() {
    setIsLoading(true);

    try {
      const patientRef = doc(db, `patients/${patient.uid}`);

      await updateDoc(patientRef, {
        'clinicalReviewState.comment': value,
      });

      toast({
        description: 'Notes successfully updated',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description: 'An error occurred while updating notes',
        status: 'error',
        title: 'Error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box>
      <Text mb={4} fontWeight="medium">
        Notes
      </Text>

      <Box position="relative">
        {isLoading && (
          <Box
            alignItems="center"
            bottom={0}
            bg="gray.100"
            display="flex"
            flexDirection="column"
            gap={1}
            justifyContent="center"
            left={0}
            position="absolute"
            right={0}
            top={0}
            zIndex={1}
          >
            <Spinner color="purple" />
            <Text fontSize="xs">Loading...</Text>
          </Box>
        )}
        <Textarea
          bg="white"
          value={value}
          onBlur={handleOnBlur}
          onChange={({ currentTarget }) => setValue(currentTarget.value)}
        />
      </Box>
    </Box>
  );
}
