import { Box, Heading } from '@chakra-ui/react';
import { useState } from 'react';
import { Layout, PatientDetailsPanelColumn } from '../../components';
import { PatientTrendChangeType, PatientTrendTimePeriod } from '../../types';
import { TrendingTable } from '../../components/Trending';
import { useNavigate, useParams } from 'react-router-dom';
import TrendingFilters from '../../components/Trending/TrendingFilters';
import { useFetchTrendingPatientsByProvider } from '../../hooks';
import { ProviderIds, PROVIDERS_LIST } from '../../constants';

export default function TrendingPage(): JSX.Element {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [trendChangeType, setTrendChangeType] =
    useState<PatientTrendChangeType>(PatientTrendChangeType.WORSE);
  const [trendPeriod, setTrendPeriodType] = useState<PatientTrendTimePeriod>(
    PatientTrendTimePeriod.WEEKLY,
  );

  const [selectedProviderIds, setSelectedProviderIds] = useState<ProviderIds[]>(
    PROVIDERS_LIST.map((p) => p.id),
  );
  const [trendingPatients, isLoading] = useFetchTrendingPatientsByProvider(
    selectedProviderIds,
    trendChangeType,
    trendPeriod,
  );

  function handleClose() {
    navigate('/trending');
  }

  return (
    <Layout>
      <Box display="grid" gridTemplateColumns={`auto ${uid && '600px'}`}>
        <Box display="flex" flexDirection="column">
          <Heading
            fontSize={24}
            fontWeight="extrabold"
            borderBottom="1px solid"
            borderColor="gray.200"
            px={6}
            py={3}
          >
            Trending
          </Heading>

          <Box px={6} py={3}>
            <TrendingFilters
              trendChangeType={trendChangeType}
              setTrendChangeType={setTrendChangeType}
              trendPeriod={trendPeriod}
              setTrendPeriodType={setTrendPeriodType}
              selectedProviderIds={selectedProviderIds}
              setSelectedProviderIds={setSelectedProviderIds}
            />
          </Box>
          <TrendingTable
            isLoading={isLoading}
            trendingPatients={trendingPatients}
            trendPeriod={trendPeriod}
          />
        </Box>

        {uid && (
          <Box boxShadow="2xl">
            <PatientDetailsPanelColumn
              context="Trends"
              patientUid={uid}
              onClose={handleClose}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}
