import { extendTheme, UseToastOptions } from '@chakra-ui/react';

export const toastDefaultOptions: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
  variant: 'subtle',
};

const theme = extendTheme({
  colors: {
    brand: {
      purple: '#5A38B2',
      purpleDisabled: '#5A38B27A',
      purpleActive: '#341F66',
      darkPurple: '#472B8C',
      sky: '#C9E5FC',
      lightSky: '#EDF7FF',
      red: '#FF4E1E',
      redDisabled: '#FF4E1E7A',
      redActive: '#A63313',
      redHover: '#CC3E18',
      peach: '#FFDFD6',
      lightPeach: '#FFF5F5',
    },
  },
});

export default theme;
