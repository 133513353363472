import { useMemo } from 'react';
import { Box, Skeleton, Text } from '@chakra-ui/react';
import { BroadcastStatusBadge } from '../../../components';
import { useFetchAdmin } from '../../../hooks';
import { IBroadcast } from '../../../types';
import { BROADCAST_LIST_TEMPLATE_COLUMNS } from '.';
import { formatDate } from '../../../utils';

interface IProps {
  broadcast: IBroadcast;
  onSelectBroadcast: (broadcastId: string) => void;
}

export default function BroadcastsListRow({
  broadcast,
  onSelectBroadcast,
}: IProps): JSX.Element {
  const { admin, isLoading } = useFetchAdmin(broadcast.createdBy);

  const createdAt = useMemo(() => {
    const { createdAt } = broadcast;
    const date = formatDate(createdAt.toDate());
    return `on ${date}`;
  }, [broadcast]);

  return (
    <Box
      onClick={() => onSelectBroadcast(broadcast.id)}
      cursor="pointer"
      _hover={{
        bg: 'blue.50',
      }}
      alignItems="center"
      borderBottom="1px solid"
      borderColor="gray.200"
      display="grid"
      gap={4}
      gridTemplateColumns={BROADCAST_LIST_TEMPLATE_COLUMNS}
      key={broadcast.id}
      px={6}
      py={2}
    >
      <Box fontSize="sm" overflow="hidden">
        <Text fontWeight="semibold">{broadcast.threadSubject}</Text>
        <Text
          color="gray.600"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {broadcast.messageBody}
        </Text>
      </Box>

      <Text fontWeight="medium" textAlign="right">
        {broadcast.patientUids.length}
      </Text>

      <Box fontSize="sm" textAlign="right">
        <Skeleton isLoaded={!isLoading}>
          <Text>
            {admin?.firstName} {admin?.lastName}
          </Text>
        </Skeleton>
        <Text color="gray.600">{createdAt}</Text>
      </Box>

      <Box justifySelf="flex-end">
        <BroadcastStatusBadge status={broadcast.status} />
      </Box>
    </Box>
  );
}
