import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ReadingType } from '.';
import { getColumnsByType } from './utils';

interface Props {
  readingType: ReadingType;
}

export default function TableHeader({ readingType }: Props): JSX.Element {
  const columns = useMemo(() => getColumnsByType(readingType), [readingType]);

  return (
    <Box
      bg="gray.50"
      borderBottom="1px solid"
      borderColor="blackAlpha.200"
      color="blackAlpha.600"
      display="grid"
      fontSize="xs"
      fontWeight="bold"
      gridTemplateColumns={`${columns.map(() => '48px').join(' ')} auto`}
      px={4}
      py={2}
    >
      {columns.map((column) => (
        <Text textTransform="uppercase" key={column}>
          {column}
        </Text>
      ))}
      <Text textAlign="right">Date, Time</Text>
    </Box>
  );
}
