import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Image, Text, useToast } from '@chakra-ui/react';
import {
  deleteUser,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { Events, mixpanel } from '../../analytics';
import { auth } from '../../firebase';
import { MARLEY_DOMAIN, verifyToken } from '../../utils';
import logo from '../../assets/marley-logo.svg';

export default function LoginPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const { currentUser } = auth;

    if (currentUser && !isLoading) {
      navigate('/patients');
    }
  }, [navigate, isLoading]);

  async function handleClick() {
    setIsLoading(true);

    const googleProvider = new GoogleAuthProvider();

    googleProvider.setCustomParameters({
      hd: MARLEY_DOMAIN,
    });

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const isVerifiedUser = await verifyToken(credential?.accessToken);

      if (isVerifiedUser) {
        mixpanel.track(Events.USER_LOGGED_IN);

        navigate('/patients');
      } else {
        await deleteUser(auth.currentUser!);
        await signOut(auth);
      }
    } catch (error) {
      toast({
        description: 'An error occurred while logging in. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#F4FAFE"
    >
      <Box textAlign="center">
        <Image src={logo} display="inline-block" mb={10} />
        <Heading as="h2" mb={2}>
          Fetch
        </Heading>

        <Text color="gray.500" mb={10}>
          Sign in with your corporate account to view patient data.
        </Text>

        <Button onClick={handleClick} isLoading={isLoading}>
          Sign In With Google
        </Button>
      </Box>
    </Box>
  );
}
