import { Box } from '@chakra-ui/react';
import DataFigure from './DataFigure';
import DataFigureLabel from './DataFigureLabel';
import { TSnippetName } from '../../analytics';

interface IProps {
  isLoading?: boolean;
  label: string;
  // if child is an element instead of a string, must provide overridingFigureMessageToCopy if copyFigure is enabled
  children: React.ReactNode;
  copyEventName?: TSnippetName;
  subtext?: string;
  overridingFigureMessageToCopy?: string;
}

export default function DataFigureGridItem({
  isLoading = false,
  label,
  children,
  copyEventName,
  subtext,
  overridingFigureMessageToCopy,
}: IProps): JSX.Element {
  const figureText = overridingFigureMessageToCopy
    ? overridingFigureMessageToCopy
    : typeof children === 'string'
    ? children
    : '';

  return (
    <Box
      border="1px solid"
      borderColor="blackAlpha.200"
      rounded="md"
      py={3}
      px={4}
      boxShadow="sm"
    >
      <DataFigureLabel
        copyEventName={copyEventName}
        label={label}
        figureText={figureText}
      />
      <DataFigure isLoading={isLoading} subtext={subtext}>
        {children}
      </DataFigure>
    </Box>
  );
}
