import { Box, Grid, Text } from '@chakra-ui/react';
import { IPatientTrend, PatientTrendTimePeriod } from '../../types';
import { TableLoader } from '../';
import TrendingTableRow from './TrendingTableRow';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isLoading: boolean;
  trendingPatients: IPatientTrend[];
  trendPeriod: PatientTrendTimePeriod;
}

export const TRENDING_TABLE_COLUMN_LAYOUT =
  '2.25fr 2fr 2fr 1.5fr 1.5fr 2fr 1.75fr';

export default function TrendingTable({
  isLoading,
  trendingPatients,
  trendPeriod,
}: IProps) {
  const navigate = useNavigate();

  function handleClick(uid: string) {
    navigate(`/trending/${uid}`);
  }

  return (
    <Box>
      <Grid
        textTransform="uppercase"
        color="blackAlpha.700"
        fontWeight="bold"
        fontSize="sm"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        templateColumns={TRENDING_TABLE_COLUMN_LAYOUT}
        justifyItems="flex-end"
        textAlign="end"
        py={4}
        px={6}
      >
        <Text fontSize="xs" justifySelf="flex-start">
          Patient
        </Text>
        <Text fontSize="xs">Avg. Now</Text>
        <Text fontSize="xs">
          Avg.{' '}
          {trendPeriod === PatientTrendTimePeriod.WEEKLY
            ? '7 Days'
            : 'One Month'}{' '}
          Ago
        </Text>
        <Text fontSize="xs">SBP Δ</Text>
        <Text fontSize="xs">DBP Δ</Text>
        <Text fontSize="xs">Goal</Text>
        <Text fontSize="xs">Provider</Text>
      </Grid>

      {isLoading && <TableLoader />}

      {!isLoading && (
        <Box h="calc(100vh - 162px)" overflow="scroll">
          {trendingPatients.length ? (
            trendingPatients.map((trendingPatient) => (
              <Box
                onClick={() => handleClick(trendingPatient.patientUid)}
                key={trendingPatient.patientUid}
                cursor="pointer"
                _hover={{
                  bg: 'blue.50',
                }}
                overflow="scroll"
              >
                <TrendingTableRow trendingPatient={trendingPatient} />
              </Box>
            ))
          ) : (
            <Box
              h="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="lg" fontWeight="medium">
                No matching patients.
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
