import { DeactivatedReason } from '../../../../../types';
import { FormControl, FormLabel, Radio, RadioGroup } from '@chakra-ui/react';

interface IProps {
  onChange: (value: DeactivatedReason) => void;
  value?: DeactivatedReason;
}

const radioOptions: Record<DeactivatedReason, string> = {
  [DeactivatedReason.GLP1_NOT_COVERED]: 'GLP1: Not covered by insurance',
  [DeactivatedReason.GLP1_COST]: 'GLP1: Too expensive',
  [DeactivatedReason.GLP1_MEDICATION_SHORTAGE]:
    'GLP1: Could not obtain med due to shortage',
  [DeactivatedReason.PATIENT_ACHIEVED_GOAL]:
    'Patient achieved their health goal',
  [DeactivatedReason.PATIENT_MOVED_STATES]: 'Patient moved out of our states',
  [DeactivatedReason.PATIENT_NOT_SATISFIED]:
    "Patient doesn't think we're helping them enough",
  [DeactivatedReason.PATIENT_MOVED_PROVIDERS]:
    'Patient went to another provider',
  [DeactivatedReason.PATIENT_INSURANCE_ISSUES]:
    'Patient lost or changed insurance coverage',
  [DeactivatedReason.COST]: 'Patient said cost was too high',
  [DeactivatedReason.MARLEY_INITIATED]: 'Marley initiated',
};

export default function SelectDeactivationReason({
  onChange,
  value,
}: IProps): JSX.Element {
  return (
    <FormControl mt={4}>
      <FormLabel>Deactivation Reason</FormLabel>
      <RadioGroup
        onChange={onChange}
        value={value}
        display="flex"
        flexDir="column"
        gap={1}
      >
        {Object.entries(radioOptions).map(([key, value]) => (
          <Radio key={key} value={key}>
            {value}
          </Radio>
        ))}
      </RadioGroup>
    </FormControl>
  );
}
