import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { ClinicalReviewStates } from '../types';

export const clinicalReviewOverrideBlocked = httpsCallable<
  {
    patientUid: string;
    state: ClinicalReviewStates;
  },
  {}
>(functions, 'clinicalReviewOverrideBlocked');

export const clinicalReviewSelectForReview = httpsCallable<
  {
    patientUid: string;
    state: ClinicalReviewStates;
  },
  {}
>(functions, 'clinicalReviewSelectForReview');

export const clinicalReviewAcknowledgeMed = httpsCallable<
  { patientUid: string },
  {}
>(functions, 'clinicalReviewAcknowledgeMed');

export const clinicalReviewSnooze = httpsCallable<{ patientUid: string }, {}>(
  functions,
  'clinicalReviewSnooze',
);
