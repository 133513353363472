import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Input, useDisclosure } from '@chakra-ui/react';
import AttachLinkModal from './AttachLinkModal';
import Attachment from './Attachment';
import { Link, PaperClip } from '../../assets';
import { IBroadcastAttachment } from '../../types';
import { getInitialAttachmentsState, uploadFile } from './utils';

interface IProps {
  attachments?: IBroadcastAttachment[];
  isEditable?: boolean;
  onChange: (value: IBroadcastAttachment[]) => void;
}

export default function BroadcastAttachmentsManager({
  attachments,
  isEditable,
  onChange,
}: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [links, setLinks] = useState<IBroadcastAttachment[]>([]);
  const [fileAttachment, setFileAttachment] = useState<IBroadcastAttachment>();

  useEffect(() => {
    if (attachments) {
      const { fileAttachment, links } = getInitialAttachmentsState(attachments);

      setFileAttachment(fileAttachment);
      setLinks(links);
    }
  }, [attachments]);

  async function handleAttachFile({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) {
    const file = currentTarget.files?.item(0);

    if (file) {
      const filePath = await uploadFile(file);
      const updatedFileAttachment: IBroadcastAttachment = {
        description: file.name,
        filePath,
        type: 'file',
      };
      const updatedAttachments = [...links, updatedFileAttachment];

      setFileAttachment(updatedFileAttachment);
      onChange(updatedAttachments);
    }
  }

  async function handleRemoveFile() {
    setFileAttachment(undefined);
    onChange(links);
  }

  function handleOnAddLink(link: IBroadcastAttachment) {
    const updatedLinks = [...links, link];

    setLinks(updatedLinks);
    handleOnLinksChange(updatedLinks);
  }

  function handleOnRemoveLink(index: number) {
    const updatedLinks = links.filter((_, i) => i !== index);

    setLinks(updatedLinks);
    handleOnLinksChange(updatedLinks);
  }

  function handleOnLinksChange(updatedLinks: IBroadcastAttachment[]) {
    const attachments = [...updatedLinks];

    if (fileAttachment) {
      attachments.push(fileAttachment);
    }

    onChange(attachments);
  }

  return (
    <>
      <AttachLinkModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOnAddLink}
      />

      {fileAttachment && (
        <Attachment
          attachment={fileAttachment}
          isEditable={isEditable}
          onRemove={handleRemoveFile}
        />
      )}

      {links.map((link, index) => (
        <Attachment
          attachment={link}
          isEditable={isEditable}
          onRemove={() => handleOnRemoveLink(index)}
          key={index}
        />
      ))}

      {isEditable && (
        <Box alignItems="center" display="flex" gap={1}>
          {!fileAttachment && (
            <label htmlFor="fileUpload">
              <Box
                alignItems="center"
                display="flex"
                cursor="pointer"
                h={8}
                justifyContent="center"
                px={3}
                rounded="md"
                _hover={{
                  bg: 'gray.100',
                }}
              >
                <PaperClip />
              </Box>
              <Input
                id="fileUpload"
                type="file"
                display="none"
                onChange={handleAttachFile}
              />
            </label>
          )}

          <Button variant="ghost" size="sm" onClick={onOpen}>
            <Link />
          </Button>
        </Box>
      )}
    </>
  );
}
