import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import ManualPatientSelection from './ManualPatientSelection';
import PatientEmailsTextArea from './PatientEmailsTextArea';

enum SelectionType {
  MANUAL = 'manual',
  CSV = 'csv',
}
interface IProps {
  setPatientUids: (patientUids: string[]) => void;
}

export default function PatientSelection({
  setPatientUids,
}: IProps): JSX.Element {
  const [selectionType, setSelectionType] = useState<SelectionType>(
    SelectionType.MANUAL,
  );

  return (
    <>
      <FormControl mb={6}>
        <FormLabel>Group Type</FormLabel>
        <RadioGroup
          onChange={(value: SelectionType) => setSelectionType(value)}
          value={selectionType}
        >
          <Stack direction="row">
            <Radio value={SelectionType.MANUAL}>Manual selection</Radio>
            <Radio value={SelectionType.CSV}>CSV</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      {selectionType === SelectionType.MANUAL && (
        <ManualPatientSelection setPatientUids={setPatientUids} />
      )}

      {selectionType === SelectionType.CSV && (
        <PatientEmailsTextArea setPatientUids={setPatientUids} />
      )}
    </>
  );
}
