import { Icon } from '@chakra-ui/react';

export default function Users(props: any): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.125 7C12.3218 7 11.5453 6.64141 10.9375 5.99063C10.3464 5.35586 9.9855 4.50938 9.92183 3.60781C9.85386 2.64609 10.1472 1.76172 10.7476 1.11719C11.348 0.472656 12.1875 0.125 13.125 0.125C14.0558 0.125 14.8976 0.478906 15.496 1.12187C16.1003 1.77109 16.3945 2.65391 16.3265 3.60742C16.2613 4.51016 15.9007 5.35625 15.3109 5.99023C14.7046 6.64141 13.9285 7 13.125 7Z"
          fill="currentColor"
        />
        <path
          d="M18.2746 13.875H7.97574C7.81015 13.8759 7.64658 13.8388 7.49759 13.7665C7.34861 13.6943 7.21818 13.5888 7.11636 13.4582C7.00836 13.3166 6.93377 13.1525 6.89819 12.978C6.8626 12.8036 6.86695 12.6233 6.91089 12.4508C7.2398 11.1301 8.05464 10.0348 9.26714 9.28359C10.3433 8.61719 11.7132 8.25 13.125 8.25C14.5644 8.25 15.8984 8.60156 16.9808 9.26758C18.1961 10.0148 19.0121 11.1164 19.3394 12.4531C19.3828 12.6258 19.3867 12.806 19.3507 12.9804C19.3147 13.1548 19.2398 13.3188 19.1316 13.4602C19.0299 13.5901 18.8998 13.6951 18.7512 13.767C18.6026 13.8389 18.4396 13.8758 18.2746 13.875Z"
          fill="currentColor"
        />
        <path
          d="M5.74214 7.15625C4.36753 7.15625 3.15894 5.87813 3.04683 4.30742C2.99136 3.50273 3.24214 2.75859 3.74996 2.21289C4.2523 1.67266 4.96089 1.375 5.74214 1.375C6.52339 1.375 7.22652 1.67422 7.7316 2.21758C8.24331 2.76758 8.49331 3.51016 8.43472 4.3082C8.32261 5.87852 7.11441 7.15625 5.74214 7.15625Z"
          fill="currentColor"
        />
        <path
          d="M8.30699 8.38477C7.61988 8.04883 6.72808 7.88086 5.74253 7.88086C4.59175 7.88086 3.47417 8.18086 2.59527 8.72539C1.59878 9.34375 0.928471 10.2441 0.657768 11.3313C0.618153 11.4876 0.614406 11.6509 0.646808 11.8089C0.679209 11.9669 0.746921 12.1156 0.844877 12.2437C0.937826 12.3631 1.0569 12.4595 1.19295 12.5256C1.329 12.5917 1.47839 12.6257 1.62964 12.625H5.96558C6.03876 12.625 6.10962 12.5993 6.1658 12.5524C6.22198 12.5055 6.25992 12.4404 6.273 12.3684C6.2773 12.3438 6.28277 12.3191 6.28902 12.2949C6.62027 10.9645 7.39644 9.84023 8.54331 9.0207C8.5855 8.9903 8.61943 8.94985 8.64202 8.90301C8.66462 8.85618 8.67518 8.80445 8.67274 8.75251C8.67029 8.70057 8.65493 8.65006 8.62803 8.60556C8.60113 8.56106 8.56356 8.52397 8.51871 8.49766C8.45738 8.46172 8.38706 8.42383 8.30699 8.38477Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
