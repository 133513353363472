import { CheckboxGroup, Checkbox, Text, Box } from '@chakra-ui/react';
import { PatientPrograms } from '../PatientDetailsPanelColumn/ProgramEnrollmentPrompt';

interface IProps {
  onChange: (selectedPrograms: PatientPrograms[]) => void;
  value: PatientPrograms[];
}

export default function EditProgramEnrollmentInput({
  onChange,
  value,
}: IProps): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <CheckboxGroup defaultValue={value} value={value} onChange={onChange}>
        <Checkbox
          border="1px solid"
          borderColor=" blackAlpha.300"
          borderRadius="md"
          pl={2}
          pr={3}
          py={1.5}
          bg="white"
          value={PatientPrograms.HYPERTENSION}
        >
          <Text fontSize="sm" color="blackAlpha.800">
            {PatientPrograms.HYPERTENSION}
          </Text>
        </Checkbox>
        <Checkbox
          border="1px solid"
          borderColor=" blackAlpha.300"
          borderRadius="md"
          pl={2}
          pr={3}
          py={1.5}
          bg="white"
          value={PatientPrograms.WEIGHT}
        >
          <Text fontSize="sm" color="blackAlpha.800">
            {PatientPrograms.WEIGHT}
          </Text>
        </Checkbox>
        <Checkbox
          border="1px solid"
          borderColor=" blackAlpha.300"
          borderRadius="md"
          pl={2}
          pr={3}
          py={1.5}
          bg="white"
          value={PatientPrograms.DIABETES}
        >
          <Text fontSize="sm" color="blackAlpha.800">
            {PatientPrograms.DIABETES}
          </Text>
        </Checkbox>
      </CheckboxGroup>
    </Box>
  );
}
