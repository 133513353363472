import { useEffect, useMemo } from 'react';
import { IPanelSection } from '..';

export default function useObservePanelSections({
  sections,
  setIntersectingSection,
}: {
  sections: IPanelSection[];
  setIntersectingSection: React.Dispatch<
    React.SetStateAction<IPanelSection | null>
  >;
}) {
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const section = sections.find(
                (s) => s.ref === (entry.target as HTMLDivElement),
              );
              if (section) {
                setIntersectingSection(section);
              }
            }
          });
        },
        {
          // recognize intersection when element reaches near top of panel
          rootMargin: '-25% 0% -75% 0%',
          threshold: 0,
        },
      ),
    [setIntersectingSection, sections],
  );

  useEffect(() => {
    sections.forEach(({ ref }) => {
      if (ref) {
        observer.observe(ref);
      }
    });
  }, [observer, sections]);
}
