import { Select } from '@chakra-ui/react';
import { useFilters } from './context';
import { reviewStateIsSegmented } from '../utils';
import { formatReviewStateName } from '../../../utils';
import { ClinicalReviewStates } from '../../../types';

export default function ClinicalReviewStateSelect(): JSX.Element {
  const { setFieldValues, values } = useFilters();

  function handleOnReviewStateChange(reviewState: ClinicalReviewStates) {
    if (reviewStateIsSegmented(reviewState)) {
      setFieldValues({
        reviewState,
      });
    } else {
      setFieldValues({
        reviewState,
        segmentIds: [],
      });
    }
  }

  return (
    <Select
      value={values.reviewState}
      size="sm"
      rounded="md"
      onChange={({ currentTarget }) =>
        handleOnReviewStateChange(currentTarget.value as ClinicalReviewStates)
      }
    >
      <option value={ClinicalReviewStates.NOT_APPLICABLE}>
        {formatReviewStateName(ClinicalReviewStates.NOT_APPLICABLE)}
      </option>

      <option value={ClinicalReviewStates.REVIEWABLE}>
        {formatReviewStateName(ClinicalReviewStates.REVIEWABLE)}
      </option>

      <option value={ClinicalReviewStates.BLOCKED}>
        {formatReviewStateName(ClinicalReviewStates.BLOCKED)}
      </option>

      <option value={ClinicalReviewStates.CHASE_LIST}>
        {formatReviewStateName(ClinicalReviewStates.CHASE_LIST)}
      </option>

      <option value={ClinicalReviewStates.CHART_PREP}>
        {formatReviewStateName(ClinicalReviewStates.CHART_PREP)}
      </option>

      <option value={ClinicalReviewStates.REVIEWING}>
        {formatReviewStateName(ClinicalReviewStates.REVIEWING)}
      </option>
    </Select>
  );
}
