import { Timestamp } from 'firebase/firestore';
import { Box, Text } from '@chakra-ui/react';
import { dayjs, getBPSnapshotFromReadings } from '../../../utils';
import { DataFigureGridItem } from '../../../components';
import { useFetchPatientBPReadings } from '../../../hooks';

interface IProps {
  patientUid?: string;
}

function formatReadingDate(timestamp?: Timestamp): string {
  if (!timestamp) {
    return 'N/A';
  }

  return dayjs(timestamp.toDate()).format('MM-DD-YYYY');
}

const twoWeeksAgo = dayjs().subtract(14, 'day').startOf('day');
const twoWeeksAgoDate = twoWeeksAgo.toDate();

export default function BPSnapshot({ patientUid }: IProps): JSX.Element {
  const [bpReadings, isLoading] = useFetchPatientBPReadings(
    patientUid,
    twoWeeksAgoDate,
  );
  const { averages, latestReading } = getBPSnapshotFromReadings(bpReadings);

  return (
    <Box p={4}>
      <Text mb={2} fontWeight="semibold">
        BP Snapshot
      </Text>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr minmax(max-content, 1fr)"
        gap={4}
      >
        <DataFigureGridItem
          isLoading={isLoading}
          label="14 Day Avg."
          subtext={bpReadings?.length ? `${bpReadings?.length} readings` : ''}
        >
          {`${averages?.avgSystolic || 'N/A'}/${
            averages?.avgDiastolic || 'N/A'
          }`}
        </DataFigureGridItem>

        <DataFigureGridItem
          isLoading={isLoading}
          label="Last Reading"
          subtext={formatReadingDate(latestReading?.readingTime)}
        >
          {`${latestReading?.systolic || 'N/A'}/${
            latestReading?.diastolic || 'N/A'
          }`}
        </DataFigureGridItem>

        <DataFigureGridItem
          isLoading={isLoading}
          label="Readings Over 14 Days"
          subtext={`Since ${twoWeeksAgo.format('MM-DD-YYYY')}`}
        >
          {`${bpReadings?.length}`}
        </DataFigureGridItem>
      </Box>
    </Box>
  );
}
