import { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import SelectDeactivationReason from './SelectDeactivationReason';
import { DeactivatedReason } from '../../../../../types';

interface IProps {
  isActive?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (
    newPatientStatus: boolean,
    deactivationReason?: DeactivatedReason,
  ) => Promise<void>;
}

export default function ConfirmationDialog({
  isActive,
  isOpen,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deactivationReason, setDeactivationReason] = useState<
    DeactivatedReason | undefined
  >();
  const cancelRef = useRef(null);
  const isSubmissionDisabled = !deactivationReason && isActive;

  async function handleClickConfirmation() {
    setIsLoading(true);
    const newPatientStatus = !isActive;

    try {
      await onConfirm(newPatientStatus, deactivationReason);
      setIsLoading(false);
      handleOnClose();
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleOnClose() {
    setDeactivationReason(undefined);
    onClose();
  }

  return (
    <AlertDialog
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleOnClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {isActive ? 'Deactivate' : 'Reactivate'} patient
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              Are you sure you want to {`${isActive ? 'de' : 're'}`}activate
              this patient? This action will {`${isActive ? 'de' : 're'}`}
              activate this patient's records across all platforms.
            </Text>

            {isActive && (
              <SelectDeactivationReason
                onChange={setDeactivationReason}
                value={deactivationReason}
              />
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={handleOnClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleClickConfirmation}
              ml={3}
              isDisabled={isSubmissionDisabled}
              isLoading={isLoading}
            >
              {isActive ? 'Deactivate' : 'Reactivate'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
