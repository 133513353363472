import { Box, Select, Text } from '@chakra-ui/react';
import { FilterPriority, useFilters } from '../context';

export default function PrioritySelect(): JSX.Element {
  const { setFieldValues, values } = useFilters();

  function handleOnChange({
    currentTarget,
  }: React.ChangeEvent<HTMLSelectElement>) {
    const priority = currentTarget.value as FilterPriority;
    setFieldValues({ priority });
  }

  return (
    <Box alignItems="center" display="flex" px={3} py={1.5} gap={4}>
      <Text fontSize="sm" color="gray.500">
        Priority
      </Text>
      <Select
        size="sm"
        rounded="md"
        onChange={handleOnChange}
        value={values.priority}
      >
        <option value={FilterPriority.DAYS_IN_STAGE}>Days in stage</option>
        <option value={FilterPriority.DISTANCE_FROM_DIASTOLIC_GOAL}>
          Distance from diastolic goal
        </option>
        <option value={FilterPriority.DISTANCE_FROM_SYSTOLIC_GOAL}>
          Distance from systolic goal
        </option>
        <option value={FilterPriority.LAST_PHONE_OUTREACH}>
          Last phone outreach
        </option>
      </Select>
    </Box>
  );
}
