import { Box, Spinner, Text } from '@chakra-ui/react';

export default function Loader(): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      fontSize="xs"
      color="gray.500"
      fontWeight="semibold"
    >
      <Spinner size="xl" speed="1s" color="brand.purple" mb={4} />
      <Text>Broadcast in progress.</Text>
      <Text>Please hang tight and do not close this window.</Text>
    </Box>
  );
}
