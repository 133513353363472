import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import NavigationItem from './NavigationItem';
import { useGetAdmin } from '../../contexts';
import { Events, mixpanel } from '../../analytics';
import { Logout, MarleyLogo } from '../../assets';
import { Features } from '../../types';
import { auth } from '../../firebase';

export default function Navigation(): JSX.Element {
  const navigate = useNavigate();
  const { features, firstName, isBetaTester, lastName, role } = useGetAdmin();
  const hasSpotlight = features?.includes(Features.SPOTLIGHT);
  const toast = useToast();

  async function handleSignout() {
    try {
      await signOut(auth);

      mixpanel.track(Events.USER_LOGGED_OUT);
      mixpanel.reset();

      navigate('/');
    } catch (error) {
      toast({
        description: 'An error occurred while logging out. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <Box
      bg="gray.800"
      display="flex"
      flexDirection="column"
      h="100vh"
      w="80px"
      pt={3}
      pb={4}
    >
      <Box>
        <MarleyLogo
          display="block"
          color="white"
          w={8}
          h={8}
          mx="auto"
          mb={3}
        />

        <Box margin="auto" w={4} h="1px" bg="gray.600" rounded="lg" />

        <Box px={3} py={4} display="flex" flexDirection="column" gap={2}>
          {hasSpotlight && <NavigationItem path="up-next" />}
          <NavigationItem path="today" />
          {isBetaTester && <NavigationItem path="broadcasts" />}
          <NavigationItem path="patients" />
          <NavigationItem path="trending" />
        </Box>
      </Box>

      <Menu>
        <MenuButton as={Box} mt="auto" mx="auto" cursor="pointer">
          <Avatar size="sm" />
        </MenuButton>

        <MenuList>
          <Box alignItems="center" display="flex" gap={3} px={3} py={1.5}>
            <Avatar size="sm" />
            <Box>
              <Text>
                {firstName} {lastName}
              </Text>
              <Text fontSize="xs" textTransform="capitalize">
                {role}
              </Text>
            </Box>
          </Box>
          <Divider />
          <MenuItem
            display="flex"
            alignItems="center"
            gap={3}
            onClick={handleSignout}
          >
            <Logout fontSize="xl" />
            Signout
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
