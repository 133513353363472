import { IPatient, IReadingBloodPressure } from '../../../types';
import { formatBPGoal } from '../../../utils';

interface IParams {
  avgSystolic?: number;
  avgDiastolic?: number;
  patient: IPatient;
  readings?: IReadingBloodPressure[];
}

export function constructBpNarrativeMessage({
  avgSystolic,
  avgDiastolic,
  patient,
  readings,
}: IParams) {
  const readingsCount = readings?.length ?? 0;

  const twoWeekAverageMessage = `Patient's 14 day average is ${avgSystolic}/${avgDiastolic} from ${readingsCount} reading${
    readingsCount === 1 ? '' : 's'
  }.`;

  const bpGoalText = getBloodPressureText(patient);

  const message =
    twoWeekAverageMessage + bpGoalText + '\nClinical recommendation: \nLabs: ';

  const enabled = Boolean(avgSystolic && avgDiastolic);

  return { enabled, message };
}

function getBloodPressureText(patient: IPatient): string | null {
  const bpGoal = formatBPGoal(patient.bloodPressureGoals);

  if (!bpGoal) {
    return null;
  }

  return ` Patient's goal is ${bpGoal}. `;
}
