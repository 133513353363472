import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { mixpanel } from '../analytics/';

export enum PageTitles {
  SIGN_IN = 'Sign In',
  PATIENTS = 'Patients',
  TASKS = 'Tasks',
  TRENDS = 'Trends',
  UP_NEXT = 'Up Next',
  REPORTS = 'Reports',
}

const PageTitleByPath: Record<string, PageTitles> = {
  '/': PageTitles.SIGN_IN,
  '/patients': PageTitles.PATIENTS,
  '/today': PageTitles.TASKS,
  '/trending': PageTitles.TRENDS,
  '/up-next': PageTitles.UP_NEXT,
  '/overview': PageTitles.REPORTS,
};

export default function useOnLocationChange() {
  const location = useLocation();

  useEffect(() => {
    const page = PageTitleByPath[location.pathname];

    if (page) {
      updateDocumentTitle(page);
      mixpanel.trackPageView(page);
    }
  }, [location]);
}

function updateDocumentTitle(page?: string) {
  const appTitle = 'Marley Admin';

  document.title = `${appTitle} - ${page}`;
}
