import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import TasksList from './TasksList';
import { Layout, PatientDetailsPanelColumn } from '../../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetchPatient } from '../../hooks';
import { ITask } from '../../types';
import SelectedTask from './SelectedTask';

export const HEADER_CONTAINER_HEIGHT = '56px';
export const SHOW_PATIENT_QUERY_PARAM = 'showPatient=true';

export default function TodayPage(): JSX.Element {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { search } = useLocation();
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [selectedTask, setSelectedTask] = useState<ITask | undefined>();
  // whenever an individual task is selected, fetch the patient associated to that task
  const { patient, isLoading: patientLoading } = useFetchPatient(
    selectedTask?.data.patientUid,
  );
  const showPatientDetailsPanel = search.includes(SHOW_PATIENT_QUERY_PARAM);

  // handle task/patient panel selection when user directly navigates here from copied link
  // TODO deep linking to completed tasks will not work because query filters by status.
  // This can be fixed by querying all tasks and filtering clientside, but it'll require some lift to refactor the "update task" logic
  useEffect(() => {
    if (taskId) {
      const task = tasks.find((t) => t.id === taskId);
      if (task) {
        setSelectedTask(task);
      }
    }
  }, [taskId, tasks]);

  function handleOnUpdateTask(task: ITask): void {
    const updatedTasks = tasks.filter((t) => {
      return task.id !== t.id;
    });
    setTasks(updatedTasks);
  }

  return (
    <Layout>
      <Box h="100vh" display="flex">
        <Box flex={1}>
          <TasksList
            tasks={tasks}
            selectedTask={selectedTask}
            setTasks={setTasks}
          />
        </Box>
        <Box bg="gray.100" flex={2} maxHeight="100vh" overflow="auto">
          {selectedTask ? (
            <SelectedTask
              selectedTask={selectedTask}
              onUpdateTask={handleOnUpdateTask}
              showPatientDetailsPanel={showPatientDetailsPanel}
              patient={patient}
              patientLoading={patientLoading}
            />
          ) : null}
        </Box>
        {showPatientDetailsPanel ? (
          <Box w="400px">
            <PatientDetailsPanelColumn
              context="Tasks"
              onClose={() => navigate(`/today/${taskId}`)}
              patientUid={patient?.uid}
            />
          </Box>
        ) : null}
      </Box>
    </Layout>
  );
}
