import { MenuItem, useDisclosure, useToast } from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import ConfirmationDialog from './ConfirmationDialog';
import { functions } from '../../../../../firebase';
import { mixpanel, Events } from '../../../../../analytics';
import { CancelCircle, CheckCircle } from '../../../../../assets';
import { DeactivatedReason, IPatient } from '../../../../../types';

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

export default function ChangePatientStatus({
  onUpdate,
  patient,
}: IProps): JSX.Element {
  const { isActive } = patient;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  async function handleConfirmChangeStatus(
    newPatientStatus: boolean,
    deactivatedReason?: DeactivatedReason,
  ) {
    const updatePatientStatus = httpsCallable(functions, 'updatePatientRecord');

    try {
      await updatePatientStatus({
        data: {
          isActive: newPatientStatus,
          deactivatedReason,
        },
        patientUid: patient?.uid,
      });

      const event = newPatientStatus
        ? Events.PATIENT_REACTIVATED
        : Events.PATIENT_DEACTIVATED;

      mixpanel.track(event);

      toast({
        description: `Patient ${
          newPatientStatus ? 'reactivated' : 'deactivated'
        }.`,
        status: 'success',
        title: 'Success',
      });

      onUpdate();
    } catch (error) {
      toast({
        description: `An error occurred ${
          newPatientStatus ? 'reactivating' : 'deactivating'
        } the patient.`,
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      onClose();
    }
  }

  return (
    <>
      <ConfirmationDialog
        isActive={isActive}
        isOpen={isOpen}
        onConfirm={handleConfirmChangeStatus}
        onClose={onClose}
      />

      <MenuItem onClick={onOpen} display="flex" alignItems="center" gap={2}>
        {isActive ? <CancelCircle /> : <CheckCircle />}
        {isActive ? 'Deactivate' : 'Activate'} patient
      </MenuItem>
    </>
  );
}
