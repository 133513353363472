import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Close, Plus } from '../../../../assets';
import { IReadingWeight } from '../../../../types';
import { WEIGHT_READINGS_LIST_TEMPLATE_COLUMNS } from './';

interface IProps {
  isLastItem: boolean;
  onUpdateOutlierStatus: (readingId: string, outlier: boolean) => Promise<void>;
  reading: IReadingWeight;
}

export default function WeightReadingsListRow({
  isLastItem,
  onUpdateOutlierStatus,
  reading,
}: IProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOutlier, setIsOutlier] = useState<boolean>(false);
  const { readingTime, value } = reading;
  const formattedReadingTime = useMemo(
    () => dayjs(readingTime.toDate()).format('M/D/YYYY, h:mmA'),
    [readingTime],
  );
  const bgColor = isOutlier ? 'green.50' : 'red.50';

  useEffect(() => {
    setIsOutlier(reading.outlier);
  }, [reading]);

  async function handleClick() {
    setIsLoading(true);

    try {
      await onUpdateOutlierStatus(reading.id, !isOutlier);
      setIsOutlier(!isOutlier);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      borderBottom={!isLastItem ? '1px solid' : undefined}
      borderColor="gray.200"
      mx={4}
      py={3}
    >
      <Grid
        alignItems="center"
        bg={isHovered ? bgColor : undefined}
        fontSize="sm"
        templateColumns={WEIGHT_READINGS_LIST_TEMPLATE_COLUMNS}
        justifyItems="flex-start"
      >
        <Text
          as={isOutlier ? 's' : undefined}
          color={isOutlier ? 'blackAlpha.500' : 'blackAlpha.800'}
          fontWeight="medium"
        >
          {Math.round(value)}
        </Text>

        <Text
          as={isOutlier ? 's' : undefined}
          color={isOutlier ? 'blackAlpha.500' : 'blackAlpha.700'}
          justifySelf="flex-end"
        >
          {formattedReadingTime}
        </Text>

        <Button
          isLoading={isLoading}
          justifySelf="flex-end"
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          px={1}
          size="xs"
          variant="ghost"
          _hover={{
            bg: isOutlier ? 'green.100' : 'red.100',
          }}
        >
          {isOutlier ? <Plus w={4} h={4} /> : <Close w={4} h={4} />}
        </Button>
      </Grid>
    </Box>
  );
}
