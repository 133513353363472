import { ClinicalReviewStates } from '../../types';

export default function formatReviewStateName(
  state: ClinicalReviewStates,
): string {
  switch (state) {
    case ClinicalReviewStates.NOT_APPLICABLE:
      return 'Not in HTN Review Cycle';
    case ClinicalReviewStates.REVIEWABLE:
      return 'Awaiting Next Cycle';
    case ClinicalReviewStates.BLOCKED:
      return 'In Automatic Outreach';
    case ClinicalReviewStates.CHASE_LIST:
      return 'Requires Outreach';
    case ClinicalReviewStates.CHART_PREP:
      return 'Chart Prep';
    case ClinicalReviewStates.REVIEWING:
      return 'Ready for Review';
    default:
      return state;
  }
}
