import { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { doc, getDoc } from 'firebase/firestore';
import { formatTimeSinceTaskCreation, formatTaskTitle } from '../utils';
import { PatientName, ProviderAvatar } from '../../../components';
import { IPatient, ITask, TaskStatus } from '../../../types';
import { db } from '../../../firebase';
import { CheckmarkCircleFilled } from '../../../assets';

interface IProps {
  isSelected: boolean;
  task: ITask;
}

export default function TaskListItem({
  isSelected,
  task,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patient, setPatient] = useState<IPatient | undefined>();

  const {
    type,
    data: { patientUid },
    status,
  } = task;

  useEffect(() => {
    async function fetchPatient() {
      const patientRef = doc(db, `patients/${patientUid}`);
      const patientDoc = await getDoc(patientRef);

      setPatient({
        uid: patientDoc.id,
        ...patientDoc.data(),
      } as IPatient);
      setIsLoading(false);
    }

    fetchPatient();
  }, [patientUid]);

  return (
    <Box
      bg={isSelected ? 'blue.50' : ''}
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      position="relative"
      px={6}
      py={4}
    >
      {isSelected && (
        <Box
          h="100%"
          bg="blue.500"
          position="absolute"
          w={1}
          top={0}
          left={0}
        />
      )}
      <PatientName
        fontWeight="semibold"
        isLoading={isLoading}
        patient={patient}
        mb={3}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          minW={0}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          {status === TaskStatus.CLOSED ? (
            <CheckmarkCircleFilled w={6} h={6} color="#68D391" />
          ) : (
            <Box
              rounded="xl"
              border="2px solid"
              borderColor="blackAlpha.500"
              h={6}
              minW={6}
            />
          )}
          <Text
            fontSize="sm"
            fontWeight="medium"
            textTransform="capitalize"
            minW={0}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {formatTaskTitle(type)}
          </Text>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <Text fontSize="xs">
            {formatTimeSinceTaskCreation(task.createdAt)}
          </Text>
          <ProviderAvatar providerId={task.assignee} size="xs" />
        </Box>
      </Box>
    </Box>
  );
}
