import { Icon } from '@chakra-ui/react';

export default function CaretDown(props: any): JSX.Element {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 5.25C10.5766 5.25 10.2905 5.36853 10.0795 5.5795C9.86853 5.79048 9.75 6.07663 9.75 6.375V8.25C9.75 8.66421 9.41421 9 9 9C8.58579 9 8.25 8.66421 8.25 8.25V6.375C8.25 5.67881 8.52656 5.01113 9.01884 4.51884C9.51113 4.02656 10.1788 3.75 10.875 3.75H18.375C19.0712 3.75 19.7389 4.02656 20.2312 4.51884C20.7234 5.01113 21 5.67881 21 6.375V17.625C21 18.3212 20.7234 18.9889 20.2312 19.4812C19.7389 19.9734 19.0712 20.25 18.375 20.25H11.25C10.5458 20.25 9.82359 19.9717 9.27413 19.5412C8.72755 19.1129 8.25 18.4476 8.25 17.625V15.75C8.25 15.3358 8.58579 15 9 15C9.41421 15 9.75 15.3358 9.75 15.75V17.625C9.75 17.8379 9.87972 18.1101 10.1993 18.3604C10.5159 18.6086 10.9188 18.75 11.25 18.75H18.375C18.6734 18.75 18.9595 18.6315 19.1705 18.4205C19.3815 18.2095 19.5 17.9234 19.5 17.625V6.375C19.5 6.07663 19.3815 5.79048 19.1705 5.5795C18.9595 5.36853 18.6734 5.25 18.375 5.25H10.875ZM12.9697 7.71967C13.2626 7.42678 13.7374 7.42678 14.0303 7.71967L17.7803 11.4697C18.0732 11.7626 18.0732 12.2374 17.7803 12.5303L14.0303 16.2803C13.7374 16.5732 13.2626 16.5732 12.9697 16.2803C12.6768 15.9874 12.6768 15.5126 12.9697 15.2197L15.4393 12.75H3.75C3.33579 12.75 3 12.4142 3 12C3 11.5858 3.33579 11.25 3.75 11.25H15.4393L12.9697 8.78033C12.6768 8.48744 12.6768 8.01256 12.9697 7.71967Z"
        fill="currentColor"
      />
    </Icon>
  );
}
