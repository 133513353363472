import { Box } from '@chakra-ui/react';
import { DataFigureGridItem } from '../..';
import { IPatient } from '../../../types';
import RiskBadge from './RiskBadge';

interface IProps {
  avgSystolic?: number;
  avgDiastolic?: number;
  isLoading: boolean;
  patient: IPatient;
  readingsCount?: number;
}

export default function TwoWeekAverage({
  avgSystolic,
  avgDiastolic,
  isLoading,
  patient,
  readingsCount,
}: IProps): JSX.Element {
  const subtext = readingsCount
    ? `${readingsCount} reading${readingsCount === 1 ? '' : 's'}`
    : '';
  const twoWeekBPAvg = `${avgSystolic ?? 'N/A'} / ${avgDiastolic ?? 'N/A'}`;
  const displayRisk = Boolean(avgSystolic && avgDiastolic);

  return (
    <DataFigureGridItem
      copyEventName="Blood Pressure 14-day Average"
      isLoading={isLoading}
      label="14 day avg."
      subtext={subtext}
      overridingFigureMessageToCopy={twoWeekBPAvg}
    >
      <Box display="flex" alignItems="center" gap={2}>
        {twoWeekBPAvg}
        {displayRisk && <RiskBadge patient={patient} />}
      </Box>
    </DataFigureGridItem>
  );
}
