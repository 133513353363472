import { Box, Text } from '@chakra-ui/react';
import { IBPTrendAverageStat } from '../../types';

export default function BPTrendAverageStat({
  avgSystolic,
  avgDiastolic,
  countReadings,
}: IBPTrendAverageStat) {
  const reading = `${Math.round(avgSystolic)}/${Math.round(avgDiastolic)}`;

  return (
    <Box>
      <Text fontSize="sm" fontWeight="medium" letterSpacing={0.2}>
        {reading}
      </Text>
      <Text fontSize="xs" color="black" opacity="48%">
        from {countReadings} readings
      </Text>
    </Box>
  );
}
