import { Box, Tab, TabList, Tabs, TabPanels } from '@chakra-ui/react';
import MessageTab from './MessageTab';
import {
  BP_MESSAGE_COPY,
  BP_THREAD_SUBJECT,
  HEART_RATE_MESSAGE_COPY,
  HEART_RATE_THREAD_SUBJECT,
  HIGH_GLUCOSE_MESSAGE_COPY,
  HIGH_GLUCOSE_THREAD_SUBJECT,
  LOW_GLUCOSE_MESSAGE_COPY,
  LOW_GLUCOSE_THREAD_SUBJECT,
} from './constants';
import { isGlucoseValueHigh } from '../../../../../utils';
import { MessageTemplates } from '../../../../../analytics';
import { ITask, TaskType } from '../../../../../types';

interface IProps {
  isLoading: boolean;
  isSuccess: boolean;
  onSendMessage: (
    message: string,
    threadSubject: string,
    messageTemplate: MessageTemplates,
  ) => Promise<void>;
  task: ITask;
}

function getDefaultTab(task: ITask): number {
  const { type, data } = task;

  if (type === TaskType.OUT_OF_RANGE_READING) {
    return 0;
  }

  if (type === TaskType.OUT_OF_RANGE_GLUCOSE_READING) {
    const { readings } = data;
    const latestReading = readings[0];

    if ('value' in latestReading) {
      return isGlucoseValueHigh(latestReading.value) ? 1 : 0;
    }
  }

  return 0;
}

export default function MessageTabs({
  isLoading,
  isSuccess,
  onSendMessage,
  task,
}: IProps): JSX.Element {
  const { type } = task;
  const defaultTab = getDefaultTab(task);

  return (
    <Box>
      <Tabs
        defaultIndex={defaultTab}
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
      >
        <TabList bg="blackAlpha.50" borderTopRadius="md">
          {type === TaskType.OUT_OF_RANGE_READING && (
            <>
              <Tab>Blood pressure</Tab>
              <Tab>Heart rate</Tab>
            </>
          )}
          {type === TaskType.OUT_OF_RANGE_GLUCOSE_READING && (
            <>
              <Tab>Low Glucose</Tab>
              <Tab>High Glucose</Tab>
            </>
          )}
        </TabList>

        <TabPanels bg="white" borderBottomRadius="md">
          {type === TaskType.OUT_OF_RANGE_READING && (
            <MessageTab
              eventType={MessageTemplates.BLOOD_PRESSURE_OUT_OF_RANGE}
              isLoading={isLoading}
              isSuccess={isSuccess}
              messageBody={BP_MESSAGE_COPY}
              onSendMessage={onSendMessage}
              threadSubject={BP_THREAD_SUBJECT}
            />
          )}

          {type === TaskType.OUT_OF_RANGE_READING && (
            <MessageTab
              eventType={MessageTemplates.HEART_RATE_OUT_OF_RANGE}
              isLoading={isLoading}
              isSuccess={isSuccess}
              messageBody={HEART_RATE_MESSAGE_COPY}
              onSendMessage={onSendMessage}
              threadSubject={HEART_RATE_THREAD_SUBJECT}
            />
          )}

          {type === TaskType.OUT_OF_RANGE_GLUCOSE_READING && (
            <MessageTab
              eventType={MessageTemplates.LOW_GLUCOSE_OUT_OF_RANGE}
              isLoading={isLoading}
              isSuccess={isSuccess}
              messageBody={LOW_GLUCOSE_MESSAGE_COPY}
              onSendMessage={onSendMessage}
              threadSubject={LOW_GLUCOSE_THREAD_SUBJECT}
            />
          )}

          {type === TaskType.OUT_OF_RANGE_GLUCOSE_READING && (
            <MessageTab
              eventType={MessageTemplates.HIGH_GLUCOSE_OUT_OF_RANGE}
              isLoading={isLoading}
              isSuccess={isSuccess}
              messageBody={HIGH_GLUCOSE_MESSAGE_COPY}
              onSendMessage={onSendMessage}
              threadSubject={HIGH_GLUCOSE_THREAD_SUBJECT}
            />
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
