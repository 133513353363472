import { Box, Text } from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';
import {
  getPatientStatus,
  formatEnrolledOn,
  formatWeeksEnrolled,
  StatusColors,
} from '../../utils/patientUtils';
import { CommunicationPreference, IPatient } from '../../types';
import ProviderAvatar from '../ProviderAvatar';
import { BloodPressure, Scale } from '../../assets';
import { TAB_BAR_HEIGHT } from '.';

interface IProps {
  patient: IPatient;
}

const ClinicInformation = forwardRef(
  ({ patient }: IProps, ref: React.ForwardedRef<null>): JSX.Element => {
    const { careTeamLead, communicationPreference } = patient;
    const enrolledOn = useMemo(() => formatEnrolledOn(patient), [patient]);
    const weeksEnrolled = useMemo(
      () => formatWeeksEnrolled(patient),
      [patient],
    );
    const patientStatus = useMemo(() => getPatientStatus(patient), [patient]);
    const noProgramEnrollment =
      !patient.programs ||
      (!patient.programs?.hypertension.enrolled &&
        !patient.programs?.weight.enrolled &&
        !patient.programs?.diabetes.enrolled);
    return (
      <Box pt={6} ref={ref} scrollMargin={TAB_BAR_HEIGHT}>
        <Text fontWeight="medium" mb={4}>
          Clinic Info
        </Text>

        <Box
          display="grid"
          gridTemplateColumns="max-content auto"
          gridColumnGap={10}
          gridRowGap={2}
          border="1px solid"
          borderColor="blackAlpha.200"
          rounded="md"
          p={4}
        >
          <Text color="blackAlpha.700" fontSize="sm">
            Provider
          </Text>
          <Box display="flex" alignItems="center">
            {careTeamLead && (
              <ProviderAvatar
                size="sm"
                providerName={careTeamLead.display}
                providerId={careTeamLead.adminId}
                mr={1}
              />
            )}
            <Text fontSize="sm">{careTeamLead?.display ?? 'N/A'}</Text>
          </Box>
          <Box>
            <Text color="blackAlpha.700" fontSize="sm">
              Status
            </Text>
          </Box>
          <Box w="fit-content" bg={StatusColors[patientStatus]} rounded="md">
            <Text
              color="white"
              p={1}
              fontSize="xs"
              fontWeight="semibold"
              textTransform="capitalize"
            >
              {patientStatus}
            </Text>
          </Box>
          <Text color="blackAlpha.700" fontSize="sm">
            Patient start date
          </Text>
          <Box fontSize="sm">
            <Text>{enrolledOn}</Text>
            {weeksEnrolled !== null && (
              <Text color="blackAlpha.600">Enrolled: {weeksEnrolled}</Text>
            )}
          </Box>
          <Text color="blackAlpha.700" fontSize="sm">
            Enrolled programs
          </Text>
          <Box display="flex" gap={1.5}>
            {noProgramEnrollment && <Text fontSize="sm">N/A</Text>}
            {patient.programs?.hypertension.enrolled && (
              <Box
                display="flex"
                alignItems="center"
                bg="gray.500"
                rounded="md"
                py={1}
                px={2}
              >
                <BloodPressure
                  height="10px"
                  width="10px"
                  color="white"
                  mr={1}
                />
                <Text color="white" fontSize="xs" fontWeight="semibold">
                  Hypertension
                </Text>
              </Box>
            )}
            {patient.programs?.weight.enrolled && (
              <Box
                display="flex"
                alignItems="center"
                bg="gray.500"
                rounded="md"
                py={1}
                px={2}
              >
                <Scale height="10px" width="10px" color="white" mr={1} />
                <Text color="white" fontSize="xs" fontWeight="semibold">
                  Weight
                </Text>
              </Box>
            )}
            {patient.programs?.diabetes.enrolled && (
              <Box
                display="flex"
                alignItems="center"
                bg="gray.500"
                rounded="md"
                py={1}
                px={2}
              >
                <Scale height="10px" width="10px" color="white" mr={1} />
                <Text color="white" fontSize="xs" fontWeight="semibold">
                  Diabetes
                </Text>
              </Box>
            )}
          </Box>

          <Text color="blackAlpha.700" fontSize="sm">
            Notification pref.
          </Text>
          <Text
            fontSize="sm"
            textTransform={
              communicationPreference === CommunicationPreference.SMS
                ? 'uppercase'
                : 'capitalize'
            }
          >
            {communicationPreference}
          </Text>
        </Box>
      </Box>
    );
  },
);

export default ClinicInformation;
