import { Icon, IconProps } from '@chakra-ui/react';

export default function BloodPressure(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 12 12">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6570_7629)">
          <path
            d="M11.1325 0H4.77108C4.29398 0 3.90361 0.3888 3.90361 0.864V2.448C1.75229 2.448 0 4.19328 0 6.336C0 8.47872 1.75229 10.224 3.90361 10.224H4.55711C4.81446 10.9699 5.71663 11.52 6.79518 11.52C7.87373 11.52 8.7759 10.9699 9.03325 10.224H9.25301C9.49301 10.224 9.68675 10.031 9.68675 9.792C9.68675 9.55296 9.49301 9.36 9.25301 9.36H9.03325C8.7759 8.61408 7.87373 8.064 6.79518 8.064C5.71663 8.064 4.81446 8.61408 4.55711 9.36H3.90361C2.2294 9.36 0.86747 8.00352 0.86747 6.336C0.86747 4.66848 2.2294 3.312 3.90361 3.312V4.896C3.90361 5.3712 4.29398 5.76 4.77108 5.76H11.1325C11.6096 5.76 12 5.3712 12 4.896V0.864C12 0.3888 11.6096 0 11.1325 0ZM6.79518 8.928C7.62217 8.928 8.24096 9.38304 8.24096 9.792C8.24096 10.201 7.62217 10.656 6.79518 10.656C5.96819 10.656 5.3494 10.201 5.3494 9.792C5.3494 9.38304 5.96819 8.928 6.79518 8.928ZM9.74747 3.10176L8.21494 4.61376C8.13976 4.68864 8.05012 4.71744 7.94313 4.71744C7.83614 4.71744 7.74651 4.68864 7.67133 4.61376L6.15325 3.10176C5.67325 2.62656 5.67325 1.8576 6.15325 1.3968C6.39325 1.16064 6.70843 1.03968 7.02361 1.03968C7.3388 1.03968 7.65398 1.15776 7.89398 1.3968L7.9547 1.45728L8.01542 1.3968C8.25542 1.16064 8.55614 1.03968 8.87133 1.03968C9.18651 1.03968 9.50169 1.15776 9.74169 1.3968C10.2217 1.872 10.2217 2.64096 9.74169 3.10176H9.74747Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_6570_7629">
            <rect width="12" height="11.52" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
