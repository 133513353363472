import getBPAverageFromReadings from './getBPAverageFromReadings';
import getBPSeverity from './getBPSeverity';
import { BloodPressureSeverity, IReadingBloodPressure } from '../../types';

type ReturnValue = {
  averages: {
    avgSystolic: number;
    avgDiastolic: number;
  } | null;
  latestReading: IReadingBloodPressure | null;
  severity: BloodPressureSeverity | null;
};

export default function getBPSnapshotFromReadings(
  readings?: IReadingBloodPressure[],
): ReturnValue {
  if (!readings || readings.length === 0) {
    return {
      averages: null,
      latestReading: null,
      severity: null,
    };
  }

  const averages = getBPAverageFromReadings(readings);
  const latestReading = getLatestReading(readings);
  const severity = getBPSeverity(averages?.avgSystolic, averages?.avgDiastolic);

  return {
    averages,
    latestReading,
    severity,
  };
}

function getLatestReading(
  readings: IReadingBloodPressure[],
): IReadingBloodPressure | null {
  if (readings.length === 0) {
    return null;
  }

  return readings[0];
}
