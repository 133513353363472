import { Grid, Box, Text, Skeleton } from '@chakra-ui/react';
import { useFetchPatient } from '../../hooks';
import { IPatientTrend } from '../../types';
import PatientName from '../PatientName';
import ProviderAvatar from '../ProviderAvatar';
import BPDeltaStat from './BPDeltaStat';
import BPTrendAverageStat from './BPTrendAverageStat';
import BPGoal from './BPGoal';
import { TRENDING_TABLE_COLUMN_LAYOUT } from './TrendingTable';
import { formatDate } from '../../utils';

export default function TrendingTableRow({
  trendingPatient,
}: {
  trendingPatient: IPatientTrend;
}) {
  const { patient, isLoading: patientLoading } = useFetchPatient(
    trendingPatient.patientUid,
  );
  const dob = formatDate(patient?.dateOfBirth);
  const { adminId: assignedMdId, display: assignedMdName } =
    trendingPatient.careTeamLead ?? {};

  return (
    <Grid
      py={3}
      px={6}
      borderBottom="1px solid"
      borderColor="gray.200"
      alignItems="center"
      justifyItems="flex-end"
      templateColumns={TRENDING_TABLE_COLUMN_LAYOUT}
    >
      <Box display="flex" alignItems="center" justifySelf="flex-start" gap={6}>
        <Box>
          <PatientName patient={patient} isLoading={patientLoading} />
          <Skeleton isLoaded={!patientLoading}>
            <Text fontSize="xs" color="blackAlpha.800">
              {dob}
            </Text>
          </Skeleton>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" textAlign="end">
        <BPTrendAverageStat
          avgSystolic={trendingPatient.trend.last.averageSystolic}
          avgDiastolic={trendingPatient.trend.last.averageDiastolic}
          countReadings={trendingPatient.trend.last.countReadings}
        />
      </Box>

      <Box textAlign="end">
        <BPTrendAverageStat
          avgSystolic={trendingPatient.trend.previous.averageSystolic}
          avgDiastolic={trendingPatient.trend.previous.averageDiastolic}
          countReadings={trendingPatient.trend.previous.countReadings}
        />
      </Box>

      <Box textAlign="end">
        <BPDeltaStat
          delta={trendingPatient.trend.change.systolic}
          deltaPercent={trendingPatient.trend.change.systolicPercent}
        />
      </Box>

      <Box textAlign="end">
        <BPDeltaStat
          delta={trendingPatient.trend.change.diastolic}
          deltaPercent={trendingPatient.trend.change.diastolicPercent}
        />
      </Box>

      <BPGoal
        avgSystolic={trendingPatient.trend.last.averageSystolic}
        avgDiastolic={trendingPatient.trend.last.averageDiastolic}
        patient={patient}
      />

      <Box textAlign="end">
        <ProviderAvatar
          size="sm"
          providerId={assignedMdId}
          providerName={assignedMdName}
        />
      </Box>
    </Grid>
  );
}
