import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ChevronDown } from '../../assets';
import { ProviderIds, ProviderName, PROVIDERS_LIST } from '../../constants';
import ProviderAvatar from '../ProviderAvatar';

type ProviderFilterText = ProviderName | 'One Provider' | 'Multiple Providers';

function getProviderFilterText(
  selectedProviderIds: ProviderIds[],
): ProviderFilterText {
  if (selectedProviderIds.length === PROVIDERS_LIST.length) {
    return ProviderName.ALL_PROVIDERS;
  }
  if (selectedProviderIds.length === 1) {
    const provider = PROVIDERS_LIST.find(
      (p) => p.id === selectedProviderIds[0],
    );
    return provider?.name ?? 'One Provider';
  }
  return 'Multiple Providers';
}

interface IProps {
  selectedProviderIds: ProviderIds[];
  setSelectedProviderIds: (value: ProviderIds[]) => void;
}

export default function ProviderFilterSelector({
  selectedProviderIds,
  setSelectedProviderIds,
}: IProps) {
  const toast = useToast();
  const [providerFilterText, setProviderFilterText] = useState<string>(
    getProviderFilterText(selectedProviderIds),
  );

  useEffect(() => {
    const text = getProviderFilterText(selectedProviderIds);
    setProviderFilterText(text);
  }, [selectedProviderIds]);

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button
          size="sm"
          bg="white"
          fontSize="sm"
          fontWeight="normal"
          variant="outline"
          rightIcon={<ChevronDown w={6} fontSize="xl" />}
        >
          {providerFilterText}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Stack>
            <CheckboxGroup
              defaultValue={selectedProviderIds}
              value={selectedProviderIds}
              onChange={(checkedIds: ProviderIds[]) => {
                // if new select state is false and there's no other true selections, return true
                if (!checkedIds.length) {
                  toast({
                    description:
                      'Please choose another provider before deselecting this one.',
                    status: 'warning',
                    title: 'At least one provider must be selected.',
                  });
                  return;
                }
                setSelectedProviderIds(checkedIds);
              }}
            >
              {PROVIDERS_LIST.map((p) => {
                return (
                  <Checkbox
                    key={p.id}
                    display="flex"
                    value={p.id}
                    px={3}
                    py={2}
                  >
                    <Box display="flex">
                      <ProviderAvatar
                        ml={1}
                        mr={2}
                        size="xs"
                        providerId={p.id}
                        providerName={
                          p.name === ProviderName.PROVIDER_OTHER
                            ? undefined
                            : p.name
                        }
                      />
                      <Text color="blackAlpha.700">{p.name}</Text>
                    </Box>
                  </Checkbox>
                );
              })}
            </CheckboxGroup>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
