import { createContext, useContext, useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { mixpanel } from '../analytics';
import { auth, db } from '../firebase';
import { IAdmin } from '../types';

const AdminContext = createContext<any>(undefined);

interface IAdminProvider {
  children: React.ReactNode;
}

export default function AdminProvider({ children }: IAdminProvider) {
  const [adminUid, setAdminUid] = useState<string | null>(null);
  const [admin, setAdmin] = useState<IAdmin | null>(null);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAdminUid(user.uid);
    } else {
      setAdminUid(null);
      setAdmin(null);
    }
  });

  useEffect(() => {
    if (admin) {
      window.postMessage(
        {
          type: 'FETCH_AUTH_STATE_CHANGED',
          payload: {
            uid: admin.uid,
            name: `${admin.firstName} ${admin.lastName}`,
          },
        },
        window.location.origin,
      );
    } else {
      window.postMessage(
        {
          type: 'FETCH_AUTH_STATE_CHANGED',
          payload: {
            uid: null,
            name: null,
          },
        },
        window.location.origin,
      );
    }
  }, [admin]);

  useEffect(() => {
    if (!adminUid) {
      return;
    }

    const adminRef = doc(db, `admins/${adminUid}`);

    const unsubscribe = onSnapshot(adminRef, (doc) => {
      const data = doc.data();

      setAdmin({
        uid: doc.id,
        ...data,
      } as IAdmin);

      if (data?.analyticsId) {
        mixpanel.identify(data.analyticsId);
        mixpanel.registerOnce({
          portal: 'Practitioner',
        });
      }
    });

    return () => unsubscribe();
  }, [adminUid]);

  return (
    <AdminContext.Provider value={admin || {}}>
      {children}
    </AdminContext.Provider>
  );
}

function useGetAdmin() {
  const context: IAdmin = useContext(AdminContext);

  if (!context) {
    throw new Error('useGetAdmin must be used within AdminProvider');
  }

  return context;
}

export { useGetAdmin };
