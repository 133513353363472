import { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useToast } from '@chakra-ui/react';
import { db } from '../firebase';
import { IPatient } from '../types';

type TReturnValue = {
  isLoading: boolean;
  patient?: IPatient;
  refetchPatient: () => Promise<void>;
};

export default function useFetchPatient(patientUid?: string): TReturnValue {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patient, setPatient] = useState<IPatient>();
  const toast = useToast();

  const fetchPatient = useCallback(async () => {
    if (!patientUid) {
      return;
    }

    setIsLoading(true);

    const patientRef = doc(db, 'patients', patientUid);
    const patientDoc = await getDoc(patientRef);

    if (!patientDoc.exists()) {
      toast({
        description: `Unable to locate patient ${patientUid}. Please try again`,
        status: 'error',
        title: 'Uh Oh!',
      });
    } else {
      setPatient({
        uid: patientDoc.id,
        ...patientDoc.data(),
      } as IPatient);
    }

    setIsLoading(false);
  }, [patientUid, toast]);

  useEffect(() => {
    fetchPatient();

    return () => {
      setPatient(undefined);
    };
  }, [fetchPatient]);

  return {
    patient,
    isLoading,
    refetchPatient: fetchPatient,
  };
}
