import { Box } from '@chakra-ui/react';
import { IPatient } from '../../../types';
import DataFigureGridItem from '../../DataFigureGridItem';
import {
  formatWeightDeltaStat,
  formatWeightDeltaSubtext,
  formatWeightReadingSubtext,
  getBMIData,
} from './utils';

interface IProps {
  patient: IPatient;
  startingWeight: number | null;
  currentWeight: number | null;
  weightDelta: number | null;
  weightDeltaPercent: number | null;
}

export default function WeightReadingsSummary({
  patient,
  startingWeight,
  currentWeight,
  weightDelta,
  weightDeltaPercent,
}: IProps) {
  const bmiData = getBMIData(patient);
  const startingWeightTimestamp = patient.firstReadingWeight?.readingTime;
  const currentWeightTimestamp = patient.lastReadingWeight?.readingTime;

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
      <DataFigureGridItem
        label="First Reading"
        subtext={formatWeightReadingSubtext({
          weight: startingWeight,
          weightDate: startingWeightTimestamp?.toDate(),
        })}
      >
        {startingWeight ? `${startingWeight}lbs` : 'N/A'}
      </DataFigureGridItem>

      <DataFigureGridItem
        label="Last Reading"
        subtext={formatWeightReadingSubtext({
          weight: currentWeight,
          weightDate: currentWeightTimestamp?.toDate(),
        })}
      >
        {currentWeight ? `${currentWeight}lbs` : 'N/A'}
      </DataFigureGridItem>

      <DataFigureGridItem
        label={`Total Wt Change`}
        subtext={formatWeightDeltaSubtext({
          startingWeightDate: startingWeightTimestamp?.toDate(),
          currentWeightDate: currentWeightTimestamp?.toDate(),
        })}
      >
        {weightDelta !== null
          ? `${formatWeightDeltaStat(weightDelta)} lbs`
          : 'N/A'}
      </DataFigureGridItem>

      <DataFigureGridItem
        label="% Wt Change"
        subtext={formatWeightDeltaSubtext({
          startingWeightDate: startingWeightTimestamp?.toDate(),
          currentWeightDate: currentWeightTimestamp?.toDate(),
        })}
      >
        {weightDeltaPercent !== null
          ? `${formatWeightDeltaStat(weightDeltaPercent)}%`
          : 'N/A'}
      </DataFigureGridItem>

      <DataFigureGridItem label="Last BMI" subtext={bmiData?.readingTime}>
        {bmiData?.bmi ? bmiData.bmi.toFixed(1) : 'N/A'}
      </DataFigureGridItem>
    </Box>
  );
}
