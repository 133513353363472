import { forwardRef, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import DataFigureGridItem from '../DataFigureGridItem';
import { useFetchSegments } from '../../hooks';
import { dayjs, formatDate, formatReviewStateName } from '../../utils';
import { ClinicalReviewStates, IPatient } from '../../types';
import { TAB_BAR_HEIGHT } from '.';

interface IProps {
  patient: IPatient;
}

const ClinicalReviews = forwardRef(
  ({ patient }: IProps, ref: React.ForwardedRef<null>) => {
    const [segments, isLoading] = useFetchSegments();
    const { daysSinceLastReview, lastReviewDate } = useMemo(
      () => formatClinicalReviewDates(patient),
      [patient],
    );

    const clinicalReviewState = useMemo(
      () => formatReviewStateName(patient.clinicalReviewState?.state),
      [patient],
    );

    const daysInReviewState = useMemo(() => {
      if (!patient.clinicalReviewState?.date) {
        return;
      }

      const today = dayjs();
      const reviewDate = dayjs(patient.clinicalReviewState.date.toDate());

      return reviewDate.from(today, true);
    }, [patient]);

    const segmentsCopy = useMemo(() => {
      if (!segments) {
        return;
      }

      const { clinicalReviewState, segments: patientSegmentIds } = patient;
      const { state } = clinicalReviewState;

      if (
        state === ClinicalReviewStates.BLOCKED ||
        state === ClinicalReviewStates.CHASE_LIST
      ) {
        return patientSegmentIds
          .map((segmentId) => {
            const segment = segments.find(
              (segment) => segment.id === segmentId,
            );

            return segment?.name;
          })
          .join(', ');
      }
    }, [patient, segments]);

    const subtext = useMemo(() => {
      if (!daysInReviewState) {
        return 'N/A';
      }

      return `${daysInReviewState}${segmentsCopy ? ` | ${segmentsCopy}` : ''}`;
    }, [daysInReviewState, segmentsCopy]);

    const nextReviewDateDetails = useMemo(() => {
      return getNextReviewDateDetails(patient);
    }, [patient]);

    return (
      <Box ref={ref} pt={4} scrollMargin={TAB_BAR_HEIGHT}>
        <Text fontWeight="medium" mb={4}>
          Clinical Reviews
        </Text>

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
          <DataFigureGridItem label="Last Review" subtext={daysSinceLastReview}>
            {lastReviewDate || 'N/A'}
          </DataFigureGridItem>

          <DataFigureGridItem
            isLoading={isLoading}
            label="Clinical Review Stage"
            subtext={subtext}
          >
            <Text textTransform="capitalize">
              {clinicalReviewState || 'N/A'}
            </Text>
          </DataFigureGridItem>

          <DataFigureGridItem
            label="Eligible for Next Review"
            subtext={nextReviewDateDetails?.daysUntilNextReview}
          >
            <Text>{nextReviewDateDetails?.nextReviewDate || 'N/A'}</Text>
          </DataFigureGridItem>
        </Box>
      </Box>
    );
  },
);

export default ClinicalReviews;

interface IClinicalReviewDates {
  daysSinceLastReview?: string;
  lastReviewDate?: string;
}

function formatClinicalReviewDates(patient: IPatient): IClinicalReviewDates {
  const { last } = patient?.clinicalReviewTasks || {};
  const lastReviewDate = formatDate(last?.date?.toDate());
  const daysSinceLastReview = getDaysRelativeToReview(last?.date?.toDate());

  return {
    daysSinceLastReview,
    lastReviewDate,
  };
}

function getDaysRelativeToReview(date?: Date | null): string | undefined {
  if (!date) {
    return;
  }

  const today = dayjs();
  const reviewDate = dayjs(date);

  return reviewDate.from(today);
}

function getNextReviewDateDetails({ clinicalReviewTasks }: IPatient):
  | {
      nextReviewDate: string;
      daysUntilNextReview: string;
    }
  | undefined {
  const { last } = clinicalReviewTasks || {};
  const { date } = last || {};

  if (!date) {
    return;
  }

  const nextReviewDate = dayjs(date.toDate()).add(28, 'days');
  const daysUntilNextReview = dayjs().to(nextReviewDate);

  return {
    nextReviewDate: nextReviewDate.isBefore(dayjs())
      ? 'Now'
      : nextReviewDate.format('MM-DD-YYYY'),
    daysUntilNextReview,
  };
}
