import { formatReviewStateName } from '../../../utils';
import { ClinicalReviewStates, IPatient } from '../../../types';

export function getNextState(
  clinicalReviewState: ClinicalReviewStates,
): ClinicalReviewStates {
  switch (clinicalReviewState) {
    case ClinicalReviewStates.BLOCKED:
      return ClinicalReviewStates.REVIEWABLE;
    case ClinicalReviewStates.CHASE_LIST:
      return ClinicalReviewStates.REVIEWABLE;
    case ClinicalReviewStates.REVIEWABLE:
      return ClinicalReviewStates.CHART_PREP;
    case ClinicalReviewStates.CHART_PREP:
      return ClinicalReviewStates.REVIEWING;
    default:
      return clinicalReviewState;
  }
}

export function isStateChangeEnabled(
  clinicalReviewState: ClinicalReviewStates,
) {
  const allowedStates = [
    ClinicalReviewStates.BLOCKED,
    ClinicalReviewStates.CHASE_LIST,
    ClinicalReviewStates.REVIEWABLE,
    ClinicalReviewStates.CHART_PREP,
  ];

  return allowedStates.includes(clinicalReviewState);
}

export function canStateBeOverridden(
  clinicalReviewState: ClinicalReviewStates,
): boolean {
  const states = [
    ClinicalReviewStates.BLOCKED,
    ClinicalReviewStates.CHASE_LIST,
  ];

  return states.includes(clinicalReviewState);
}

export function toastDescription(
  currentState: ClinicalReviewStates,
  nextState: ClinicalReviewStates,
): string {
  const formattedCurrentState = formatReviewStateName(currentState);
  const formattedNextState = formatReviewStateName(nextState);

  return `Patient's clinical stage moved from ${formattedCurrentState} to ${formattedNextState}.`;
}

export function patientHasMedicationBlocker(patient: IPatient): boolean {
  const { segments } = patient;
  const medsBlockerSegmentId = process.env
    .REACT_APP_CIO_MEDICATIONS_FOLLOW_UP_SEGMENT_ID as string;

  if (!segments) {
    return false;
  }

  return segments.includes(medsBlockerSegmentId);
}
