import { SyntheticEvent, useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { BroadcastAttachmentsManager } from '../../components';
import { useGetAdmin } from '../../contexts';
import { BroadcastStatus, IBroadcast, IBroadcastAttachment } from '../../types';
import { db } from '../../firebase';

interface IProps {
  broadcast: IBroadcast;
  onUpdate: () => Promise<void>;
}

export default function BroadcastDetailsMessage({
  broadcast,
  onUpdate,
}: IProps) {
  const [threadSubject, setThreadSubject] = useState<string>('');
  const [messageBody, setMessageBody] = useState<string>('');
  const [attachments, setAttachments] = useState<IBroadcastAttachment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const admin = useGetAdmin();
  const toast = useToast();

  const { createdBy, status } = broadcast;
  const isEditable =
    status === BroadcastStatus.DRAFT && createdBy === admin.uid;

  useEffect(() => {
    const { attachments, messageBody, threadSubject } = broadcast;

    setThreadSubject(threadSubject);
    setMessageBody(messageBody);
    setAttachments(attachments);
  }, [broadcast]);

  async function handleEditBroadcast(event: SyntheticEvent) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const broadcastRef = doc(db, 'broadcasts', broadcast.id);

      await updateDoc(broadcastRef, {
        attachments,
        messageBody,
        threadSubject,
      });

      await onUpdate();

      toast({
        description: 'Broadcast updated.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description: 'An error occurred editing the broadcast.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      h={`calc(100vh - 100px)`}
      display="flex"
      justifyContent="center"
      bg="gray.50"
    >
      <Box
        as="form"
        borderRadius="md"
        my={3}
        bg="white"
        w="568px"
        boxShadow="lg"
        p={6}
        display="flex"
        flexDirection="column"
        onSubmit={handleEditBroadcast}
      >
        <FormControl isRequired={isEditable}>
          <FormLabel>Subject line</FormLabel>
          <Input
            color="blackAlpha.800"
            fontWeight="semibold"
            fontSize="lg"
            value={threadSubject}
            readOnly={!isEditable}
            mb={6}
            onChange={({ currentTarget }) =>
              setThreadSubject(currentTarget.value)
            }
          />
        </FormControl>

        <FormControl
          display="flex"
          flexDir="column"
          isRequired={isEditable}
          flexGrow={1}
          mb={4}
        >
          <FormLabel>Message</FormLabel>
          <Textarea
            value={messageBody}
            readOnly={!isEditable}
            flexGrow={1}
            onChange={({ currentTarget }) =>
              setMessageBody(currentTarget.value)
            }
          />
        </FormControl>

        <BroadcastAttachmentsManager
          attachments={attachments}
          isEditable={isEditable}
          onChange={setAttachments}
        />

        {isEditable && (
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button
              colorScheme="blue"
              size="sm"
              variant="outline"
              isLoading={isLoading}
              type="submit"
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
