import { Box, Select, Spinner, Text } from '@chakra-ui/react';
import TaskListItem from './TaskListItem';
import { ITask, TaskStatus } from '../../../types';
import { Events, mixpanel } from '../../../analytics';

interface IProps {
  isLoading: boolean;
  onFilterChange: (status: TaskStatus) => void;
  onTaskClick: (task: ITask) => void;
  selectedTask: ITask | undefined;
  tasks: ITask[];
}

export default function TaskListItems({
  isLoading,
  onFilterChange,
  onTaskClick,
  selectedTask,
  tasks,
}: IProps): JSX.Element {
  const displayEmptyState = !isLoading && tasks.length === 0;

  return (
    <Box borderRight="1px solid" borderColor="gray.200">
      <Box px={6} py={4} borderBottom="1px solid" borderColor="gray.200">
        <Select
          defaultValue={TaskStatus.OPEN}
          size="sm"
          rounded="md"
          w="50%"
          onChange={({ currentTarget }) => {
            onFilterChange(currentTarget.value as TaskStatus);

            mixpanel.track(Events.TASKS_FILTERED, {
              filter_type: 'Status',
            });
          }}
        >
          <option value={TaskStatus.OPEN}>To-do</option>
          <option value={TaskStatus.CLOSED}>Completed</option>
        </Select>
      </Box>

      {tasks.map((task) => {
        const isSelected = selectedTask?.id === task.id;

        return (
          <Box key={task.id} onClick={() => onTaskClick(task)} cursor="pointer">
            <TaskListItem isSelected={isSelected} task={task} />
          </Box>
        );
      })}

      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRight="1px solid"
          borderColor="gray.200"
          py={8}
        >
          <Spinner size="lg" />
        </Box>
      )}

      {displayEmptyState && (
        <Box textAlign="center" py={8}>
          <Text fontSize="3xl" mb={4}>
            🎉
          </Text>
          <Text fontWeight="semibold">You’ve reached the end of the list!</Text>
          <Text>Check back later for more.</Text>
        </Box>
      )}
    </Box>
  );
}
