import { useEffect, useState } from 'react';
import { Box, Skeleton, useToast } from '@chakra-ui/react';
import TrendOverview from './TrendOverview';
import TrendItem from './TrendItem';
import { BPTrendAverageStat, BPDeltaStat } from '../../../Trending';
import { IPatientTrend, PatientTrendTimePeriod } from '../../../../types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase';
import TrendHeader from './TrendHeader';

interface IProps {
  patientUid: string;
}

export default function BloodPressureTrends({
  patientUid,
}: IProps): JSX.Element {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patientTrends, setPatientTrends] = useState<
    IPatientTrend[] | undefined
  >();
  const [patientTrend, setPatientTrend] = useState<IPatientTrend | undefined>();
  const [trendPeriod, setTrendPeriod] = useState<PatientTrendTimePeriod>(
    PatientTrendTimePeriod.WEEKLY,
  );

  useEffect(() => {
    async function fetchTrendsData() {
      try {
        if (!patientUid) {
          return;
        }

        setIsLoading(true);

        const q = query(
          collection(db, 'trends'),
          where('patientUid', '==', patientUid),
        );
        const querySnapshot = await getDocs(q);
        const patientTrends: IPatientTrend[] = [];

        querySnapshot.forEach((doc) =>
          patientTrends.push(doc.data() as IPatientTrend),
        );

        if (patientTrends.length) {
          setPatientTrends(patientTrends);
        } else {
          if (window.location.pathname.includes('trending')) {
            toast({
              description: 'Patient has no active BP trends.',
              status: 'info',
              title: 'Note:',
            });
          }
        }
      } catch (error) {
        toast({
          description: 'Error fetching blood pressure trends.',
          status: 'error',
          title: 'Uh oh!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchTrendsData();
  }, [patientUid, toast]);

  useEffect(() => {
    setPatientTrend(patientTrends?.find((t) => t.trend.period === trendPeriod));
  }, [patientTrends, trendPeriod]);

  return (
    <>
      {isLoading ? (
        <Box
          border="1px solid"
          borderColor="blackAlpha.200"
          rounded="md"
          p={4}
          mb={3}
          display="flex"
          flexDir="column"
          gap={4}
        >
          <Skeleton height={6} />
          <Skeleton height={6} />
          <Skeleton height={6} />
        </Box>
      ) : patientTrend ? (
        <Box
          border="1px solid"
          borderColor="blackAlpha.200"
          p={4}
          rounded="md"
          mb={3}
        >
          <TrendHeader
            patientTrends={patientTrends}
            trendPeriod={trendPeriod}
            setTrendPeriod={setTrendPeriod}
          />

          <TrendOverview patientTrend={patientTrend} />

          <Box
            display="grid"
            gridTemplateColumns="160px auto"
            columnGap={12}
            rowGap={8}
          >
            <TrendItem
              title={`Avg. ${
                trendPeriod === PatientTrendTimePeriod.MONTHLY
                  ? '1 Month'
                  : '7 Days'
              } Ago`}
            >
              <BPTrendAverageStat
                avgSystolic={patientTrend.trend.previous.averageSystolic}
                avgDiastolic={patientTrend.trend.previous.averageDiastolic}
                countReadings={patientTrend.trend.previous.countReadings}
              />
            </TrendItem>

            <TrendItem title="Avg. Now">
              <BPTrendAverageStat
                avgSystolic={patientTrend.trend.last.averageSystolic}
                avgDiastolic={patientTrend.trend.last.averageDiastolic}
                countReadings={patientTrend.trend.last.countReadings}
              />
            </TrendItem>

            <TrendItem title="SBP">
              <BPDeltaStat
                delta={patientTrend.trend.change.systolic}
                deltaPercent={patientTrend.trend.change.systolicPercent}
              />
            </TrendItem>

            <TrendItem title="DBP">
              <BPDeltaStat
                delta={patientTrend.trend.change.diastolic}
                deltaPercent={patientTrend.trend.change.diastolicPercent}
              />
            </TrendItem>
          </Box>
        </Box>
      ) : null}
    </>
  );
}
