import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Text } from '@chakra-ui/react';
import Filters, { FilterProvider, useFilters } from './Filters';
import ClinicalReviewStateSelect from './Filters/ClinicalReviewStateSelect';
import PatientDetails from './PatientDetails';
import PatientsList from './PatientsList';
import { useFetchPatients } from './utils';
import { Layout } from '../../components';

function UpNextPage(): JSX.Element {
  const { values } = useFilters();
  const [patients, isLoading, onClinicalStateChange] = useFetchPatients(values);
  const [activePatientUid, setActivePatientUid] = useState<
    string | undefined
  >();
  const { uid } = useParams();

  useEffect(() => {
    if (uid) {
      setActivePatientUid(uid);
    } else {
      setActivePatientUid(undefined);
    }
  }, [uid]);

  return (
    <Layout>
      <Box display="grid" gridTemplateColumns="400px auto">
        <Box>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.200"
            display="grid"
            gridTemplateColumns="1fr max-content"
            justifyContent="space-between"
            px={6}
            py={3}
            h={14}
          >
            <Heading fontSize={24} fontWeight="extrabold">
              Up Next
            </Heading>

            <ClinicalReviewStateSelect />
          </Box>

          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.200"
            display="flex"
            justifyContent="space-between"
            px={6}
            py={3}
          >
            <Filters />

            {!isLoading && (
              <Text alignSelf="flex-end" fontSize="sm" color="blackAlpha.700">
                {patients.length} Match{patients.length === 1 ? '' : 'es'}
              </Text>
            )}
          </Box>

          <Box h="calc(100vh - 142px)" overflowY="scroll">
            <PatientsList patients={patients} isLoading={isLoading} />
          </Box>
        </Box>

        <PatientDetails
          onClinicalStateChange={() => onClinicalStateChange(activePatientUid)}
          patientUid={activePatientUid}
        />
      </Box>
    </Layout>
  );
}

export default function UpNextPageWithProvider() {
  return (
    <FilterProvider>
      <UpNextPage />
    </FilterProvider>
  );
}
