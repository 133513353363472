import { useState } from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import {
  canStateBeOverridden,
  getNextState,
  patientHasMedicationBlocker,
  toastDescription,
} from './utils';
import { dayjs, formatReviewStateName } from '../../../utils';
import {
  clinicalReviewOverrideBlocked,
  clinicalReviewSelectForReview,
} from '../../../api';
import { Events, mixpanel } from '../../../analytics';
import { ArrowRight, FastForward } from '../../../assets';
import { ClinicalReviewStates, IPatient } from '../../../types';

interface Props {
  onUpdate: () => void;
  patient: IPatient;
}

const states = [ClinicalReviewStates.BLOCKED, ClinicalReviewStates.CHASE_LIST];

export default function UpdateClinicalStateButton({
  onUpdate,
  patient,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const { clinicalReviewState } = patient;
  const currentState = clinicalReviewState.state;
  const nextState = getNextState(currentState);
  const secondaryCTA = isSecondaryCTA(patient, currentState);

  async function handleOnClick() {
    setIsLoading(true);

    const { clinicalReviewState, uid } = patient;
    const daysInState = dayjs().diff(
      clinicalReviewState.date?.toDate(),
      'days',
    );

    mixpanel.track(Events.PATIENT_CLINICAL_REVIEW_STATE_CHANGED, {
      current_state: currentState,
      new_state: nextState,
      days_in_state: daysInState,
    });

    try {
      if (nextState === ClinicalReviewStates.REVIEWABLE) {
        await clinicalReviewOverrideBlocked({
          patientUid: uid,
          state: nextState,
        });
      }

      if (
        nextState === ClinicalReviewStates.CHART_PREP ||
        nextState === ClinicalReviewStates.REVIEWING
      ) {
        await clinicalReviewSelectForReview({
          patientUid: uid,
          state: nextState,
        });
      }

      setIsLoading(false);

      toast({
        description: toastDescription(currentState, nextState),
        title: 'Success',
        status: 'success',
      });

      onUpdate();
    } catch (error) {
      toast({
        description:
          'Failed to update patient clinical stage. Please try again.',
        title: 'Uh Oh',
        status: 'error',
      });
    }
  }

  return (
    <Button
      colorScheme={secondaryCTA ? undefined : 'blue'}
      isLoading={isLoading}
      onClick={handleOnClick}
      variant={secondaryCTA ? 'outline' : 'solid'}
      size="lg"
    >
      <Box textAlign="left">
        <Box alignItems="center" display="flex" gap={1.5} mb={1}>
          {getButtonIcon(currentState)}
          <Text>{getButtonText(currentState, nextState)}</Text>
        </Box>
        <Text fontSize="xs" fontWeight="normal">
          {getButtonDescription(currentState)}
        </Text>
      </Box>
    </Button>
  );
}

function getButtonIcon(currentState: ClinicalReviewStates): JSX.Element {
  if (
    currentState === ClinicalReviewStates.REVIEWABLE ||
    currentState === ClinicalReviewStates.CHART_PREP
  ) {
    return <ArrowRight />;
  }

  return <FastForward />;
}

function getButtonDescription(currentState: ClinicalReviewStates): string {
  if (currentState === ClinicalReviewStates.REVIEWABLE) {
    return 'Expedite to next clinical review';
  }

  if (currentState === ClinicalReviewStates.CHART_PREP) {
    return 'Confirm patient is review-ready';
  }

  return 'Expedite move to next stage';
}

function isSecondaryCTA(
  patient: IPatient,
  currentState: ClinicalReviewStates,
): boolean {
  const hasMedicationBlocker = patientHasMedicationBlocker(patient);

  return canStateBeOverridden(currentState) && hasMedicationBlocker;
}

function getButtonText(
  currentState: ClinicalReviewStates,
  nextState: ClinicalReviewStates,
): string {
  if (states.includes(currentState)) {
    return 'Override All Blockers';
  }

  return `Move to ${formatReviewStateName(nextState)}`;
}
