import { httpsCallable } from '@firebase/functions';
import { functions } from '../../../firebase';
import { IPatient } from '../../../types';
import { dayjs, formatDate } from '../../../utils';

export function formatWeightReadingSubtext({
  weight,
  weightDate,
}: {
  weight: number | null;
  weightDate?: Date;
}) {
  if (!weight || !weightDate) {
    return undefined;
  }
  // round to nearest day when measuring by days, but truncate by month count
  const diffInDays = Math.round(dayjs().diff(weightDate, 'd', true));
  const diffInMonths = dayjs().diff(weightDate, 'M');

  return `${
    diffInMonths === 0
      ? diffInDays + ` day${diffInDays === 1 ? '' : 's'}`
      : diffInMonths + ` month${diffInMonths === 1 ? '' : 's'}`
  } ago on ${formatDate(weightDate)}`;
}

export function formatWeightDeltaSubtext({
  startingWeightDate,
  currentWeightDate,
}: {
  startingWeightDate?: Date;
  currentWeightDate?: Date;
}) {
  if (!startingWeightDate || !currentWeightDate) {
    return undefined;
  }

  const diff = dayjs(currentWeightDate).diff(startingWeightDate, 'w');

  if (diff < 1) {
    return 'Over < 1 week';
  }

  return `Over ${diff} week${diff === 1 ? '' : 's'}`;
}

export function calculateTotalWeightDelta({
  startingWeight,
  currentWeight,
}: {
  startingWeight: number | null;
  currentWeight: number | null;
}) {
  if (!startingWeight || !currentWeight) {
    return null;
  }
  // positive int will indicate weight gain, negative int will indicate weight loss
  return currentWeight - startingWeight;
}

export function calculateWeightDeltaPercent({
  startingWeight,
  currentWeight,
}: {
  startingWeight: number | null;
  currentWeight: number | null;
}) {
  const weightDelta = calculateTotalWeightDelta({
    startingWeight,
    currentWeight,
  });
  if (weightDelta === null) {
    return null;
  }

  return Math.round((weightDelta / startingWeight!) * 100);
}

export function formatWeightDeltaStat(stat: number | null) {
  if (stat === null) return null;

  if (stat > 0) {
    return `+${stat}`;
  }
  return stat;
}

export function constructWeightNarrativeMessage({
  startingWeight,
  currentWeight,
  weightDelta,
  weightDeltaPercent,
}: {
  startingWeight: number | null;
  currentWeight: number | null;
  weightDelta: number | null;
  weightDeltaPercent: number | null;
}) {
  const message = `Starting weight: ${startingWeight}\nCurrent weight: ${currentWeight}\nTotal weight change: ${formatWeightDeltaStat(
    weightDelta,
  )}lbs\n% Weight change: ${formatWeightDeltaStat(
    weightDeltaPercent,
  )}%\nMedications: \nPlan: `;

  return { enabled: Boolean(startingWeight && currentWeight), message };
}

export async function updateWeightReadingOutlierStatus(
  readingId: string,
  outlier: boolean,
): Promise<string> {
  const updateWeightReadingOutlier = httpsCallable<
    {
      readingId: string;
      outlier: boolean;
    },
    { readingId: string }
  >(functions, 'updateWeightReadingOutlier');

  const { data } = await updateWeightReadingOutlier({ readingId, outlier });

  return data.readingId;
}

export function getBMIData(patient: IPatient):
  | {
      bmi: number;
      readingTime: string;
    }
  | undefined {
  const { firstReadingWeight, lastReadingWeight } = patient;

  if (lastReadingWeight?.bmi) {
    const readingTime = dayjs(lastReadingWeight.readingTime.toDate()).format(
      'MM-DD-YYYY',
    );

    return {
      bmi: lastReadingWeight.bmi,
      readingTime,
    };
  }

  if (firstReadingWeight?.bmi) {
    const readingTime = dayjs(firstReadingWeight.readingTime.toDate()).format(
      'MM-DD-YYYY',
    );

    return {
      bmi: firstReadingWeight.bmi,
      readingTime,
    };
  }

  return undefined;
}
