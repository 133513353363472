import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../../components';
import { IBroadcast } from '../../types';
import BroadcastsList from './BroadcastsList';
import CreateBroadcastModal from './CreateBroadcastModal';
import { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';

export default function BroadcastsPage(): JSX.Element {
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const [broadcasts, setBroadcasts] = useState<IBroadcast[]>([]);

  function navigateToBroadcastDetails(broadcastId: string) {
    navigate(`/broadcasts/${broadcastId}`);
  }

  async function fetchBroadcasts() {
    const broadcastsQuery = query(
      collection(db, 'broadcasts'),
      orderBy('createdAt', 'desc'),
    );
    const broadcastsDocs = await getDocs(broadcastsQuery);
    const broadcasts: IBroadcast[] = [];

    broadcastsDocs.forEach((doc) => {
      broadcasts.push({
        id: doc.id,
        ...doc.data(),
      } as IBroadcast);
    });

    setBroadcasts(broadcasts);
  }

  useEffect(() => {
    fetchBroadcasts();
  }, []);

  return (
    <Layout>
      <CreateBroadcastModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        px={6}
        py={3}
      >
        <Heading fontSize={24} fontWeight="extrabold">
          Broadcasts
        </Heading>

        <Button colorScheme="blue" size="sm" onClick={onCreateModalOpen}>
          Create Broadcast
        </Button>
      </Box>

      <BroadcastsList
        broadcasts={broadcasts}
        onSelectBroadcast={navigateToBroadcastDetails}
      />
    </Layout>
  );
}
