import { ChangeEvent, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { IBroadcastAttachment } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (link: IBroadcastAttachment) => void;
}

export default function AttachLinkModal({
  isOpen,
  onClose,
  onSubmit,
}: IProps): JSX.Element {
  const [url, setUrl] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [hasUrlError, setHasUrlError] = useState<boolean>(false);
  const [hasDescriptionError, setHasDescriptionError] =
    useState<boolean>(false);

  function handleOnUrlChange({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    const { value } = currentTarget;

    setUrl(value);

    if (!value.startsWith('https://')) {
      setHasUrlError(true);
    } else {
      setHasUrlError(false);
    }
  }

  function handleOnDescriptionChange({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) {
    const { value } = currentTarget;

    setDescription(value);

    if (!value) {
      setHasDescriptionError(true);
    } else {
      setHasDescriptionError(false);
    }
  }

  function handleSubmit() {
    const link: IBroadcastAttachment = {
      description,
      linkUrl: url,
      type: 'link',
    };

    onSubmit(link);
    handleOnClose();
  }

  function handleOnClose() {
    setUrl('');
    setDescription('');
    setHasUrlError(false);
    setHasDescriptionError(false);
    onClose();
  }

  const isDisabled = !url || !description || hasUrlError || hasDescriptionError;

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach Link</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isRequired mb={4} isInvalid={hasUrlError}>
            <FormLabel>URL</FormLabel>
            <Input placeholder="URL" onChange={handleOnUrlChange} />
            <FormErrorMessage>A valid URL is required.</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={hasDescriptionError}>
            <FormLabel>Description</FormLabel>
            <Input
              placeholder="Description"
              onChange={handleOnDescriptionChange}
            />
            <FormErrorMessage>This field is required.</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" size="sm" onClick={handleOnClose} mr={2}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={handleSubmit}
            isDisabled={isDisabled}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
