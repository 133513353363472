import { Skeleton, Text, TextProps } from '@chakra-ui/react';
import { IPatient } from '../../types';

interface IProps extends TextProps {
  isLoading?: boolean;
  patient?: IPatient;
}

export default function PatientName(props: IProps): JSX.Element {
  const { isLoading = false, patient, ...textProps } = props;
  const fullName = `${patient?.firstName} ${patient?.lastName}`;

  return (
    <Skeleton isLoaded={!isLoading}>
      <Text fontWeight="semibold" fontSize="sm" {...textProps}>
        {fullName}
      </Text>
    </Skeleton>
  );
}
