import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import SearchResultRow from './SearchResultRow';
import { IPatient } from '../../../../types';

interface IProps {
  onSelectSearchResult: (patientUid: string) => void;
  patients: IPatient[];
}

export const DROPDOWN_HEIGHT = 350;

export default function SearchResults({
  onSelectSearchResult,
  patients,
}: IProps): JSX.Element {
  const [selectedResult, setSelectedResult] = useState<number>(0);
  const selectedResultRef = useRef<number>(0);

  useEffect(() => {
    selectedResultRef.current = selectedResult;
  }, [selectedResult, selectedResultRef]);

  const handleOnPressEnter = useCallback(() => {
    const patient = patients[selectedResultRef.current];
    onSelectSearchResult(patient.uid);
  }, [onSelectSearchResult, patients]);

  const keydownListener = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      if (key === 'ArrowDown' || key === 'ArrowUp' || key === 'Enter') {
        event.preventDefault();
      }

      if (key === 'Enter') {
        handleOnPressEnter();
      } else if (key === 'ArrowDown') {
        setSelectedResult((previous) => {
          if (previous === patients.length - 1) {
            return 0;
          }

          return previous + 1;
        });
      } else if (key === 'ArrowUp') {
        setSelectedResult((previous) => {
          if (previous === 0) {
            return patients.length - 1;
          }

          return previous - 1;
        });
      }
    },
    [handleOnPressEnter, patients.length],
  );

  useEffect(() => {
    window.addEventListener('keydown', keydownListener);

    return () => window.removeEventListener('keydown', keydownListener);
  }, [patients, keydownListener]);

  return (
    <Box
      position="absolute"
      mt={1}
      p={2}
      bg="gray.50"
      rounded="md"
      shadow="md"
      w="320px"
      maxHeight={DROPDOWN_HEIGHT}
      overflowY="scroll"
      zIndex={1}
    >
      {patients.map((patient, idx) => (
        <SearchResultRow
          isSelected={idx === selectedResult}
          key={patient.uid}
          onClick={onSelectSearchResult}
          patient={patient}
        />
      ))}
    </Box>
  );
}
