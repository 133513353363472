import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Timestamp } from 'firebase/firestore';
import { dayjs } from '../../../utils';
import { Reading, ReadingType } from './';
import { getReadingValuesByType } from './utils';

interface IProps {
  reading: Reading;
  readingType: ReadingType;
}

export default function TableRow({
  reading,
  readingType,
}: IProps): JSX.Element {
  const values = useMemo(
    () => getReadingValuesByType(reading, readingType),
    [reading, readingType],
  );

  return (
    <Box
      borderBottom="1px solid"
      borderColor="blackAlpha.200"
      display="grid"
      fontSize="sm"
      gridTemplateColumns={`${values.map(() => '48px').join(' ')} auto`}
      py={3}
    >
      {values.map((value, index) => (
        <Text color="blackAlpha.800" fontWeight="medium" key={index}>
          {value}
        </Text>
      ))}
      <Text color="blackAlpha.700" textAlign="right">
        {formatBPReadingDate(reading.readingTime)}
      </Text>
    </Box>
  );
}

function formatBPReadingDate(timestamp: Timestamp): string {
  const date = dayjs(timestamp.toDate());

  return date.format('M/DD/YYYY, h:mmA');
}
