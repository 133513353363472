export const BP_THREAD_SUBJECT = 'Blood Pressure Check-in';
export const BP_MESSAGE_COPY = `We see your blood pressure is higher than normal right now. If you're able to, would you be able to recheck your blood pressure?

While we don’t get concerned about a random high reading, we do want to make sure it’s random. So, let us know if you are experiencing any of the following:

  1. Headache
  2. Chest pain
  3. New vision changes
  4. New weakness, difficultly speaking or walking
  5. Dizziness
  6. New back pain
  7. Nausea or vomiting

Reply "NO TO ALL" if you are not experiencing any of these symptoms. If you are experiencing any of these symptoms please call 911 or go to your local emergency room right away.
`;

export const HEART_RATE_THREAD_SUBJECT = 'Heart Rate Check-in';
export const HEART_RATE_MESSAGE_COPY = `When you recently checked your blood pressure, your heart rate was reported to us as abnormal. If you are able to, would you be able to recheck your blood pressure? While we don’t get concerned about a random abnormal reading, we do want to make sure it’s random. So, let us know if you are experiencing any of the following:

  - Chest pain
  - Confusion or memory problems
  - Dizziness or lightheadedness
  - Unusual fatigue
  - Shortness of breath
  - Sensation of a racing, pounding heartbeat or flopping in the chest (palpitations)

Reply “NO TO ALL” if you are not experiencing any of these symptoms. If you are experiencing any of these symptoms please call 911 or go to your local emergency room right away.'
`;

export const LOW_GLUCOSE_THREAD_SUBJECT = 'Low Glucose Check-in';
export const LOW_GLUCOSE_MESSAGE_COPY = `We received an alert that you had a critically low blood glucose reading. This can occur if you have missed or skipped a meal, have been unusually active, or are taking too much medication.

While we usually don’t have to change your treatment plan based on one blood glucose reading, we do want to know if you had any symptoms and if treatment was required.   Please let us know if you are experiencing any of the following:

1. Irregular or fast heartbeat
2. Sudden fatigue
3. Pale skin
4. Shakey, sweaty, anxious feelings
5. Lightheadedness
6. Sudden onset of hunger
7. Sudden change in mood
8. Tingling or numbness of lips, tongue, or cheek

As a reminder, if you are experiencing a blood sugar LESS than 70 mg/dl WITH low blood sugar symptoms, follow the 15/15 rule.

15/15 Rule:
Have 15 grams of carbohydrate to raise your blood sugar and check it after 15 minutes.

Examples: 1 cup non-fat milk, 4 teaspoons of sugar, 4 glucose tablets, gel tube (see package instructions), 4 ounces (½ cup) of juice or regular soda (not diet), 1 tablespoon of sugar, honey, or corn syrup, OR 4-5 hard candies eaten quickly. If it is still below 70 mg/dl, have another serving.

Reply “NO TO ALL” if you are not experiencing any of these symptoms.

Reply “I TREATED AND SYMPTOMS WENT AWAY” if you required treatment.

If you are experiencing these symptoms and symptoms did not resolve with treatment, or if worsens please call 9-1-1 or go to your local emergency room right away.`;

export const HIGH_GLUCOSE_THREAD_SUBJECT = 'High Glucose Check-in';
export const HIGH_GLUCOSE_MESSAGE_COPY = `Checking in because we saw a high blood sugar reading. This can happen if you eat certain foods, forget your medication, or if your body is stressed from being sick or tired.

When you blood sugar is high you may feel thirsty, urinate more, or experience any of the following:

- nausea and vomiting
- dry mouth
- sudden fatigue
- blurry vision
- difficulty concentrating
- stomach pain

If you blood sugar is over 300mg/dL AND you are experiencing any of the above symptoms, you should seek local emergency care.

REPLY “NO TO ALL” if you are not experiencing any of these symptoms.`;
