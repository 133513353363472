import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ChangePatientStatus from './ChangePatientStatus';
import EditPatient from './EditPatient';
import {
  TExternalLinkDestination,
  TLinkCopiedDestination,
} from '../../../../analytics';
import { Copy, ExternalLink, More } from '../../../../assets';
import { handleExternalLinkClick } from '../../../../analytics/utils';
import { IPatient } from '../../../../types';

function ExternalLinkRow({
  linkUrl,
  text,
  mixpanelDestination,
}: {
  linkUrl: string;
  text: string;
  mixpanelDestination: TExternalLinkDestination;
}) {
  return (
    <MenuItem
      as={Link}
      display="flex"
      gap={2}
      to={linkUrl}
      target="_blank"
      onClick={() =>
        handleExternalLinkClick({
          context: 'Patient',
          destination: mixpanelDestination,
        })
      }
    >
      <ExternalLink /> Open in {text}
    </MenuItem>
  );
}

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
  onCopyLinkText: (
    linkText: string,
    destination: TLinkCopiedDestination,
  ) => Promise<void>;
}

export default function MenuDropdown({
  onUpdate,
  onCopyLinkText,
  patient,
}: IProps) {
  const { uid, canvasId, customerIOId, impiloId, sourceId } = patient;

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="menu"
        icon={<More />}
        variant="outline"
        size="sm"
      />

      <MenuList zIndex={1000}>
        <EditPatient onUpdate={onUpdate} patient={patient} />

        <MenuDivider />

        {canvasId && (
          <ExternalLinkRow
            text="Canvas"
            mixpanelDestination="Canvas"
            linkUrl={`${process.env.REACT_APP_CANVAS_URL}/patient/${canvasId}`}
          />
        )}
        {impiloId && (
          <ExternalLinkRow
            text="Impilo"
            mixpanelDestination="Impilo"
            linkUrl={`${process.env.REACT_APP_IMPILO_URL}/patient/${impiloId}`}
          />
        )}
        {sourceId && (
          <ExternalLinkRow
            text="Source"
            mixpanelDestination="Source"
            linkUrl={`${process.env.REACT_APP_SOURCE_URL}/members/${sourceId}`}
          />
        )}
        {customerIOId && (
          <ExternalLinkRow
            text="CustomerIO"
            mixpanelDestination="Customer.io"
            linkUrl={`${process.env.REACT_APP_CUSTOMER_IO_URL}/people/${customerIOId}`}
          />
        )}
        {uid && (
          <MenuItem
            display="flex"
            gap={2}
            onClick={() =>
              onCopyLinkText(
                `${process.env.REACT_APP_FORMSORT_OFFBOARDING_SURVEY_URL}?marley_patient_uuid=${patient.uid}`,
                'Offboarding Survey',
              )
            }
          >
            <Copy /> Copy Patient Offboarding Survey Link
          </MenuItem>
        )}

        <MenuDivider />

        <ChangePatientStatus onUpdate={onUpdate} patient={patient} />
      </MenuList>
    </Menu>
  );
}
