import { useNavigate } from 'react-router-dom';
import { Box, Heading, Skeleton, useDisclosure } from '@chakra-ui/react';
import { ArrowLeft } from '../../../assets';
import { BroadcastStatusBadge } from '../../../components';
import { BroadcastStatus, IBroadcast } from '../../../types';
import SendBroadcastButton from './SendBroadcastButton';
import { BroadcastDetailsTab } from '..';
import DismissConfirmationModal from './DismissConfirmationModal';
import BroadcastErrorAlert from './BroadcastErrorAlert';

interface IHeaderProps {
  broadcast?: IBroadcast;
  isLoading: boolean;
  fetchBroadcast: () => Promise<void>;
  setSelectedTab: React.Dispatch<React.SetStateAction<BroadcastDetailsTab>>;
}

export default function Header({
  broadcast,
  isLoading,
  fetchBroadcast,
  setSelectedTab,
}: IHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const broadcastErrorCount = broadcast?.errorsByPatientUid.length;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <DismissConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        broadcast={broadcast}
        fetchBroadcast={fetchBroadcast}
      />
      <Box borderBottom="1px solid" borderColor="gray.200" px={6} py={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box alignItems="center" display="flex">
            <Box
              cursor="pointer"
              borderRadius={16}
              h={8}
              w={8}
              bg="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={2}
              onClick={() => navigate('/broadcasts')}
            >
              <ArrowLeft color="blackAlpha.900" />
            </Box>

            <Heading fontSize={24} fontWeight="extrabold" mr={2}>
              <Skeleton isLoaded={Boolean(!isLoading && broadcast)}>
                {broadcast?.threadSubject ?? 'Broadcast not found'}
              </Skeleton>
            </Heading>

            {broadcast && <BroadcastStatusBadge status={broadcast.status} />}
          </Box>

          {broadcast?.status === BroadcastStatus.DRAFT && (
            <SendBroadcastButton
              broadcast={broadcast}
              onSuccess={fetchBroadcast}
            />
          )}
        </Box>

        {Boolean(broadcastErrorCount) ? (
          <BroadcastErrorAlert
            broadcastErrorCount={broadcastErrorCount!}
            onOpen={onOpen}
            setSelectedTab={setSelectedTab}
          />
        ) : null}
      </Box>
    </>
  );
}
