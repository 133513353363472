import { Icon, IconProps } from '@chakra-ui/react';

export default function ChevronDown(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
        ></path>
      </svg>
    </Icon>
  );
}
