import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  getPatientStatus,
  formatEnrolledOn,
  formatWeeksEnrolled,
  StatusColors,
} from '../../../utils/patientUtils';
import { PatientName, ProviderAvatar } from '../../../components';
import { IPatient } from '../../../types';
import { PATIENTS_TABLE_COLUMN_LAYOUT } from '../PatientsTable';
import { formatDate } from '../../../utils';
import { AlertCircle } from '../../../assets';

interface IProps {
  patient: IPatient;
  hasError?: boolean;
}

export default function TableRow({ patient, hasError }: IProps): JSX.Element {
  const { address, dateOfBirth, careTeamLead } = patient;
  const { adminId: assignedMdId, display: assignedMdName } = careTeamLead ?? {};

  const dob = formatDate(dateOfBirth);
  const age = dayjs().diff(dayjs(dateOfBirth), 'year');
  const enrolledOn = useMemo(() => formatEnrolledOn(patient), [patient]);
  const weeksEnrolled = useMemo(() => formatWeeksEnrolled(patient), [patient]);
  const patientStatus = useMemo(() => getPatientStatus(patient), [patient]);

  return (
    <Box
      py={3}
      px={6}
      borderBottom="1px solid"
      borderColor="gray.200"
      alignItems="center"
      display="grid"
      gap={2}
      gridTemplateColumns={PATIENTS_TABLE_COLUMN_LAYOUT}
    >
      <Box display="flex" alignItems="center" gap={3}>
        {hasError ? <AlertCircle h={6} w={6} color="red.500" /> : null}
        <PatientName patient={patient} />
      </Box>

      <Box fontSize="sm">
        <Text>{dob}</Text>
        <Text color="blackAlpha.600">Age: {age}</Text>
      </Box>

      <Box>
        <Text
          bg={StatusColors[patientStatus]}
          color="white"
          fontSize="xs"
          p={1}
          rounded="md"
          display="inline"
          fontWeight="semibold"
          textTransform="capitalize"
        >
          {patientStatus}
        </Text>
      </Box>

      <Box fontSize="sm">
        <Text>{enrolledOn}</Text>
        {weeksEnrolled !== null && (
          <Text color="blackAlpha.600">Enrolled: {weeksEnrolled}</Text>
        )}
      </Box>

      <Box>{address.state}</Box>
      <Box textAlign="end">
        <ProviderAvatar
          size="sm"
          providerId={assignedMdId}
          providerName={assignedMdName}
        />
      </Box>
    </Box>
  );
}
