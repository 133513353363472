import { Icon, IconProps } from '@chakra-ui/react';

export default function CheckmarkCircleFilled(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9969 8.71767C16.1528 8.85405 16.1686 9.09096 16.0322 9.24682L10.7822 15.2468C10.7139 15.3249 10.6162 15.3712 10.5125 15.3747C10.4087 15.3781 10.3082 15.3384 10.2348 15.265L7.98483 13.015C7.83839 12.8686 7.83839 12.6312 7.98483 12.4847C8.13128 12.3383 8.36872 12.3383 8.51517 12.4847L10.4817 14.4513L15.4678 8.75294C15.6042 8.59708 15.8411 8.58129 15.9969 8.71767Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}
