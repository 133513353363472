import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFormik } from 'formik';
import DatePicker from 'react-date-picker';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  Divider,
} from '@chakra-ui/react';
import {
  CommunicationPreferenceInput,
  EmailInput,
  PhoneNumberInput,
  EditProgramEnrollmentInput,
} from '../';
import { Calendar, RequiredAsterisk } from '../../assets';
import { getStateOptions } from '../../utils';
import { functions } from '../../firebase';
import { CommunicationPreference, IPatient } from '../../types';
import {
  formatFormValues,
  IFormValues,
  setInitialValues,
  validateContactInformation,
} from './utils';
import './styles.css';
import FinancialAid from './FinancialAid';
import PatientFormDrawer from './PatientFormDrawer';
import PaymentStatusRadio from './PaymentStatusRadio';
import { PatientPrograms } from '../PatientDetailsPanelColumn/ProgramEnrollmentPrompt';
import { Events, mixpanel } from '../../analytics';

interface IProps {
  initialValues?: IPatient;
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
}

export default function PatientForm({
  initialValues,
  isOpen,
  onClose,
  onUpdate,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showProgramError, setShowProgramError] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const isEditingPatient = Boolean(initialValues);

  const formik = useFormik({
    initialValues: setInitialValues(initialValues),
    onSubmit: isEditingPatient ? handleOnEditPatient : handleOnCreatePatient,
    enableReinitialize: true,
  });

  async function handleOnCreatePatient(values: IFormValues) {
    setIsLoading(true);

    const isContactInformationValid = validateContactInformation(values);

    if (!isContactInformationValid) {
      toast({
        description: 'Please enter a valid email address and/or phone number.',
        status: 'error',
        title: 'Uh Oh.',
      });
      setIsLoading(false);
      return;
    }

    const patientIntake = httpsCallable<any, { uid: string }>(
      functions,
      'patientIntake',
    );
    const patientData = formatFormValues(values);

    try {
      const { data } = await patientIntake(patientData);

      toast({
        description: `${values.firstName} ${values.lastName} has been added as a patient.`,
        status: 'success',
        title: 'Success!',
      });

      handleOnClose();

      mixpanel.track(Events.PATIENT_CREATED);

      navigate(`/patients/${data.uid}`);
    } catch (error) {
      toast({
        description: 'An error occurred while adding the new patient.',
        status: 'error',
        title: 'Uh oh!',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnEditPatient(values: IFormValues) {
    if (initialValues?.isEnrolled && !values.selectedPrograms.length) {
      setShowProgramError(true);
      return;
    }

    const isContactInformationValid = validateContactInformation(values);

    if (!isContactInformationValid) {
      toast({
        description: 'Please enter a valid email address and/or phone number.',
        status: 'error',
        title: 'Uh Oh.',
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const updatePatientRecord = httpsCallable(functions, 'updatePatientRecord');
    const patientData = {
      isActive: initialValues!.isActive,
      ...formatFormValues(values),
    };

    try {
      await updatePatientRecord({
        patientUid: initialValues!.uid,
        data: patientData,
      });

      onUpdate && onUpdate();

      mixpanel.track(Events.PATIENT_EDITED);

      toast({
        description: `${values.firstName} ${values.lastName}'s record has been updated.`,
        status: 'success',
        title: 'Success!',
      });

      handleOnClose();
    } catch (error) {
      toast({
        description: "An error occurred while updating the patient's record.",
        status: 'error',
        title: 'Uh oh!',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleOnClose() {
    formik.resetForm();
    setIsLoading(false);
    setShowProgramError(false);
    onClose();
  }

  return (
    <PatientFormDrawer
      handleOnClose={handleOnClose}
      isEditing={isEditingPatient}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleOnClose}
      onSubmit={formik.handleSubmit}
    >
      {!isEditingPatient && (
        <Box p={6} bg="gray.50" borderRadius={6} mb={6}>
          <Text>
            Adding a new patient in Marley Admin creates linked patient records
            in Canvas, Source, and Impilo. Do not submit this form if a patient
            record has already been created in one of these apps.
          </Text>
        </Box>
      )}

      <Text mb={6} fontSize="lg" fontWeight="semibold">
        Patient details
      </Text>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        rowGap={4}
        columnGap={4}
        mb={6}
      >
        <FormControl isRequired>
          <FormLabel>First name</FormLabel>
          <Input
            id="firstName"
            placeholder="First name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Last name</FormLabel>
          <Input
            id="lastName"
            placeholder="Last name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Date of birth</FormLabel>
          <Input
            id="dateOfBirth"
            name="dateOfBirth"
            onChange={(value) => {
              formik.setFieldValue('dateOfBirth', value || '');
            }}
            // @ts-ignore
            value={formik.values.dateOfBirth}
            placeholder="YYYY-MM-DD"
            as={DatePicker}
            openCalendarOnFocus={false}
            calendarIcon={<Calendar />}
            clearIcon={null}
            showLeadingZeros={true}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Sex at birth</FormLabel>
          <Select
            id="sexAtBirth"
            value={formik.values.sexAtBirth}
            onChange={formik.handleChange}
          >
            <option value="" disabled selected>
              Sex at birth
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            <option value="unknown">Unknown</option>
          </Select>
        </FormControl>
      </Box>

      <Divider />
      <Text my={6} fontSize="lg" fontWeight="semibold">
        Contact info
      </Text>

      <Box display="flex" gap={4} mb={4}>
        <EmailInput
          defaultValue={formik.values.email}
          onChange={(email: string) => formik.setFieldValue('email', email)}
        />

        <PhoneNumberInput
          defaultValue={formik.values.phoneNumber}
          onChange={(phoneNumber: string) =>
            formik.setFieldValue('phoneNumber', phoneNumber)
          }
        />
      </Box>
      <FormControl isRequired mb={4}>
        <FormLabel>Communication preference</FormLabel>
        <CommunicationPreferenceInput
          onChange={(preference: CommunicationPreference) =>
            formik.setFieldValue('communicationPreference', preference)
          }
          value={formik.values.communicationPreference}
        />
      </FormControl>

      <Box mb={6}>
        <FormControl isRequired>
          <FormLabel>Mailing address</FormLabel>
          <Input
            id="addressLineOne"
            placeholder="Line one"
            mb={2}
            onChange={formik.handleChange}
            value={formik.values.addressLineOne}
          />
        </FormControl>
        <Input
          id="addressLineTwo"
          placeholder="Line two"
          mb={2}
          onChange={formik.handleChange}
          value={formik.values.addressLineTwo}
        />
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap={3}>
          <FormControl isRequired>
            <Input
              id="addressCity"
              placeholder="City"
              onChange={formik.handleChange}
              value={formik.values.addressCity}
            />
          </FormControl>
          <FormControl isRequired>
            <Select
              id="addressState"
              onChange={formik.handleChange}
              value={formik.values.addressState}
            >
              <option value="" disabled selected>
                Select State
              </option>
              {getStateOptions()}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <Input
              id="addressZipCode"
              placeholder="Zip code"
              onChange={formik.handleChange}
              value={formik.values.addressZipCode}
            />
          </FormControl>
        </Box>
      </Box>

      <Divider />

      <Box mb={6}>
        <Text my={6} fontSize="lg" fontWeight="semibold">
          Payment info
        </Text>

        <PaymentStatusRadio
          onChange={(value) => formik.setFieldValue('billingType', value)}
          value={formik.values.billingType}
        />

        {isEditingPatient && (
          <Box mt={4}>
            <FinancialAid
              financialAidApprovedAt={formik.values.financialAidApprovedAt}
              financialAidStatus={formik.values.financialAidStatus}
              onFinancialAidApprovedAtChange={(value) =>
                formik.setFieldValue('financialAidApprovedAt', value)
              }
              onFinancialAidStatusChange={(value) =>
                formik.setFieldValue('financialAidStatus', value)
              }
            />
          </Box>
        )}
      </Box>

      <Divider />

      {!isEditingPatient && (
        <>
          <Text my={6} fontSize="lg" fontWeight="semibold">
            Additional info
          </Text>

          <FormControl mt={6}>
            <FormLabel>Intake notes</FormLabel>
            <Textarea id="clinicalNote" onChange={formik.handleChange} />
            <FormHelperText>
              This note will appear below the patient's name in Canvas.
            </FormHelperText>
          </FormControl>
        </>
      )}

      {initialValues?.isEnrolled && (
        <>
          <Divider />
          <Text my={6} fontSize="lg" fontWeight="semibold">
            Programs
          </Text>
          <Box mb={4}>
            <FormLabel>
              Patient's enrolled programs
              <RequiredAsterisk />
            </FormLabel>
            <EditProgramEnrollmentInput
              onChange={(selectedPrograms: PatientPrograms[]) => {
                formik.setFieldValue('selectedPrograms', selectedPrograms);
                if (selectedPrograms.length) {
                  setShowProgramError(false);
                }
              }}
              value={formik.values.selectedPrograms}
            />
            {showProgramError && (
              <Text mt={1} fontSize="xs" color="red.500">
                Please select at least one program to enroll patient in.
              </Text>
            )}
          </Box>
        </>
      )}
    </PatientFormDrawer>
  );
}
