import { Box, Text } from '@chakra-ui/react';
import { BloodPressure } from '../../../../assets';
import { BloodPressureRisk, IPatient } from '../../../../types';

interface IProps {
  patient: IPatient;
}

const options: Record<
  Exclude<BloodPressureRisk, BloodPressureRisk.UNKNOWN>,
  {
    bg: string;
    color: string;
    copy: string;
  }
> = {
  [BloodPressureRisk.LOW]: {
    bg: 'green.100',
    color: 'green.900',
    copy: 'Low',
  },
  [BloodPressureRisk.MODERATE]: {
    bg: 'orange.100',
    color: 'orange.900',
    copy: 'Moderate',
  },
  [BloodPressureRisk.HIGH]: {
    bg: 'red.100',
    color: 'red.900',
    copy: 'High',
  },
};

export default function RiskBadge({ patient }: IProps): JSX.Element | null {
  const { lastBloodPressureRisk } = patient;

  if (!lastBloodPressureRisk) {
    return null;
  }

  const { risk } = lastBloodPressureRisk;

  if (risk === BloodPressureRisk.UNKNOWN) {
    return null;
  }

  const { bg, color, copy } = options[risk];

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      fontSize="xs"
      gap={1}
      py={1}
      px="6px"
      bg={bg}
      color={color}
      rounded="2xl"
    >
      <BloodPressure />
      <Text>{copy}</Text>
    </Box>
  );
}
