import { Icon, IconProps } from '@chakra-ui/react';

export default function Filter(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9062 4.59375H1.09375C0.919702 4.59375 0.752782 4.52461 0.629711 4.40154C0.50664 4.27847 0.4375 4.11155 0.4375 3.9375C0.4375 3.76345 0.50664 3.59653 0.629711 3.47346C0.752782 3.35039 0.919702 3.28125 1.09375 3.28125H12.9062C13.0803 3.28125 13.2472 3.35039 13.3703 3.47346C13.4934 3.59653 13.5625 3.76345 13.5625 3.9375C13.5625 4.11155 13.4934 4.27847 13.3703 4.40154C13.2472 4.52461 13.0803 4.59375 12.9062 4.59375Z"
          fill="currentColor"
        />
        <path
          d="M10.7188 7.65625H3.28125C3.1072 7.65625 2.94028 7.58711 2.81721 7.46404C2.69414 7.34097 2.625 7.17405 2.625 7C2.625 6.82595 2.69414 6.65903 2.81721 6.53596C2.94028 6.41289 3.1072 6.34375 3.28125 6.34375H10.7188C10.8928 6.34375 11.0597 6.41289 11.1828 6.53596C11.3059 6.65903 11.375 6.82595 11.375 7C11.375 7.17405 11.3059 7.34097 11.1828 7.46404C11.0597 7.58711 10.8928 7.65625 10.7188 7.65625Z"
          fill="currentColor"
        />
        <path
          d="M8.09375 10.7188H5.90625C5.7322 10.7188 5.56528 10.6496 5.44221 10.5265C5.31914 10.4035 5.25 10.2365 5.25 10.0625C5.25 9.88845 5.31914 9.72153 5.44221 9.59846C5.56528 9.47539 5.7322 9.40625 5.90625 9.40625H8.09375C8.2678 9.40625 8.43472 9.47539 8.55779 9.59846C8.68086 9.72153 8.75 9.88845 8.75 10.0625C8.75 10.2365 8.68086 10.4035 8.55779 10.5265C8.43472 10.6496 8.2678 10.7188 8.09375 10.7188Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
