import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from '@chakra-ui/react';
import PrioritySelect from './PrioritySelect';
import { Filter } from '../../../../assets';
import SegmentSelect from './SegmentSelect';

export default function FilterMenu(): JSX.Element {
  return (
    <Menu>
      <MenuButton as={Button} size="sm" variant="outline">
        <Filter />
      </MenuButton>
      <MenuList zIndex={1000}>
        <PrioritySelect />

        <MenuDivider />

        <SegmentSelect />
      </MenuList>
    </Menu>
  );
}
