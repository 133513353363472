import { FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { BroadcastAttachmentsManager } from '../../../../components';
import { IBroadcastAttachment } from '../../../../types';

interface IProps {
  isLoading: boolean;
  onAttachmentsChange: (value: IBroadcastAttachment[]) => void;
  onMessageBodyChange: (value: string) => void;
  onThreadSubjectChange: (value: string) => void;
}

export default function MessageEntry({
  isLoading,
  onAttachmentsChange,
  onMessageBodyChange,
  onThreadSubjectChange,
}: IProps): JSX.Element {
  return (
    <>
      <FormControl isDisabled={isLoading} isRequired mb={4}>
        <FormLabel>Subject line</FormLabel>
        <Input
          placeholder="Subject line"
          onChange={({ currentTarget }) =>
            onThreadSubjectChange(currentTarget.value)
          }
        />
      </FormControl>

      <FormControl isDisabled={isLoading} isRequired mb={4}>
        <FormLabel>Message</FormLabel>
        <Textarea
          h={350}
          placeholder="Message"
          onChange={({ currentTarget }) =>
            onMessageBodyChange(currentTarget.value)
          }
        />
      </FormControl>

      <BroadcastAttachmentsManager
        isEditable={true}
        onChange={onAttachmentsChange}
      />
    </>
  );
}
