import { dayjs } from '../../utils';
import {
  BillingType,
  CommunicationPreference,
  FinancialAidStatus,
  IPatient,
  TSexAtBirth,
} from '../../types';
import { PatientPrograms } from '../PatientDetailsPanelColumn/ProgramEnrollmentPrompt';

export interface IFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  sexAtBirth: string;
  email: string;
  phoneNumber: string;
  addressLineOne: string;
  addressLineTwo?: string;
  addressCity: string;
  addressState: string;
  addressZipCode: string;
  clinicalNote?: string;
  billingType: BillingType;
  communicationPreference: CommunicationPreference;
  financialAidStatus: FinancialAidStatus;
  financialAidApprovedAt: Date | null;
  selectedPrograms: PatientPrograms[];
}

const initialFormValues: IFormValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: undefined,
  sexAtBirth: '',
  email: '',
  phoneNumber: '',
  addressLineOne: '',
  addressLineTwo: '',
  addressCity: '',
  addressState: '',
  addressZipCode: '',
  clinicalNote: '',
  billingType: BillingType.SELF_PAY,
  communicationPreference: CommunicationPreference.SMS,
  financialAidStatus: FinancialAidStatus.NOT_APPLICABLE,
  financialAidApprovedAt: null,
  selectedPrograms: [],
};

export function setInitialValues(patient?: IPatient): IFormValues {
  if (patient) {
    return formatPatientToFormValues(patient);
  }

  return initialFormValues;
}

function getEnrolledPatientPrograms(patient: IPatient) {
  const programs: PatientPrograms[] = [];

  if (patient.programs?.diabetes.enrolled) {
    programs.push(PatientPrograms.DIABETES);
  }

  if (patient.programs?.hypertension.enrolled) {
    programs.push(PatientPrograms.HYPERTENSION);
  }

  if (patient.programs?.weight.enrolled) {
    programs.push(PatientPrograms.WEIGHT);
  }

  return programs;
}

function formatPatientToFormValues(patient: IPatient): IFormValues {
  const { address, billing } = patient;
  const dateOfBirth = dayjs(patient.dateOfBirth).toDate();
  const enrolledPrograms = getEnrolledPatientPrograms(patient);

  return {
    firstName: patient.firstName,
    lastName: patient.lastName,
    dateOfBirth,
    sexAtBirth: patient.sexAtBirth,
    email: patient.email,
    phoneNumber: patient.phoneNumber,
    addressLineOne: address.lineOne,
    addressLineTwo: address.lineTwo,
    addressCity: address.city,
    addressState: address.state,
    addressZipCode: address.zipCode,
    billingType: billing.type || BillingType.SELF_PAY,
    communicationPreference: patient.communicationPreference,
    financialAidStatus: billing.financialAidStatus || null,
    financialAidApprovedAt: billing.financialAidApprovedAt?.toDate() || null,
    selectedPrograms: enrolledPrograms,
  };
}

interface FormPayload extends Partial<Omit<IPatient, 'billing' | 'programs'>> {
  billing: {
    financialAidStatus: FinancialAidStatus | null;
    financialAidApprovedAt: string | null;
    type: BillingType;
  };
  clinicalNote?: string;
  programs: {
    diabetes: {
      enrolled: boolean;
    };
    hypertension: {
      enrolled: boolean;
    };
    weight: {
      enrolled: boolean;
    };
  };
}

export function formatFormValues(values: IFormValues): FormPayload {
  const dateOfBirth = dayjs(values.dateOfBirth).format('YYYY-MM-DD');

  const diabetesEnrolled = values.selectedPrograms?.includes(
    PatientPrograms.DIABETES,
  );
  const hypertensionEnrolled = values.selectedPrograms?.includes(
    PatientPrograms.HYPERTENSION,
  );
  const weightEnrolled = values.selectedPrograms.includes(
    PatientPrograms.WEIGHT,
  );

  return {
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    dateOfBirth,
    sexAtBirth: values.sexAtBirth as TSexAtBirth,
    email: values.email.trim(),
    phoneNumber: values.phoneNumber,
    address: {
      lineOne: values.addressLineOne,
      lineTwo: values.addressLineTwo,
      city: values.addressCity,
      state: values.addressState,
      zipCode: values.addressZipCode,
    },
    billing: {
      financialAidStatus: values.financialAidStatus,
      financialAidApprovedAt: values.financialAidApprovedAt?.toJSON() || null,
      type: values.billingType,
    },
    clinicalNote: values.clinicalNote,
    communicationPreference: values.communicationPreference,
    programs: {
      diabetes: {
        enrolled: diabetesEnrolled,
      },
      hypertension: {
        enrolled: hypertensionEnrolled,
      },
      weight: {
        enrolled: weightEnrolled,
      },
    },
  };
}

export function validateContactInformation(values: IFormValues): boolean {
  const { email, phoneNumber } = values;

  if (!email || !phoneNumber) {
    return false;
  }

  return true;
}
