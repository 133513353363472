import { Grid, Box, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import DaysInStage from './DaysInStage';
import DistanceFromGoal from './DistanceFromGoal';
import { PatientName, ProviderAvatar } from '../../../components';
import { formatDate } from '../../../utils';
import { IPatient } from '../../../types';
import { Link } from 'react-router-dom';
import LastPhoneOutreach from './LastPhoneOutreach';

interface Props {
  isActive: boolean;
  patient: IPatient;
}

export default function ListItem({ isActive, patient }: Props) {
  const [searchParams] = useSearchParams();
  const { careTeamLead, dateOfBirth, uid } = patient;
  const dob = formatDate(dateOfBirth);
  const { adminId: assignedMdId, display: assignedMdName } = careTeamLead ?? {};

  return (
    <Grid
      as={Link}
      to={`/up-next/${uid}?${searchParams.toString()}`}
      py={3}
      px={6}
      bg={isActive ? 'blue.50' : ''}
      borderBottom="1px solid"
      borderColor="gray.200"
      position="relative"
      _hover={{ bg: 'blue.50' }}
    >
      {isActive && (
        <Box
          h="100%"
          bg="blue.500"
          position="absolute"
          w={1}
          top={0}
          left={0}
        />
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={6}
        mb={3}
      >
        <Box>
          <PatientName patient={patient} />
          <Text fontSize="xs" color="blackAlpha.800">
            {dob}
          </Text>
        </Box>

        <ProviderAvatar
          size="xs"
          providerId={assignedMdId}
          providerName={assignedMdName}
        />
      </Box>

      <Box alignItems="center" display="flex" justifyContent="space-between">
        <DistanceFromGoal patient={patient} />

        <Box
          alignItems="center"
          color="blackAlpha.500"
          display="flex"
          fontSize="xs"
          gap={2}
        >
          <LastPhoneOutreach patient={patient} />
          {'|'}
          <DaysInStage patient={patient} />
        </Box>
      </Box>
    </Grid>
  );
}
