import { Box, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import DatePicker from 'react-date-picker';
import { Calendar } from '../../assets';
import { FinancialAidStatus } from '../../types';

interface Props {
  onFinancialAidStatusChange: (value: FinancialAidStatus | null) => void;
  onFinancialAidApprovedAtChange: (value: Date | null) => void;
  financialAidStatus: FinancialAidStatus | null;
  financialAidApprovedAt: Date | null;
}

export default function FinancialAidSelect({
  onFinancialAidStatusChange,
  onFinancialAidApprovedAtChange,
  financialAidStatus,
  financialAidApprovedAt,
}: Props): JSX.Element {
  return (
    <Box>
      <FormControl>
        <FormLabel>Financial assistance status</FormLabel>

        <Select
          value={financialAidStatus || FinancialAidStatus.NOT_APPLICABLE}
          onChange={({ currentTarget }) =>
            onFinancialAidStatusChange(
              currentTarget.value as FinancialAidStatus,
            )
          }
        >
          <option value={FinancialAidStatus.NOT_APPLICABLE}>
            Not Applicable
          </option>
          <option value={FinancialAidStatus.APPLICATION_SENT}>
            Application Sent
          </option>
          <option value={FinancialAidStatus.APPROVED}>Approved</option>
          <option value={FinancialAidStatus.DENIED}>Denied</option>
        </Select>
      </FormControl>

      {financialAidStatus === FinancialAidStatus.APPROVED && (
        <FormControl mt={4}>
          <FormLabel>Financial assistance approval date</FormLabel>
          <Input
            onChange={(date) => {
              if (!date) {
                onFinancialAidApprovedAtChange(null);
                return;
              }

              onFinancialAidApprovedAtChange(date as unknown as Date);
            }}
            // @ts-ignore
            value={financialAidApprovedAt}
            placeholder="YYYY-MM-DD"
            as={DatePicker}
            openCalendarOnFocus={false}
            calendarIcon={<Calendar />}
            clearIcon={null}
            showLeadingZeros={true}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
          />
        </FormControl>
      )}
    </Box>
  );
}
