import { NavLink } from 'react-router-dom';
import { Box, Link, Text } from '@chakra-ui/react';
import { Calendar, Megaphone, Users, Trending, Compass } from '../../assets';

type TPath = 'today' | 'broadcasts' | 'patients' | 'trending' | 'up-next';

interface IProps {
  path: TPath;
}

export default function NavigationItem({ path, ...rest }: IProps): JSX.Element {
  const { Icon, text } = optionsByPath[path];

  return (
    <Link
      as={NavLink}
      to={`/${path}`}
      color="gray.200"
      display="block"
      margin="auto"
      textAlign="center"
      rounded="lg"
      _activeLink={{
        color: 'white',
        '& > div': {
          background: 'gray.600',
        },
      }}
      _hover={{
        '& > div': {
          background: 'gray.600',
        },
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        h={10}
        margin="auto"
        justifyContent="center"
        rounded="lg"
        w={10}
      >
        <Icon fontSize="xl" />
      </Box>

      <Text fontSize="2xs" fontWeight="medium">
        {text}
      </Text>
    </Link>
  );
}

const optionsByPath: Record<TPath, { Icon: any; text: string }> = {
  today: {
    Icon: Calendar,
    text: 'Today',
  },
  broadcasts: {
    Icon: Megaphone,
    text: 'Broadcasts',
  },
  patients: {
    Icon: Users,
    text: 'Patients',
  },
  trending: {
    Icon: Trending,
    text: 'Trending',
  },
  'up-next': {
    Icon: Compass,
    text: 'Up Next',
  },
};
