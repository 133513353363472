import { Button, Link } from '@chakra-ui/react';
import { TExternalLinkDestination } from '../../analytics';
import { handleExternalLinkClick } from '../../analytics/utils';

export default function ExternalLinkButton({
  text,
  linkUrl,
  mixpanelDestination,
}: {
  text: string;
  linkUrl: string;
  mixpanelDestination: TExternalLinkDestination;
}) {
  return (
    <Button
      _hover={{ textDecoration: 'none', bg: 'gray.100' }}
      px={2}
      height="min-content"
      py={1.5}
      fontSize="xs"
      as={Link}
      variant="outline"
      href={linkUrl}
      isExternal
      target="_blank"
      onClick={() => {
        handleExternalLinkClick({
          context: 'Patient',
          destination: mixpanelDestination,
        });
      }}
    >
      {text}
    </Button>
  );
}
