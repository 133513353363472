import { Box, Text } from '@chakra-ui/react';
import { IBroadcast } from '../../../types';
import BroadcastsListRow from './BroadcastsListRow';

export const BROADCAST_LIST_TEMPLATE_COLUMNS = '7fr 1fr 1.5fr 1fr';

export default function BroadcastsList({
  broadcasts,
  onSelectBroadcast,
}: {
  broadcasts: IBroadcast[];
  onSelectBroadcast: (broadcastId: string) => void;
}): JSX.Element {
  return (
    <Box>
      <Box
        color="gray.600"
        display="grid"
        gridTemplateColumns={BROADCAST_LIST_TEMPLATE_COLUMNS}
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        borderBottom="1px solid"
        borderColor="gray.200"
        alignItems="center"
        px={6}
        py={4}
        gap={4}
      >
        <Text>Broadcast</Text>
        <Text textAlign="right"># Patients</Text>
        <Text textAlign="right">Created By</Text>
        <Text textAlign="right">Status</Text>
      </Box>
      <Box h="calc(100vh - 107px)" overflow="scroll">
        {broadcasts.map((broadcast) => (
          <BroadcastsListRow
            onSelectBroadcast={onSelectBroadcast}
            key={broadcast.id}
            broadcast={broadcast}
          />
        ))}
      </Box>
    </Box>
  );
}
