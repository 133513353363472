import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import {
  BroadcastStatus,
  IBroadcast,
  IBroadcastAttachment,
} from '../../../types';

interface IBroadCastDraftParams {
  attachments: IBroadcastAttachment[];
  messageBody: string;
  patientUids: string[];
  providerUid: string;
  threadSubject: string;
}

export async function createBroadcastDraft({
  attachments,
  messageBody,
  patientUids,
  providerUid,
  threadSubject,
}: IBroadCastDraftParams): Promise<string> {
  const broadcastsCollection = collection(db, 'broadcasts');
  const broadcastData: Omit<IBroadcast, 'id'> = {
    attachments: attachments,
    createdAt: Timestamp.now(),
    createdBy: providerUid,
    errorsByPatientUid: [],
    messageBody,
    patientUids,
    status: BroadcastStatus.DRAFT,
    threadSubject,
  };

  const broadcastDoc = await addDoc(broadcastsCollection, broadcastData);

  return broadcastDoc.id;
}
