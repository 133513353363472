import { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { Box, Button, useToast } from '@chakra-ui/react';
import { Events, mixpanel } from '../../../../analytics';
import { CheckCircle } from '../../../../assets';
import { db } from '../../../../firebase';
import { ITask, TaskStatus } from '../../../../types';

interface IProps {
  onUpdateTask: (task: ITask) => void;
  task: ITask;
}

export default function TaskStatusManager({
  onUpdateTask,
  task,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taskStatus, setTaskStatus] = useState<TaskStatus>();
  const toast = useToast();

  useEffect(() => {
    setTaskStatus(task.status);
  }, [task]);

  async function handleMarkTaskAsDone(): Promise<void> {
    setIsLoading(true);

    try {
      const taskRef = doc(db, `tasks/${task.id}`);

      await updateDoc(taskRef, {
        status: TaskStatus.CLOSED,
      } as Pick<ITask, 'status'>);

      setTaskStatus(TaskStatus.CLOSED);
      onUpdateTask(task);

      mixpanel.track(Events.TASK_COMPLETED, {
        task_type: 'Blood Pressure Out of Range',
      });

      toast({
        description: 'Task marked as done.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description: 'Error marking task as done.',
        status: 'error',
        title: 'Uh oh!',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleReopenTask(): Promise<void> {
    setIsLoading(true);

    try {
      const taskRef = doc(db, `tasks/${task.id}`);

      await updateDoc(taskRef, {
        status: TaskStatus.OPEN,
      } as Pick<ITask, 'status'>);

      setTaskStatus(TaskStatus.OPEN);
      onUpdateTask(task);

      mixpanel.track(Events.TASK_REOPENED, {
        task_type: 'Blood Pressure Out of Range',
      });

      toast({
        description: 'Task reopened.',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        title: 'Success!',
        variant: 'left-accent',
      });
    } catch (error) {
      toast({
        description: 'Error reopening task.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
        title: 'Uh oh!',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      alignItems="center"
      borderTop="1px solid"
      borderColor="gray.200"
      display="flex"
      p={2}
    >
      {taskStatus === TaskStatus.OPEN && (
        <Button
          variant="outline"
          onClick={handleMarkTaskAsDone}
          isLoading={isLoading}
          leftIcon={<CheckCircle />}
        >
          Mark done
        </Button>
      )}

      {taskStatus === TaskStatus.CLOSED && (
        <>
          <Button
            variant="outline"
            onClick={handleReopenTask}
            isLoading={isLoading}
          >
            Re-open task
          </Button>
        </>
      )}
    </Box>
  );
}
