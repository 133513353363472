import { Box, Skeleton, Stack } from '@chakra-ui/react';

export default function TableLoader(): JSX.Element {
  return (
    <Stack>
      <Box pt={8} pb={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>

      <Box py={4} px={6} display="flex" alignItems="center" gap={4}>
        <Skeleton h={3} w="100%" />
      </Box>
    </Stack>
  );
}
