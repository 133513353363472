import { useCallback, useEffect } from 'react';
import { IPanelSection } from '..';

interface IProps {
  verticalScrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  horizontalScrollTabBarRef: React.MutableRefObject<HTMLDivElement | null>;
  sectionInFocus: IPanelSection | null;
  setSectionInFocus: React.Dispatch<React.SetStateAction<IPanelSection | null>>;
  intersectingSection: IPanelSection | null;
  sectionToScrollTo: IPanelSection | null;
  setSectionToScrollTo: React.Dispatch<
    React.SetStateAction<IPanelSection | null>
  >;
}

export default function useHandlePanelSectionNavigation({
  verticalScrollContainerRef,
  horizontalScrollTabBarRef,
  sectionInFocus,
  setSectionInFocus,
  intersectingSection,
  sectionToScrollTo,
  setSectionToScrollTo,
}: IProps) {
  // function to scroll vertically to top-align the selected section
  const scrollToVerticalSection = useCallback(
    (section: IPanelSection) => {
      const parent = verticalScrollContainerRef.current;
      const child = section.ref;
      if (parent && child) {
        // add additional offset so section header is visible
        const scrollTop = child.offsetTop - parent.offsetTop - 35;
        parent.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }
    },
    [verticalScrollContainerRef],
  );

  // function to scroll horizontally to center the selected tab
  const scrollToHorizontalTab = useCallback(
    (section: IPanelSection) => {
      const parent = horizontalScrollTabBarRef.current;
      const child = section.tabRef.current;
      if (parent && child) {
        const scrollLeft =
          child.offsetLeft - (parent.offsetWidth - child.offsetWidth) / 2;
        parent.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    },
    [horizontalScrollTabBarRef],
  );

  // the intersection observer needs a separate state to recognize which section we're passing through
  // versus setting the section we want to scroll to when clicking a tab
  // if they share the same state, a tab click will start scrolling to the specified section but the sections
  // we pass along the way will trigger the intersection observer to update the focused tab, which will then
  // cause the scroll to stop at that section
  useEffect(() => {
    // if a user clicks on a tab, trigger programmatic vertical scrolling to that section
    // without allowing the intersection observer to intervene and cause scroll jank
    if (sectionToScrollTo) {
      setSectionInFocus(sectionToScrollTo);
      scrollToHorizontalTab(sectionToScrollTo);
      scrollToVerticalSection(sectionToScrollTo);
    }
  }, [
    scrollToHorizontalTab,
    scrollToVerticalSection,
    sectionToScrollTo,
    setSectionInFocus,
  ]);

  useEffect(() => {
    // only update focused tab is vertical scrolling is user-generated
    // & do not trigger programmatic vertical scroll
    if (intersectingSection && !sectionToScrollTo) {
      setSectionInFocus(intersectingSection);
      scrollToHorizontalTab(intersectingSection);
    }
  }, [
    intersectingSection,
    scrollToHorizontalTab,
    sectionToScrollTo,
    setSectionInFocus,
  ]);

  // after a user clicks a tab, reset the sectionToScrollTo back to null once target section has been reached
  // so that intersection observer can trigger the appropriate logic on user-initiated scroll
  useEffect(() => {
    if (intersectingSection?.title === sectionToScrollTo?.title) {
      setSectionToScrollTo(null);
    }
  }, [intersectingSection, sectionToScrollTo?.title, setSectionToScrollTo]);

  return { sectionInFocus };
}
