import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { IPatient } from '../types';

export default function useFetchPatients(): [IPatient[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patients, setPatients] = useState<IPatient[]>([]);

  useEffect(() => {
    async function fetchPatients() {
      const querySnapshot = await getDocs(collection(db, 'patients'));
      const patients: IPatient[] = [];

      querySnapshot.forEach((doc) => {
        patients.push({
          uid: doc.id,
          ...doc.data(),
        } as IPatient);
      });

      setPatients(patients);
      setIsLoading(false);
    }

    fetchPatients();
  }, []);

  return [patients, isLoading];
}
