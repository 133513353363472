import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import SearchResults from './SearchResults';
import { useFetchPatients } from '../../../../../hooks';
import { IPatient } from '../../../../../types';
import { Search } from '../../../../../assets';
import SelectedPatients from './SelectedPatients';

interface IProps {
  setPatientUids: (patientUids: string[]) => void;
}

export default function ManualPatientSelection({
  setPatientUids,
}: IProps): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [results, setResults] = useState<IPatient[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<IPatient[]>([]);
  const [patients] = useFetchPatients();

  useEffect(() => {
    return () => {
      setResults([]);
      setSearchValue('');
      setSelectedPatients([]);
    };
  }, []);

  useEffect(() => {
    const selectedPatientUids = selectedPatients.map((patient) => patient.uid);

    setPatientUids(selectedPatientUids);
  }, [selectedPatients, setPatientUids]);

  function handleSearch({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    const { value } = currentTarget;

    setSearchValue(value);

    if (!value.trim()) {
      setResults([]);
      return;
    }

    const results = patients.filter((patient) => {
      const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();

      return fullName.includes(value.toLowerCase());
    });

    setResults(results);
  }

  function handleSelectSearchResult(patientUid: string) {
    setResults([]);
    setSearchValue('');

    if (selectedPatients.find((patient) => patient.uid === patientUid)) {
      return;
    }

    const patient = patients.find((patient) => patient.uid === patientUid);

    if (!patient) {
      return;
    }

    setSelectedPatients([...selectedPatients, patient]);
  }

  function handleRemovePatient(patientUid: string) {
    const updatedPatients = selectedPatients.filter(
      (patient) => patient.uid !== patientUid,
    );

    setSelectedPatients(updatedPatients);
  }

  const showSearchResults = results.length > 0 || searchValue.trim().length > 0;

  return (
    <Box>
      <FormControl isRequired mb={4}>
        <FormLabel>Search Patients</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Search />
          </InputLeftElement>
          <Input
            placeholder="Patient Name"
            onChange={handleSearch}
            value={searchValue}
          />
        </InputGroup>
      </FormControl>

      <Box>
        <Box position="relative">
          {showSearchResults && (
            <SearchResults
              patients={results}
              onSelectSearchResult={handleSelectSearchResult}
            />
          )}
        </Box>

        <SelectedPatients
          onRemovePatient={handleRemovePatient}
          patients={selectedPatients}
        />
      </Box>
    </Box>
  );
}
