import { Timestamp } from 'firebase/firestore';
import { Box, Text } from '@chakra-ui/react';
import { dayjs } from '../../../utils';
import { DataFigureGridItem } from '../../../components';
import { useFetchPatientGlucoseReadings } from '../../../hooks';

interface IProps {
  patientUid?: string;
}

function formatReadingDate(timestamp?: Timestamp): string {
  if (!timestamp) {
    return 'N/A';
  }

  return dayjs(timestamp.toDate()).format('MM-DD-YYYY');
}

const twoWeeksAgo = dayjs().subtract(14, 'day').startOf('day');
const twoWeeksAgoDate = twoWeeksAgo.toDate();

export default function GlucoseSnapshot({ patientUid }: IProps): JSX.Element {
  const [glucoseReadings, isLoading] = useFetchPatientGlucoseReadings(
    patientUid,
    twoWeeksAgoDate,
  );
  const latestReading = glucoseReadings[0];

  return (
    <Box p={4}>
      <Text mb={2} fontWeight="semibold">
        Glucose Snapshot
      </Text>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr minmax(max-content, 1fr)"
        gap={4}
      >
        <DataFigureGridItem
          isLoading={isLoading}
          label="Last Reading"
          subtext={formatReadingDate(latestReading?.readingTime)}
        >
          {latestReading?.value ? `${latestReading.value} mg/dl` : 'N/A'}
        </DataFigureGridItem>

        <DataFigureGridItem
          isLoading={isLoading}
          label="Readings Over 14 Days"
          subtext={`Since ${twoWeeksAgo.format('MM-DD-YYYY')}`}
        >
          {`${glucoseReadings?.length}`}
        </DataFigureGridItem>
      </Box>
    </Box>
  );
}
