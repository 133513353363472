import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import MenuDropdown from './MenuDropdown';
import { mixpanel, Events, TLinkCopiedDestination } from '../../../analytics';
import { Close, Copy } from '../../../assets';
import { IPatient } from '../../../types';
import { HEADER_CONTAINER_HEIGHT } from '../../../pages/TodayPage';

interface IProps {
  onClose?: () => void;
  onUpdate: () => Promise<void>;
  patient?: IPatient;
}

export default function PatientDetailsPanelHeader({
  onClose,
  onUpdate,
  patient,
}: IProps) {
  const [tooltip, setTooltip] = useState<boolean>(false);

  async function handleCopyLinkText(
    linkText: string,
    destination: TLinkCopiedDestination,
  ) {
    await navigator.clipboard.writeText(linkText);
    mixpanel.track(Events.LINK_COPIED, {
      destination,
    });
  }

  return (
    <Box
      h={HEADER_CONTAINER_HEIGHT}
      borderLeft="1px solid"
      borderBottom="1px solid"
      borderColor="gray.200"
      display="flex"
      justifyContent={onClose ? 'space-between' : 'flex-end'}
      alignItems="center"
      px={6}
    >
      {onClose && (
        <IconButton
          onClick={onClose}
          isRound
          size="sm"
          aria-label="close"
          fontSize={16}
          icon={<Close />}
        />
      )}
      {patient && (
        <Box display="flex" gap={1}>
          <Tooltip
            closeDelay={350}
            label="Copied"
            isOpen={tooltip}
            hasArrow
            onClose={() => setTooltip(false)}
            rounded="md"
          >
            <IconButton
              aria-label="copy URL"
              size="sm"
              variant="ghost"
              onClick={() => {
                handleCopyLinkText(window.location.href, 'Patient');
                setTooltip(true);
              }}
              icon={<Copy />}
            />
          </Tooltip>
          <MenuDropdown
            onCopyLinkText={handleCopyLinkText}
            onUpdate={onUpdate}
            patient={patient}
          />
        </Box>
      )}
    </Box>
  );
}
