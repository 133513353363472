import { Box, Radio, RadioGroup } from '@chakra-ui/react';
import { CommunicationPreference } from '../../types';

interface IProps {
  id?: string;
  name?: string;
  onChange: (preference: CommunicationPreference) => void;
  value: CommunicationPreference;
}

export default function CommunicationPreferenceInput({
  id,
  name,
  onChange,
  value,
}: IProps): JSX.Element {
  return (
    <RadioGroup
      id={id}
      name={name}
      display="flex"
      flexDir="column"
      gap={1}
      value={value}
      onChange={onChange}
    >
      <Radio value={CommunicationPreference.SMS}>
        <Box textTransform="uppercase">{CommunicationPreference.SMS}</Box>
      </Radio>
      <Radio value={CommunicationPreference.EMAIL}>
        <Box textTransform="capitalize">{CommunicationPreference.EMAIL}</Box>
      </Radio>
    </RadioGroup>
  );
}
