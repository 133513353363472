import { Box, Text } from '@chakra-ui/react';
import { IPatientTrend } from '../../../../types';
import { isNegativeInt } from '../../../Trending/BPDeltaStat';

interface IProps {
  patientTrend: IPatientTrend;
}

export default function TrendOverview({ patientTrend }: IProps): JSX.Element {
  const { change } = patientTrend.trend;

  return (
    <Box mb={8} display="flex" flexDirection="column" alignItems="flex-start">
      <Text
        color={change.type === 'worse' ? 'red.600' : 'green.600'}
        fontSize="2xl"
        fontWeight="semibold"
      >
        Systolic avg. {Math.abs(change.systolic)}mmHg{' '}
        {isNegativeInt(change.systolic) ? 'lower' : 'higher'} than last week
      </Text>
    </Box>
  );
}
