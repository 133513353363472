import { Box, Select, Text } from '@chakra-ui/react';
import { IPatientTrend, PatientTrendTimePeriod } from '../../../../types';

interface IProps {
  patientTrends?: IPatientTrend[];
  setTrendPeriod: React.Dispatch<React.SetStateAction<PatientTrendTimePeriod>>;
  trendPeriod: PatientTrendTimePeriod;
}

export default function TrendHeader({
  patientTrends,
  trendPeriod,
  setTrendPeriod,
}: IProps) {
  function handleTrendPeriodChange({
    currentTarget,
  }: React.ChangeEvent<HTMLSelectElement>) {
    setTrendPeriod(currentTarget.value as PatientTrendTimePeriod);
  }

  const hasWeeklyTrendsData = patientTrends?.find(
    (t) => t.trend.period === PatientTrendTimePeriod.WEEKLY,
  );
  const hasMonthlyTrendsData = patientTrends?.find(
    (t) => t.trend.period === PatientTrendTimePeriod.MONTHLY,
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Text
        textTransform="uppercase"
        fontSize="sm"
        fontWeight="bold"
        color="blackAlpha.600"
      >
        Rolling {trendPeriod} BP avg. Trend
      </Text>
      <Select
        onChange={handleTrendPeriodChange}
        value={trendPeriod}
        size="sm"
        rounded="md"
        w="inherit"
      >
        {hasWeeklyTrendsData && (
          <option value={PatientTrendTimePeriod.WEEKLY}>Weekly</option>
        )}
        {hasMonthlyTrendsData && (
          <option value={PatientTrendTimePeriod.MONTHLY}>Monthly</option>
        )}
      </Select>
    </Box>
  );
}
