import { Box, Text } from '@chakra-ui/react';
import { formatDate } from '../../../utils';
import { FinancialAidStatus, IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function PaymentInfo({ patient }: Props): JSX.Element {
  const { billing } = patient;

  return (
    <Box>
      <Text fontWeight="medium" mb={4}>
        Payment Info
      </Text>

      <Box
        alignItems="center"
        border="1px solid"
        borderColor="blackAlpha.200"
        display="grid"
        gridTemplateColumns="max-content auto"
        gridColumnGap={10}
        gridRowGap={2}
        rounded="md"
        p={4}
      >
        <Text color="blackAlpha.700" fontSize="sm">
          Billing Type
        </Text>
        <Text textTransform="capitalize">{billing?.type || '-'}</Text>
        <Text color="blackAlpha.700" fontSize="sm">
          Financial Aid Status
        </Text>
        <Text textTransform="capitalize">
          {billing?.financialAidStatus?.split('_').join(' ') ||
            'Not Applicable'}
        </Text>
        {billing?.financialAidStatus === FinancialAidStatus.APPROVED && (
          <>
            <Text color="blackAlpha.700" fontSize="sm">
              Financial Aid Approval Date
            </Text>
            <Text textTransform="capitalize">
              {formatDate(billing?.financialAidApprovedAt?.toDate()) || '-'}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
}
