import { Box, Skeleton, Text } from '@chakra-ui/react';

interface IProps {
  children: React.ReactNode;
  isLoading: boolean;
  subtext?: string;
}

export default function DataFigure({ children, isLoading, subtext }: IProps) {
  return (
    <Box>
      <Skeleton isLoaded={!isLoading}>
        <Text fontSize="3xl" fontWeight="semibold">
          {children}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        <Text fontSize="sm" color="blackAlpha.600">
          {subtext}
        </Text>
      </Skeleton>
    </Box>
  );
}
