import { Reading, ReadingType } from '.';

export function collectionNameByType(readingType: ReadingType): string {
  switch (readingType) {
    case 'bloodPressure':
      return 'readingBloodPressures';
    case 'bloodGlucose':
      return 'readingBloodGlucoses';
    default:
      throw new Error('Invalid reading type');
  }
}

export function getColumnsByType(readingType: ReadingType): string[] {
  switch (readingType) {
    case 'bloodPressure':
      return ['Syst.', 'Diast.', 'HR'];
    case 'bloodGlucose':
      return ['mg/dL'];
    default:
      throw new Error('Invalid reading type');
  }
}

export function getReadingValuesByType(
  reading: Reading,
  readingType: ReadingType,
): (string | number | null | undefined)[] {
  switch (readingType) {
    case 'bloodPressure':
      return [reading.systolic, reading.diastolic, reading.heartRate];
    case 'bloodGlucose':
      return [reading.value];
    default:
      throw new Error('Invalid reading type');
  }
}
