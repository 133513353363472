import { useState } from 'react';
import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  PatientDetailsPanelColumn,
  PatientForm,
} from '../../components';
import PatientSearch from './PatientSearch';
import PatientsTable from './PatientsTable';
import { Plus } from '../../assets';
import { IClientFilters, PatientFilterStatus } from '../../utils/patientUtils';
import { ProviderIds, PROVIDERS_LIST } from '../../constants';

export default function PatientsPage(): JSX.Element {
  const [clientFilters, setClientFilters] = useState<IClientFilters>({
    state: 'all',
    status: PatientFilterStatus.All,
  });
  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: onFormClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const { uid: patientUid } = useParams();

  const [selectedProviderIds, setSelectedProviderIds] = useState<ProviderIds[]>(
    PROVIDERS_LIST.map((p) => p.id),
  );

  function handleClientFiltersChange(filterValue: { [key: string]: string }) {
    setClientFilters({
      ...clientFilters,
      ...filterValue,
    });
  }

  function handleClearFilters() {
    setClientFilters({
      status: PatientFilterStatus.All,
      state: 'all',
    });
    setSelectedProviderIds(PROVIDERS_LIST.map((p) => p.id));
  }

  function handleClose() {
    navigate('/patients');
  }

  return (
    <Layout>
      <PatientForm isOpen={isFormOpen} onClose={onFormClose} />

      <Box display="grid" gridTemplateColumns={`auto ${patientUid && '600px'}`}>
        <Box>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.200"
            display="flex"
            h={14}
            justifyContent="space-between"
            px={6}
            py={3}
          >
            <Box display="flex" alignItems="center" gap={4}>
              <Heading fontSize={24} fontWeight="extrabold">
                Patients
              </Heading>

              <PatientSearch />
            </Box>

            <Button
              leftIcon={<Plus />}
              onClick={onFormOpen}
              size="sm"
              colorScheme="blue"
            >
              Add Patient
            </Button>
          </Box>

          <PatientsTable
            clientFilters={clientFilters}
            onClientFiltersChange={handleClientFiltersChange}
            onClearFilters={handleClearFilters}
            selectedProviderIds={selectedProviderIds}
            setSelectedProviderIds={setSelectedProviderIds}
          />
        </Box>

        {patientUid && (
          <Box boxShadow="2xl">
            <PatientDetailsPanelColumn
              context="Patients"
              patientUid={patientUid}
              onClose={handleClose}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}
