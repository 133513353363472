import { Box, Text } from '@chakra-ui/react';
import PatientRow from '../PatientRow';
import { IPatient } from '../../../../../../types';

interface IProps {
  onSelectSearchResult: (patientUid: string) => void;
  patients: IPatient[];
}

export default function SearchResults({
  onSelectSearchResult,
  patients,
}: IProps): JSX.Element {
  return (
    <Box
      position="absolute"
      bg="white"
      rounded="md"
      shadow="md"
      w="full"
      zIndex={1}
    >
      <Box
        borderBottom="1px solid"
        borderColor="gray.200"
        color="gray.600"
        display="grid"
        fontSize="xs"
        fontWeight="bold"
        gridTemplateColumns="1.5fr .75fr 1fr .5fr"
        overflow="auto"
        p={3}
        px={4}
        textTransform="uppercase"
      >
        <Box>Patient Name</Box>
        <Box>DOB</Box>
        <Box>Start Date</Box>
        <Box />
      </Box>

      {patients.map((patient, idx) => (
        <Box
          key={patient.uid}
          onClick={() => onSelectSearchResult(patient.uid)}
        >
          <PatientRow
            hasBorder={idx !== patients.length - 1}
            patient={patient}
          />
        </Box>
      ))}

      {patients.length === 0 && (
        <Box p={8} textAlign="center">
          <Text fontWeight="medium">No Results Found</Text>
          <Text color="gray.600" fontSize="sm">
            Try adjusting your search.
          </Text>
        </Box>
      )}
    </Box>
  );
}
