import { Box, Text } from '@chakra-ui/react';
import UpdateClinicalStateButton from './UpdateClinicalStateButton';
import MedicationBlockerButton from './MedicationBlockerButton';
import Notes from './Notes';
import { getNextState, isStateChangeEnabled } from './utils';
import { formatReviewStateName } from '../../../utils';
import { ClinicalReviewStates, IPatient } from '../../../types';
import SnoozeButton from './SnoozeButton';

interface Props {
  onUpdate: () => void;
  patient: IPatient;
}

export default function SpotlightActions({
  onUpdate,
  patient,
}: Props): JSX.Element | null {
  const { clinicalReviewState } = patient;
  const currentState = clinicalReviewState.state;

  if (currentState === ClinicalReviewStates.NOT_APPLICABLE) {
    return null;
  }

  const stateChangeEnabled = isStateChangeEnabled(currentState);
  const nextState = getNextState(currentState);

  return (
    <Box
      bg="gray.50"
      borderLeft="1px solid"
      borderTop="1px solid"
      borderColor="gray.200"
      bottom={0}
      left={0}
      position="absolute"
      p={6}
      w="calc(100% - 16px)"
    >
      <Text mb={4} fontWeight="medium">
        Clinical Stage
      </Text>

      <Box
        border="1px solid"
        borderColor="gray.200"
        bg="white"
        display="grid"
        gridTemplateColumns="max-content auto"
        gridColumnGap={10}
        gridRowGap={2}
        mb={4}
        p={4}
        rounded="md"
      >
        <Box color="blackAlpha.700" fontSize="sm">
          Current stage
        </Box>{' '}
        <Box textTransform="capitalize">
          {formatReviewStateName(clinicalReviewState.state)}
        </Box>
        <Box color="blackAlpha.700" fontSize="sm">
          Next stage(s)
        </Box>{' '}
        <Box textTransform="capitalize">{formatReviewStateName(nextState)}</Box>
      </Box>

      <Box mb={4}>
        <Notes patient={patient} />
      </Box>

      {stateChangeEnabled && (
        <>
          <Text mb={4} fontWeight="medium">
            Actions
          </Text>

          <Box display="flex" gap={4}>
            <UpdateClinicalStateButton patient={patient} onUpdate={onUpdate} />

            <MedicationBlockerButton patient={patient} onUpdate={onUpdate} />

            <SnoozeButton patient={patient} onUpdate={onUpdate} />
          </Box>
        </>
      )}
    </Box>
  );
}
