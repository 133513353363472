import { ClinicalReviewStates } from '../types';

export enum Events {
  EXTERNAL_LINK_CLICKED = 'External Link Clicked',
  LINK_COPIED = 'Link Copied',
  MESSAGE_SENT = 'Message Sent',
  PATIENT_CLINICAL_REVIEW_MEDICATION_BLOCKER_CLEARED = 'Patient Clinical Review Medication Blocker Cleared',
  PATIENT_CLINICAL_REVIEW_STATE_CHANGED = 'Patient Clinical Review State Changed',
  PATIENT_CLINICAL_REVIEW_STATE_SNOOZED = 'Patient Clinical Review State Snoozed',
  PATIENT_CREATED = 'Patient Created',
  PATIENT_DEACTIVATED = 'Patient Deactivated',
  PATIENT_EDITED = 'Patient Edited',
  PATIENT_ENROLLED = 'Patient Enrolled',
  PATIENT_REACTIVATED = 'Patient Reactivated',
  PATIENT_SECTION_VIEWED = 'Patient Section Viewed',
  PATIENT_VIEWED = 'Patient Viewed',
  PATIENTS_FILTERED = 'Patients Filtered',
  PATIENTS_SEARCHED = 'Patients Searched',
  SNIPPET_COPIED = 'Snippet Copied',
  SPOTLIGHT_PATIENTS_FILTERED = 'Spotlight Patients Filtered',
  TASK_COMPLETED = 'Task Completed',
  TASK_REOPENED = 'Task Reopened',
  TASK_VIEWED = 'Task Viewed',
  TASKS_FILTERED = 'Tasks Filtered',
  TRENDS_FILTERED = 'Trends Filtered',
  USER_LOGGED_IN = 'User Logged In',
  USER_LOGGED_OUT = 'User Logged Out',
}

export type TExternalLinkDestination =
  | 'Canvas'
  | 'Customer.io'
  | 'Impilo'
  | 'Source'
  | 'Stripe';

export type TLinkCopiedDestination = 'Patient' | 'Offboarding Survey';

export interface EventProperties {
  [Events.EXTERNAL_LINK_CLICKED]: {
    destination: TExternalLinkDestination;
    context: 'Patient' | 'Task';
    subcontext?:
      | 'Patient: Clinical Reviews'
      | 'Patient: Blood Pressure Readings'
      | 'Patient: Recent Activity';
  };
  [Events.LINK_COPIED]: {
    destination: TLinkCopiedDestination;
  };
  [Events.MESSAGE_SENT]: {
    context: 'Task';
    template: MessageTemplates;
  };
  [Events.PATIENT_CLINICAL_REVIEW_MEDICATION_BLOCKER_CLEARED]: {
    current_state: ClinicalReviewStates;
  };
  [Events.PATIENT_CLINICAL_REVIEW_STATE_CHANGED]: {
    current_state: ClinicalReviewStates;
    new_state: ClinicalReviewStates;
    days_in_state: number;
  };
  [Events.PATIENT_CLINICAL_REVIEW_STATE_SNOOZED]: null;
  [Events.PATIENT_CREATED]: null;
  [Events.PATIENT_DEACTIVATED]: null;
  [Events.PATIENT_EDITED]: null;
  [Events.PATIENT_ENROLLED]: null;
  [Events.PATIENT_REACTIVATED]: null;
  [Events.PATIENT_SECTION_VIEWED]: {
    section:
      | 'clinical_reviews'
      | 'bp_readings'
      | 'weight_readings'
      | 'glucose_readings'
      | 'recent_activity'
      | 'personal_info'
      | 'clinic_info'
      | 'payment_info';
  };
  [Events.PATIENT_VIEWED]: {
    context: 'Patients' | 'Tasks' | 'Trends' | 'Spotlight' | 'Broadcast';
  };
  [Events.PATIENTS_FILTERED]: {
    filter_type: 'Provider' | 'Status' | 'State' | 'Clear filters';
  };
  [Events.PATIENTS_SEARCHED]: null;
  [Events.SNIPPET_COPIED]: {
    snippet_name: TSnippetName;
  };
  [Events.SPOTLIGHT_PATIENTS_FILTERED]: {
    clinical_review_state: ClinicalReviewStates;
    provider_ids?: string[];
    priority?:
      | 'days_in_stage'
      | 'distance_from_diastolic_goal'
      | 'distance_from_systolic_goal'
      | 'last_phone_outreach';
  };
  [Events.TASK_COMPLETED]: {
    resolution: 'Sent to Canvas';
    task_type: 'Blood Pressure Out of Range' | 'Glucose Out of Range';
  };
  [Events.TASK_REOPENED]: {
    task_type: 'Blood Pressure Out of Range' | 'Glucose Out of Range';
  };
  [Events.TASK_VIEWED]: {
    task_type: 'Blood Pressure Out of Range' | 'Glucose Out of Range';
  };
  [Events.TASKS_FILTERED]: {
    filter_type: 'Status';
  };
  [Events.TRENDS_FILTERED]: {
    filter_type?: 'Provider';
    trend_direction?: 'Increasing' | 'Decreasing';
    trend_period?: 'Week over week' | 'Month over month';
  };
  [Events.USER_LOGGED_IN]: null;
  [Events.USER_LOGGED_OUT]: null;
}

export type TSnippetName =
  | 'Abnormal Blood Pressure'
  | 'Abnormal Heart Rate'
  | 'Blood Pressure Narrative'
  | 'Blood Pressure Goal'
  | 'Blood Pressure 14-day Average'
  | 'Blood Pressure Latest Reading';

export enum MessageTemplates {
  BLOOD_PRESSURE_OUT_OF_RANGE = 'Blood Pressure Out of Range',
  HIGH_GLUCOSE_OUT_OF_RANGE = 'High Glucose Out of Range',
  HEART_RATE_OUT_OF_RANGE = 'Heart Rate Out of Range',
  LOW_GLUCOSE_OUT_OF_RANGE = 'Low Glucose Out of Range',
}
