import { FormControl, FormLabel } from '@chakra-ui/react';
import { ProviderFilterSelector } from '../../../components';
import { useFilters } from './context';
import { ProviderIds } from '../../../constants';

export default function ProviderSelect(): JSX.Element {
  const { values, setFieldValues } = useFilters();

  function handleOnChange(selectedProviderIds: ProviderIds[]) {
    setFieldValues({ providerIds: selectedProviderIds });
  }

  return (
    <FormControl>
      <FormLabel fontSize="sm">Provider</FormLabel>
      <ProviderFilterSelector
        selectedProviderIds={values.providerIds}
        setSelectedProviderIds={handleOnChange}
      />
    </FormControl>
  );
}
