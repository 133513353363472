import { Box, Button, Text } from '@chakra-ui/react';
import { CancelCircle } from '../../../assets';

interface IProps {
  onClick: () => void;
}

export default function EmptyState({ onClick }: IProps): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="-webkit-fill-available"
    >
      <Box textAlign="center">
        <Text fontSize="lg" fontWeight="medium">
          No matching patients
        </Text>

        <Text mb={3}>Try broadening your search criteria</Text>

        <Button
          onClick={onClick}
          size="sm"
          variant="outline"
          leftIcon={<CancelCircle />}
        >
          Clear filters
        </Button>
      </Box>
    </Box>
  );
}
