import { Box, Button, Text } from '@chakra-ui/react';
import { Link, PaperClip, Trash } from '../../assets';
import { IBroadcastAttachment } from '../../types';

interface IProps {
  attachment: IBroadcastAttachment;
  isEditable?: boolean;
  onRemove: () => void;
}

export default function Attachment({
  attachment,
  isEditable,
  onRemove,
}: IProps): JSX.Element {
  const { description, linkUrl, type } = attachment;

  return (
    <Box
      alignItems="center"
      border="1px solid"
      borderColor="gray.300"
      display="flex"
      justifyContent="space-between"
      mb={2}
      p={3}
      rounded="md"
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          bg="gray.200"
          h={8}
          w={8}
          rounded="md"
        >
          {type === 'file' ? <PaperClip /> : <Link />}
        </Box>

        <Box>
          <Text fontSize="sm">{description}</Text>
          {linkUrl && <Text fontSize="xs">{linkUrl}</Text>}
        </Box>
      </Box>

      {isEditable && (
        <Button variant="ghost" size="sm" onClick={onRemove}>
          <Trash />
        </Button>
      )}
    </Box>
  );
}
