import { Icon } from '@chakra-ui/react';

export default function MarleyLogo(props: any): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 36 36">
      <path
        d="M32.432 13.3383L22.6606 3.56804C20.0886 0.99262 15.9109 0.99262 13.3388 3.56804L3.56852 13.3383C0.992312 15.9137 0.992312 20.0886 3.56852 22.664L13.3388 32.4322C15.9109 35.0086 20.0886 35.0086 22.6606 32.4322L32.432 22.664C35.0072 20.0886 35.0072 15.9137 32.432 13.3383ZM27.4816 23.6327C27.4816 23.9134 27.3687 24.1631 27.1843 24.3475C27.003 24.5298 26.7492 24.6427 26.4716 24.6427C25.9133 24.6427 25.4596 24.1911 25.4596 23.6327V16.558C25.4596 14.8456 24.1057 13.4367 22.3861 13.3517C22.3416 13.3486 22.2981 13.3465 22.2515 13.3465H22.2183C20.4377 13.3652 19.0092 14.8021 19.0092 16.558V23.6161C19.0092 23.8989 18.8942 24.1548 18.7098 24.3423C18.5223 24.5267 18.2665 24.6427 17.9837 24.6427C17.416 24.6427 16.9571 24.1828 16.9571 23.6161V16.558C16.9571 14.8021 15.5318 13.3652 13.749 13.3465H13.7325C13.6828 13.3465 13.6299 13.3486 13.5812 13.3517C11.8638 13.4367 10.5078 14.8456 10.5078 16.558V23.6327C10.5078 24.1911 10.0562 24.6427 9.49784 24.6427H9.49473C8.93951 24.6427 8.48476 24.1911 8.48476 23.6327V16.4617C8.48476 14.8104 9.1674 13.3486 10.397 12.4131C11.2671 11.7501 12.3568 11.3482 13.5336 11.3295C13.5698 11.3264 13.603 11.3264 13.6361 11.3264H13.6548C13.7045 11.3264 13.7511 11.3264 13.7977 11.3295C14.8854 11.3482 15.972 11.6703 16.7696 12.237C16.7841 12.2484 16.7976 12.2567 16.8111 12.2671C17.2544 12.5758 17.6481 12.956 17.9837 13.4014C18.3328 12.9332 18.7513 12.5375 19.2184 12.2236C20.014 11.6621 21.1421 11.3482 22.2204 11.3295C22.2618 11.3264 22.3064 11.3264 22.3499 11.3264H22.3633C22.4048 11.3264 22.4431 11.3264 22.4845 11.3295C23.6623 11.3482 24.7521 11.7501 25.6212 12.4131C26.8487 13.3517 27.4816 14.8104 27.4816 16.4617V23.6327Z"
        fill="currentColor"
      />
    </Icon>
  );
}
