import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { TaskType } from '../../types';

const taskTitleByType: Record<TaskType, string> = {
  [TaskType.OUT_OF_RANGE_READING]: 'Blood Pressure Reading',
  [TaskType.OUT_OF_RANGE_GLUCOSE_READING]: 'Glucose Reading',
};

export function formatTaskTitle(type: TaskType): string {
  return taskTitleByType[type].toLowerCase();
}

export function formatTimeSinceTaskCreation(timestamp: Timestamp): string {
  const now = dayjs();

  return dayjs(timestamp.toDate()).from(now);
}

export function formatOutOfRangeReading(reading: {
  diastolic: number;
  heartRate: number;
  readingTimestamp: Timestamp;
  systolic: number;
}): string {
  const { diastolic, heartRate, readingTimestamp, systolic } = reading;

  return `${systolic}/${diastolic} with a heart rate of ${heartRate} on ${formatReadingTimestamp(
    readingTimestamp,
  )}`;
}

function formatOutOfRangeGlucoseReading(reading: {
  value: number;
  readingTimestamp: Timestamp;
}): string {
  const { value, readingTimestamp } = reading;

  return `${value} mg/dl on ${formatReadingTimestamp(readingTimestamp)}`;
}

function formatReadingTimestamp(timestamp: Timestamp): string {
  return dayjs(timestamp.toDate()).format('MM-DD-YYYY [at] h:mmA');
}

export function formatReasonForReview(type: TaskType, count: number): string {
  return `Patient recorded ${count} out-of-range ${formatTaskTitle(type)}${
    count === 1 ? '' : 's'
  } in the past 24 hours:`;
}

export const readingFormatterByType: Record<
  TaskType,
  (reading: any) => string
> = {
  [TaskType.OUT_OF_RANGE_READING]: formatOutOfRangeReading,
  [TaskType.OUT_OF_RANGE_GLUCOSE_READING]: formatOutOfRangeGlucoseReading,
};
