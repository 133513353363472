import { IBloodPressureGoals } from '../types';

export default function formatBPGoal(
  bloodPressureGoals?: IBloodPressureGoals[],
): string | null {
  if (
    !bloodPressureGoals ||
    bloodPressureGoals.length === 0 ||
    bloodPressureGoals.length > 1
  ) {
    return null;
  }

  if (bloodPressureGoals.length === 1) {
    const { diastolic, systolic } = bloodPressureGoals[0];

    return `${systolic}/${diastolic}`;
  }

  return null;
}
