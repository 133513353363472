import { Icon, IconProps } from '@chakra-ui/react';

export default function Compass(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.875 10C1.875 5.5142 5.5142 1.875 10 1.875C14.4858 1.875 18.125 5.5142 18.125 10C18.125 14.4858 14.4858 18.125 10 18.125C5.5142 18.125 1.875 14.4858 1.875 10ZM10 3.125C6.20455 3.125 3.125 6.20455 3.125 10C3.125 13.7954 6.20455 16.875 10 16.875C13.7954 16.875 16.875 13.7954 16.875 10C16.875 6.20455 13.7954 3.125 10 3.125Z"
          fill="#CBD5E0"
        />
        <path
          d="M13.698 5.8957L9.11992 7.72695C8.8055 7.85269 8.5199 8.041 8.28045 8.28045C8.041 8.5199 7.85269 8.8055 7.72695 9.11992L5.8957 13.698C5.87297 13.7548 5.86741 13.817 5.8797 13.877C5.89199 13.9369 5.9216 13.9919 5.96485 14.0351C6.00811 14.0784 6.06311 14.108 6.12303 14.1203C6.18295 14.1326 6.24516 14.127 6.30195 14.1043L10.8801 12.273C11.1945 12.1473 11.4801 11.959 11.7195 11.7195C11.959 11.4801 12.1473 11.1945 12.273 10.8801L14.1043 6.30195C14.127 6.24516 14.1326 6.18295 14.1203 6.12303C14.108 6.06311 14.0784 6.00811 14.0351 5.96485C13.9919 5.9216 13.9369 5.89199 13.877 5.8797C13.817 5.86741 13.7548 5.87297 13.698 5.8957ZM10 10.9375C9.81458 10.9375 9.63332 10.8825 9.47915 10.7795C9.32498 10.6765 9.20482 10.5301 9.13386 10.3588C9.06291 10.1875 9.04434 9.99896 9.08051 9.8171C9.11669 9.63525 9.20598 9.4682 9.33709 9.33709C9.4682 9.20598 9.63525 9.11669 9.8171 9.08051C9.99896 9.04434 10.1875 9.06291 10.3588 9.13386C10.5301 9.20482 10.6765 9.32498 10.7795 9.47915C10.8825 9.63332 10.9375 9.81458 10.9375 10C10.9375 10.2486 10.8387 10.4871 10.6629 10.6629C10.4871 10.8387 10.2486 10.9375 10 10.9375Z"
          fill="#CBD5E0"
        />
      </svg>
    </Icon>
  );
}
