import { Box, Text } from '@chakra-ui/react';
import { PatientDetailsPanelColumn } from '../../../components';

interface Props {
  onClinicalStateChange: () => void;
  patientUid?: string;
}

export default function PatientDetails({
  onClinicalStateChange,
  patientUid,
}: Props): JSX.Element {
  if (!patientUid) {
    return (
      <Box
        alignItems="center"
        borderLeft="1px solid"
        borderColor="gray.200"
        display="flex"
        justifyContent="center"
      >
        <Box textAlign="center">
          <Text fontWeight="medium" fontSize="lg">
            No Patient Selected
          </Text>
          <Text color="gray.500" fontSize="sm">
            Try adjusting your filters.
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box overflow="hidden">
      <PatientDetailsPanelColumn
        context="Spotlight"
        onClinicalStateChange={onClinicalStateChange}
        patientUid={patientUid}
      />
    </Box>
  );
}
