import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';

interface IProps {
  children: React.ReactNode;
  handleOnClose: () => void;
  isEditing: boolean;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

export default function PatientFormDrawer({
  children,
  handleOnClose,
  isEditing,
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}: IProps): JSX.Element {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={handleOnClose}
      size="lg"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <form onSubmit={onSubmit}>
        <DrawerContent>
          <DrawerHeader
            borderBottom="1px solid"
            borderColor="blackAlpha.200"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {isEditing ? 'Edit' : 'Add New'} Patient
            <DrawerCloseButton position="static" />
          </DrawerHeader>

          <DrawerBody p={6}>{children}</DrawerBody>

          <DrawerFooter display="flex" justifyContent="flex-start" gap={2}>
            <Button colorScheme="blue" type="submit" isLoading={isLoading}>
              Save
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}
