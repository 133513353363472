import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { formatBPGoal } from '../../utils';
import { IPatient } from '../../types';
import { getDistanceFromBPGoal } from './utils';

interface IProps {
  avgDiastolic: number;
  avgSystolic: number;
  patient?: IPatient;
}

export default function BPGoal({ avgDiastolic, avgSystolic, patient }: IProps) {
  const { bloodPressureGoals } = patient || {};
  const goal = useMemo(
    () => formatBPGoal(bloodPressureGoals),
    [bloodPressureGoals],
  );
  const goalDistance = useMemo(
    () =>
      getDistanceFromBPGoal({
        avgDiastolic,
        avgSystolic,
        goals: bloodPressureGoals,
      }),
    [avgDiastolic, avgSystolic, bloodPressureGoals],
  );

  return (
    <Box textAlign="end">
      <Text fontSize="sm" fontWeight="medium">
        {goal || 'N/A'}
      </Text>
      <Text color="black" opacity="48%" fontSize="xs">
        {goalDistance}
      </Text>
    </Box>
  );
}
