import { Icon, IconProps } from '@chakra-ui/react';

export default function PIll(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" />
        <rect
          x="10.98"
          y="82.75"
          width="234.04"
          height="90.51"
          rx="45.25"
          transform="translate(-53.02 128) rotate(-45)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        />
        <line
          x1="96"
          y1="96"
          x2="160"
          y2="160"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        />
        <line
          x1="160"
          y1="112"
          x2="184"
          y2="88"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        />
      </svg>
    </Icon>
  );
}
