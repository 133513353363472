import { forwardRef } from 'react';
import { Box, Text } from '@chakra-ui/react';
import ReadingsTable from '../ReadingsTable';
import { IPatient } from '../../../types';
import { TAB_BAR_HEIGHT } from '../../PatientDetailsPanelColumn';

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

const GlucoseReadings = forwardRef(
  (
    { onUpdate, patient }: IProps,
    ref: React.ForwardedRef<null>,
  ): JSX.Element | null => {
    const isDiabetesPatient = patient.programs.diabetes.enrolled;

    if (!isDiabetesPatient) {
      return null;
    }

    return (
      <Box ref={ref} pt={6} scrollMargin={TAB_BAR_HEIGHT}>
        <Box mb={4}>
          <Text fontWeight="medium">Blood Glucose Data</Text>
        </Box>

        <ReadingsTable
          patient={patient}
          readingType="bloodGlucose"
          tableTitle="All Glucose Readings"
        />
      </Box>
    );
  },
);

export default GlucoseReadings;
