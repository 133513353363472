import { Box, Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import ListItem from './ListItem';
import { IPatient } from '../../../types';

interface Props {
  isLoading: boolean;
  patients: IPatient[];
}

export default function PatientsList({
  isLoading,
  patients,
}: Props): JSX.Element {
  const { uid } = useParams();

  if (isLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        h="-webkit-fill-available"
        justifyContent="center"
      >
        <Spinner color="brand.purple" size="lg" />
      </Box>
    );
  }

  return (
    <Box>
      {patients.map((patient) => (
        <ListItem
          key={patient.uid}
          isActive={uid === patient.uid}
          patient={patient}
        />
      ))}
    </Box>
  );
}
