import { DataFigureGridItem } from '../..';
import { IPatient } from '../../../types';
import { formatBPGoal } from '../../../utils';

interface IProps {
  patient: IPatient;
}

export default function Goal({ patient }: IProps): JSX.Element {
  const { bloodPressureGoals } = patient;
  const formattedBPGoal = formatBPGoal(bloodPressureGoals);

  return (
    <DataFigureGridItem copyEventName="Blood Pressure Goal" label="Goal">
      {formattedBPGoal || 'N/A'}
    </DataFigureGridItem>
  );
}
