import { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import BPSnapshot from './BPSnapshot';
import GlucoseSnapshot from './GlucoseSnapshot';
import TaskActions from './TaskActions';
import { formatReasonForReview, readingFormatterByType } from '../utils';
import { formatDate } from '../../../utils';
import { PatientName, ProviderAvatar } from '../../../components';
import { IPatient, ITask, TaskType } from '../../../types';
import { Events, mixpanel } from '../../../analytics';

interface IProps {
  onUpdateTask: (task: ITask) => void;
  task: ITask;
  patient?: IPatient;
  patientLoading: boolean;
}

const eventPropertyByType: Record<
  TaskType,
  'Blood Pressure Out of Range' | 'Glucose Out of Range'
> = {
  [TaskType.OUT_OF_RANGE_READING]: 'Blood Pressure Out of Range',
  [TaskType.OUT_OF_RANGE_GLUCOSE_READING]: 'Glucose Out of Range',
};

export default function SelectedTaskDetails({
  onUpdateTask,
  task,
  patient,
  patientLoading,
}: IProps): JSX.Element {
  useEffect(() => {
    if (task.id) {
      const taskType = eventPropertyByType[task.type];

      mixpanel.track(Events.TASK_VIEWED, {
        task_type: taskType,
      });
    }
  }, [task]);

  const dob = patient ? formatDate(patient.dateOfBirth) : null;
  const { assignee, data, type } = task;
  const { patientUid, readings } = data;
  const count = readings?.length;
  const readingFormatter = readingFormatterByType[task.type];
  const reasonForReview = formatReasonForReview(task.type, count);

  return (
    <Box bg="white" rounded="md" shadow="md">
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        px={4}
        py={3}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Box>
          <PatientName
            patient={patient}
            isLoading={patientLoading}
            fontSize="md"
          />
          {dob && (
            <Text color="blackAlpha.900" fontSize="sm">
              {dob}
            </Text>
          )}
        </Box>
        <ProviderAvatar providerId={assignee} size="xs" />
      </Box>

      <Box p={4}>
        <Text mb={1} fontWeight="semibold">
          Reason for review
        </Text>
        <Text fontSize="lg">{reasonForReview}</Text>
        {readings?.map((reading) => (
          <Text key={reading.readingTimestamp.toString()}>
            &bull; {readingFormatter(reading)}
          </Text>
        ))}
      </Box>

      {type === TaskType.OUT_OF_RANGE_READING && (
        <BPSnapshot patientUid={patientUid} />
      )}

      {type === TaskType.OUT_OF_RANGE_GLUCOSE_READING && (
        <GlucoseSnapshot patientUid={patientUid} />
      )}

      {patient && (
        <TaskActions
          patient={patient}
          task={task}
          onUpdateTask={onUpdateTask}
        />
      )}
    </Box>
  );
}
