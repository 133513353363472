import { Icon } from '@chakra-ui/react';

export default function HelpCircle(props: any): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 0.5C2.51562 0.5 0.5 2.51562 0.5 5C0.5 7.48438 2.51562 9.5 5 9.5C7.48438 9.5 9.5 7.48438 9.5 5C9.5 2.51562 7.48438 0.5 5 0.5ZM4.85938 7.625C4.76667 7.625 4.67604 7.59751 4.59895 7.546C4.52187 7.49449 4.46179 7.42129 4.42631 7.33563C4.39083 7.24998 4.38155 7.15573 4.39963 7.0648C4.41772 6.97387 4.46236 6.89035 4.52792 6.82479C4.59347 6.75924 4.677 6.71459 4.76793 6.69651C4.85885 6.67842 4.9531 6.6877 5.03876 6.72318C5.12441 6.75866 5.19762 6.81874 5.24913 6.89583C5.30063 6.97291 5.32812 7.06354 5.32812 7.15625C5.32812 7.28057 5.27874 7.3998 5.19083 7.48771C5.10292 7.57561 4.9837 7.625 4.85938 7.625V7.625ZM5.64313 5.23438C5.2632 5.48938 5.21094 5.72305 5.21094 5.9375C5.21094 6.02452 5.17637 6.10798 5.11483 6.16952C5.0533 6.23106 4.96984 6.26562 4.88281 6.26562C4.79579 6.26562 4.71233 6.23106 4.65079 6.16952C4.58926 6.10798 4.55469 6.02452 4.55469 5.9375C4.55469 5.42398 4.79094 5.0157 5.27703 4.68922C5.72891 4.38594 5.98438 4.19375 5.98438 3.77117C5.98438 3.48383 5.82031 3.26563 5.4807 3.10414C5.40078 3.06617 5.22289 3.02914 5.00398 3.03172C4.7293 3.03523 4.51602 3.10086 4.35172 3.23305C4.04187 3.48242 4.01562 3.75383 4.01562 3.75781C4.01355 3.8009 4.003 3.84316 3.98459 3.88218C3.96618 3.92119 3.94027 3.9562 3.90833 3.9852C3.87639 4.0142 3.83906 4.03662 3.79845 4.05119C3.75785 4.06576 3.71477 4.07219 3.67168 4.07012C3.62859 4.06804 3.58633 4.0575 3.54732 4.03909C3.5083 4.02068 3.47329 3.99476 3.44429 3.96282C3.41529 3.93089 3.39287 3.89355 3.3783 3.85294C3.36373 3.81234 3.3573 3.76926 3.35938 3.72617C3.36195 3.66922 3.40156 3.15617 3.93992 2.72305C4.21906 2.49852 4.57414 2.3818 4.99461 2.37664C5.29227 2.37313 5.57187 2.42352 5.76148 2.51305C6.32891 2.78141 6.64062 3.22883 6.64062 3.77117C6.64062 4.56406 6.1107 4.92008 5.64313 5.23438Z"
          fill="currentColor"
          fillOpacity="0.92"
        />
      </svg>
    </Icon>
  );
}
