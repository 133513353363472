import { useState } from 'react';
import { Button, Tooltip, useToast } from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { PaperPlane } from '../../../assets';
import { IBroadcast } from '../../../types';
import { functions } from '../../../firebase';
import { useGetAdmin } from '../../../contexts';

interface IBroadcastRequestData {
  broadcastId: string;
}

interface IBroadcastResponseData {
  successCount: number;
  errorsByPatientUid: string[];
}

interface IProps {
  broadcast: IBroadcast;
  onSuccess: () => Promise<void>;
}

export default function SendBroadcastButton({
  broadcast,
  onSuccess,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const admin = useGetAdmin();
  const userCreatedBroadcast = broadcast?.createdBy === admin.uid;

  async function sendBroadcast() {
    setIsLoading(true);

    try {
      const sendBroadcast = httpsCallable<
        IBroadcastRequestData,
        IBroadcastResponseData
      >(functions, 'createSourceBroadcast');
      const { id, patientUids } = broadcast;

      const { data } = await sendBroadcast({
        broadcastId: id,
      });

      const { successCount, errorsByPatientUid } = data;

      toast({
        description: `Broadcast sent to ${successCount} patient${
          successCount === 1 ? '' : 's'
        }.`,
        status: 'success',
        title: 'Success!',
      });

      if (errorsByPatientUid.length) {
        toast({
          description: `Error sending broadcast to ${errorsByPatientUid.length}/${patientUids.length} patients.`,
          status: 'warning',
          title: `Uh Oh.`,
        });
      }

      onSuccess();
    } catch (error) {
      setIsLoading(false);
      toast({
        description: 'An error occurred sending the broadcast.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <Tooltip
      hasArrow
      isDisabled={userCreatedBroadcast}
      label="The user who created this broadcast can send it."
    >
      <Button
        colorScheme="blue"
        isDisabled={!userCreatedBroadcast}
        isLoading={isLoading}
        leftIcon={<PaperPlane />}
        onClick={sendBroadcast}
        size="sm"
      >
        Send Broadcast
      </Button>
    </Tooltip>
  );
}
