export enum ProviderIds {
  PROVIDER_RB = 'bCB5Mu9NpRVq3i2NcpxOEQXLwk53', // Rachel Bennet firebase admin uid
  PROVIDER_ASB = 'fFXmEZY5DQUOxfchyASZ3yzep6F2', // Amanda Simone-Belin firebase admin uid
  PROVIDER_RL = 'hitBVAyTEcZhJ9hT2RuY2FWFkDH3', // Rebecca Liggon firebase admin uid
  PROVIDER_DL = '1o4fxD0ntgatJevHB0IpBYC07MQ2', // Dayna Long firebase admin uid
  PROVIDER_CB = 'xSq0vHXty4RBN7kw1EcBvCHeeiq1', // Chelsea Biel firebase admin uid
  PROVIDER_KB = 'qHVdjun2V4b14crlScXWxz0bBT92', // Kristina Bartelt firebase admin uid
  PROVIDER_OTHER = 'other',
}

export enum ProviderName {
  ALL_PROVIDERS = 'All Providers',
  PROVIDER_RB = 'Rachel Bennett',
  PROVIDER_ASB = 'Amanda Simone-Belin',
  PROVIDER_RL = 'Rebecca Liggon',
  PROVIDER_DL = 'Dayna Long',
  PROVIDER_KB = 'Kristina Bartelt',
  PROVIDER_OTHER = 'Other/Unassigned',
}

// list of providers to allow filtering of patients by
export const PROVIDERS_LIST = [
  { id: ProviderIds.PROVIDER_ASB, name: ProviderName.PROVIDER_ASB },
  { id: ProviderIds.PROVIDER_DL, name: ProviderName.PROVIDER_DL },
  { id: ProviderIds.PROVIDER_KB, name: ProviderName.PROVIDER_KB },
  { id: ProviderIds.PROVIDER_RB, name: ProviderName.PROVIDER_RB },
  { id: ProviderIds.PROVIDER_RL, name: ProviderName.PROVIDER_RL },
  { id: ProviderIds.PROVIDER_OTHER, name: ProviderName.PROVIDER_OTHER },
];
