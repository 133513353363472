import { Icon, IconProps } from '@chakra-ui/react';

export default function Trending(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.125 7.625C15.125 7.27982 15.4048 7 15.75 7H20.125C20.4702 7 20.75 7.27982 20.75 7.625V12C20.75 12.3452 20.4702 12.625 20.125 12.625C19.7798 12.625 19.5 12.3452 19.5 12V9.13388L14.576 14.0579C14.576 14.0579 14.5759 14.0579 14.5759 14.058C14.4018 14.2321 14.1951 14.3702 13.9676 14.4645C13.7401 14.5587 13.4963 14.6072 13.25 14.6072C13.0037 14.6072 12.7599 14.5587 12.5324 14.4645C12.3049 14.3702 12.0981 14.2321 11.924 14.0579L9.94204 12.0759L9.94199 12.0759C9.88395 12.0178 9.81505 11.9718 9.73921 11.9404C9.66337 11.9089 9.58209 11.8928 9.5 11.8928C9.41791 11.8928 9.33663 11.9089 9.26079 11.9404C9.18495 11.9718 9.11605 12.0178 9.05801 12.0759L9.05796 12.0759L4.31694 16.8169C4.07286 17.061 3.67714 17.061 3.43306 16.8169C3.18898 16.5729 3.18898 16.1771 3.43306 15.9331L8.17402 11.1921C8.34814 11.0179 8.55486 10.8798 8.78237 10.7855C9.00988 10.6913 9.25374 10.6428 9.5 10.6428C9.74626 10.6428 9.99012 10.6913 10.2176 10.7855C10.4451 10.8798 10.6519 11.0179 10.826 11.1921L12.808 13.1741L12.808 13.1741C12.866 13.2322 12.935 13.2782 13.0108 13.3096C13.0866 13.3411 13.1679 13.3572 13.25 13.3572C13.3321 13.3572 13.4134 13.3411 13.4892 13.3096C13.565 13.2782 13.634 13.2322 13.692 13.1741L13.692 13.1741L18.6161 8.25H15.75C15.4048 8.25 15.125 7.97018 15.125 7.625Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
