import { useEffect, useRef, useState } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { HEADER_CONTAINER_HEIGHT } from '../../pages/TodayPage';
import VendorAlerts from './VendorAlerts';
import PatientDetailsPanelHeader from './PatientDetailsPanelHeader';
import PatientDetailsSummary from './PatientDetailsSummary';
import ClinicalReviews from './ClinicalReviews';
import BloodPressureReadings from './BloodPressureReadings';
import WeightReadings from './WeightReadings';
import GlucoseReadings from './GlucoseReadings';
import ContactInformation from './ContactInformation';
import ClinicInformation from './ClinicInformation';
import RecentActivity from './RecentActivity';
import PatientDetailsPanelTabs from './PatientDetailPanelTabs';
import PaymentInfo from './PaymentInfo';
import SpotlightActions from './SpotlightActions';
import { useFetchPatient } from '../../hooks';
import {
  useHandlePanelSectionNavigation,
  useObservePanelSections,
  usePanelSections,
} from './hooks';
import { mixpanel, Events } from '../../analytics';
import ProgramEnrollmentPrompt from './ProgramEnrollmentPrompt';

export const TAB_BAR_HEIGHT = '44px';

type IPanelSectionHash =
  | '#clinical-reviews'
  | '#bp-readings'
  | '#weight-readings'
  | '#glucose-readings'
  | '#recent-activity'
  | '#personal-info'
  | '#clinic-info'
  | '#payment-info';

type IPanelSectionTitle =
  | 'Clinical Reviews'
  | 'Blood Pressure Data'
  | 'Weight Data'
  | 'Blood Glucose Data'
  | 'Recent Activity'
  | 'Personal Info'
  | 'Clinic Info'
  | 'Payment Info';

export interface IPanelSection {
  title: IPanelSectionTitle;
  event_property:
    | 'clinical_reviews'
    | 'bp_readings'
    | 'weight_readings'
    | 'glucose_readings'
    | 'recent_activity'
    | 'personal_info'
    | 'clinic_info'
    | 'payment_info';
  hash: IPanelSectionHash;
  tabRef: React.MutableRefObject<HTMLAnchorElement | null>;
  ref: HTMLDivElement | null;
}

interface IProps {
  context: 'Patients' | 'Tasks' | 'Trends' | 'Spotlight' | 'Broadcast';
  onClinicalStateChange?: () => void;
  onClose?: () => void;
  patientUid?: string;
}

export default function PatientDetailsPanelColumn({
  context,
  onClinicalStateChange,
  onClose,
  patientUid,
}: IProps) {
  const { patient, isLoading, refetchPatient } = useFetchPatient(patientUid);
  const {
    sections,
    setClinicalReviewRef,
    setBpReadingsRef,
    setWeightReadingsRef,
    setGlucoseReadingsRef,
    setRecentActivityRef,
    setPersonalInfoRef,
    setClinicInfoRef,
    setPaymentInfoRef,
  } = usePanelSections();

  // actively focused section
  const [sectionInFocus, setSectionInFocus] = useState<IPanelSection | null>(
    sections[0],
  );
  // section that's currently intersecting 25% mark on scrolling viewport
  const [intersectingSection, setIntersectingSection] =
    useState<IPanelSection | null>(null);
  // section to programatically scroll to on user-generated tab click
  const [sectionToScrollTo, setSectionToScrollTo] =
    useState<IPanelSection | null>(null);

  useEffect(() => {
    if (patientUid) {
      mixpanel.track(Events.PATIENT_VIEWED, {
        context,
      });
    }
  }, [context, patientUid]);

  const verticalScrollContainerRef = useRef(null);
  const horizontalScrollTabBarRef = useRef(null);

  // reset the focused section if when a new patient is selected
  useEffect(() => {
    setSectionInFocus(sections[0]);
  }, [sections]);

  useHandlePanelSectionNavigation({
    verticalScrollContainerRef,
    horizontalScrollTabBarRef,
    sectionInFocus,
    setSectionInFocus,
    intersectingSection,
    sectionToScrollTo,
    setSectionToScrollTo,
  });

  useObservePanelSections({
    sections,
    setIntersectingSection,
  });

  // since it's likely the bottom section can't be scrolled the center of the panel
  // where it'll be recognized by the IntersectionObserver to focus the tab,
  // instead detect when we reach scroll end to focus the tab of the bottom section
  function detectScrollEnd(e: React.SyntheticEvent) {
    const { scrollHeight, scrollTop, clientHeight } =
      e.target as HTMLDivElement;
    const atBottom =
      Math.round(scrollHeight - scrollTop) === Math.round(clientHeight);
    if (atBottom) {
      const lastSection = sections[sections.length - 1];
      setIntersectingSection(lastSection);
    }
  }

  async function handleOnClinicalStateChange() {
    await refetchPatient();

    if (onClinicalStateChange) {
      onClinicalStateChange();
    }
  }

  return (
    <Box>
      <PatientDetailsPanelHeader
        patient={patient}
        onClose={onClose}
        onUpdate={refetchPatient}
      />
      <Box
        ref={verticalScrollContainerRef}
        onScroll={detectScrollEnd}
        height={`calc(100vh - ${HEADER_CONTAINER_HEIGHT})`}
        overflowY="scroll"
        borderLeft="1px solid"
        borderColor="gray.200"
      >
        {!patient && <Loader />}

        {patient && (
          <>
            <PatientDetailsSummary patient={patient} />
            <VendorAlerts patient={patient} />

            <Box px={6}>
              <ProgramEnrollmentPrompt
                patient={patient}
                onUpdate={refetchPatient}
              />
            </Box>

            <PatientDetailsPanelTabs
              ref={horizontalScrollTabBarRef}
              sections={sections}
              sectionInFocus={sectionInFocus}
              onTabClick={setSectionToScrollTo}
            />
            <Box px={6} pb={6}>
              {patient.isEnrolled && (
                <>
                  <ClinicalReviews
                    ref={(r) => setClinicalReviewRef(r)}
                    patient={patient}
                  />

                  <BloodPressureReadings
                    ref={(r) => setBpReadingsRef(r)}
                    patient={patient}
                  />

                  <WeightReadings
                    ref={(r) => setWeightReadingsRef(r)}
                    onUpdate={refetchPatient}
                    patient={patient}
                  />

                  <GlucoseReadings
                    ref={(r) => setGlucoseReadingsRef(r)}
                    onUpdate={refetchPatient}
                    patient={patient}
                  />

                  <RecentActivity
                    ref={(r) => setRecentActivityRef(r)}
                    patient={patient}
                    patientLoading={isLoading}
                  />
                </>
              )}
              <ContactInformation
                ref={(r) => setPersonalInfoRef(r)}
                patient={patient}
              />

              <Box mb={6}>
                <ClinicInformation
                  ref={(r) => setClinicInfoRef(r)}
                  patient={patient}
                />
              </Box>

              <Box ref={(r) => setPaymentInfoRef(r)}>
                <PaymentInfo patient={patient} />
              </Box>

              {/* empty box to push last section up so it's recognized by intersection observer */}
              <Box h={250} />
            </Box>
          </>
        )}
      </Box>

      {context === 'Spotlight' && patient && (
        <Box position="relative">
          <SpotlightActions
            patient={patient}
            onUpdate={handleOnClinicalStateChange}
          />
        </Box>
      )}
    </Box>
  );
}

function Loader() {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" h="100%">
      <Box alignItems="center" display="flex" flexDir="column">
        <Spinner color="brand.purple" mb={4} size="lg" />
        <Text fontSize="xl">Loading...</Text>
      </Box>
    </Box>
  );
}
