import { Box, Heading } from '@chakra-ui/react';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HEADER_CONTAINER_HEIGHT } from '..';
import { db } from '../../../firebase';
import { ITask, TaskStatus } from '../../../types';
import TaskListItems from './TaskListItems';

export default function TasksListColumn({
  tasks,
  setTasks,
  selectedTask,
}: {
  tasks: ITask[];
  setTasks: React.Dispatch<React.SetStateAction<ITask[]>>;
  selectedTask?: ITask;
}) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { taskId } = useParams();
  const [statusFilter, setStatusFilter] = useState<TaskStatus>(TaskStatus.OPEN);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchTasks() {
      const tasksCollection = collection(db, 'tasks');
      const q = query(
        tasksCollection,
        orderBy('createdAt', 'desc'),
        where('status', '==', statusFilter),
      );

      setIsLoading(true);

      const querySnapshot = await getDocs(q);
      const tasks: ITask[] = [];

      querySnapshot.forEach((doc) => {
        tasks.push({
          id: doc.id,
          ...doc.data(),
        } as ITask);
      });

      setTasks(tasks);
      setIsLoading(false);
    }

    fetchTasks();
  }, [setTasks, statusFilter]);

  const handleSelectTask = useCallback(
    (task: ITask): void => {
      navigate(`/today/${task.id}${search}`);
    },
    [navigate, search],
  );

  useEffect(() => {
    if (tasks.length && !taskId) {
      handleSelectTask(tasks[0]);
    }
  }, [tasks, taskId, handleSelectTask]);

  return (
    <>
      <Heading
        h={HEADER_CONTAINER_HEIGHT}
        alignItems="center"
        borderRight="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="flex"
        fontSize={24}
        fontWeight="extrabold"
        justifyContent="space-between"
        px={6}
        size="md"
      >
        Today
        {!isLoading && <Box>{tasks.length}</Box>}
      </Heading>
      <Box
        height={`calc(100vh - ${HEADER_CONTAINER_HEIGHT})`}
        overflowY="scroll"
      >
        <TaskListItems
          isLoading={isLoading}
          onFilterChange={setStatusFilter}
          onTaskClick={handleSelectTask}
          selectedTask={selectedTask}
          tasks={tasks}
        />
      </Box>
    </>
  );
}
