import { Icon, IconProps } from '@chakra-ui/react';

export default function ChatBubbles(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7469 11.675H12.7219L12.6969 11.6719C12.6125 11.6625 12.5281 11.6438 12.4469 11.6094L10.675 10.9812L10.6625 10.975C10.625 10.9594 10.5844 10.95 10.5406 10.95C10.5 10.95 10.4625 10.9563 10.425 10.9719C10.3844 10.9875 9.99373 11.1344 9.58748 11.2469C9.36561 11.3062 8.59686 11.5063 7.98123 11.5063C6.40311 11.5063 4.92811 10.9 3.82811 9.8C2.73748 8.70938 2.13748 7.2625 2.13748 5.72188C2.13748 5.32813 2.18123 4.93125 2.26561 4.54688C2.54061 3.27813 3.26873 2.125 4.31248 1.29688C5.36561 0.459375 6.70311 0 8.07186 0C9.70311 0 11.225 0.625 12.3531 1.75625C13.4219 2.82812 14.0062 4.23438 14 5.72188C14 6.82813 13.6719 7.9 13.0531 8.82187L13.0375 8.84688C13.025 8.8625 13.0156 8.87813 13.0031 8.89375C12.9875 8.91875 12.9781 8.94063 12.9719 8.95625L13.4625 10.7031C13.4812 10.7625 13.4906 10.825 13.4969 10.8875V10.925C13.4969 11.3375 13.1594 11.675 12.7469 11.675ZM11.0312 10.0469L12.3719 10.5219L11.9875 9.15C11.925 8.92187 11.975 8.66562 12.1406 8.38437L12.1437 8.37813C12.1719 8.33438 12.2 8.29062 12.2312 8.24687C12.7344 7.49375 13 6.61875 13 5.71562C13.0031 4.49687 12.5219 3.34063 11.6437 2.45937C10.7031 1.51875 9.43436 1 8.07186 1C5.74373 1 3.70936 2.58125 3.23748 4.75938C3.16873 5.075 3.13436 5.4 3.13436 5.72188C3.13436 8.35938 5.30623 10.5063 7.97811 10.5063C8.36873 10.5063 8.94686 10.3875 9.32186 10.2844C9.69061 10.1844 10.0562 10.0469 10.0719 10.0406C10.2219 9.98437 10.3781 9.95625 10.5406 9.95625C10.7062 9.95313 10.8719 9.98438 11.0312 10.0469Z"
          fill="black"
          fillOpacity="0.8"
        />
        <path
          d="M1.24689 14C1.07502 14 0.909393 13.9406 0.775018 13.8344L0.765643 13.8281C0.556268 13.6531 0.459393 13.375 0.512518 13.1094C0.603143 12.6438 0.790643 11.6656 0.859393 11.3031C0.859393 11.3031 0.859393 11.3031 0.859393 11.3C0.337518 10.525 0.0437681 9.61875 0.00314307 8.6875C-0.0374819 7.75313 0.184393 6.82813 0.637518 6.00938C0.771893 5.76875 1.07502 5.68125 1.31877 5.81563C1.55939 5.95 1.64689 6.25312 1.51252 6.49687C0.765643 7.84062 0.831268 9.46875 1.69064 10.7469L1.69377 10.75C1.80939 10.925 1.91877 11.1344 1.86564 11.3813C1.85314 11.4438 1.70939 12.1844 1.58127 12.8563L2.91564 12.3344C3.15627 12.2406 3.42189 12.2438 3.66252 12.3438C4.23127 12.5656 4.82502 12.6813 5.37502 12.6813C5.37814 12.6813 5.37814 12.6813 5.38127 12.6813C6.16877 12.6813 6.94689 12.4719 7.62814 12.0781C7.86564 11.9406 8.17189 12.0219 8.31252 12.2594C8.45002 12.4969 8.36877 12.8031 8.13127 12.9438C7.29689 13.4281 6.34689 13.6813 5.38439 13.6813C5.38127 13.6813 5.38127 13.6813 5.37814 13.6813C4.70314 13.6813 3.98439 13.5406 3.29689 13.2719L3.28439 13.2656L1.53752 13.95C1.45002 13.9875 1.35627 14.0063 1.25939 14.0063C1.25002 14 1.25002 14 1.24689 14Z"
          fill="black"
          fillOpacity="0.8"
        />
      </svg>
    </Icon>
  );
}
