import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useGetAdmin } from './contexts';
import { useOnLocationChange } from './hooks';
import {
  BroadcastsPage,
  BroadcastDetailsPage,
  LoginPage,
  PatientsPage,
  TodayPage,
  TrendingPage,
  UpNextPage,
} from './pages';
import { ProtectedRoute } from './components';
import { Features } from './types';

export default function App(): JSX.Element {
  useOnLocationChange();
  const { features, isBetaTester } = useGetAdmin();
  const hasSpotlight = useMemo(
    () => features?.includes(Features.SPOTLIGHT),
    [features],
  );

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />

      <Route
        path="/today"
        element={
          <ProtectedRoute>
            <TodayPage />
          </ProtectedRoute>
        }
      >
        <Route path=":taskId" element={<PatientsPage />} />
      </Route>

      {isBetaTester && (
        <Route path="/broadcasts">
          <Route
            index
            element={
              <ProtectedRoute>
                <BroadcastsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute>
                <BroadcastDetailsPage />
              </ProtectedRoute>
            }
          />
        </Route>
      )}

      <Route
        path="/patients"
        element={
          <ProtectedRoute>
            <PatientsPage />
          </ProtectedRoute>
        }
      >
        <Route path=":uid" element={<PatientsPage />} />
      </Route>

      <Route
        path="/trending"
        element={
          <ProtectedRoute>
            <TrendingPage />
          </ProtectedRoute>
        }
      >
        <Route path=":uid" element={<TrendingPage />} />
      </Route>

      {hasSpotlight && (
        <Route
          path="/up-next"
          element={
            <ProtectedRoute>
              <UpNextPage />
            </ProtectedRoute>
          }
        >
          <Route path=":uid" element={<UpNextPage />} />
        </Route>
      )}

      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
}
