import { Timestamp } from 'firebase/firestore';

export enum AdminRole {
  CLINICIAN = 'clinician',
  OTHER = 'other',
}

export enum Features {
  SPOTLIGHT = 'spotlight',
}

export interface IAdmin {
  uid: string;
  analyticsId: string;
  email: string;
  features: Features[];
  firstName: string;
  isBetaTester?: boolean;
  lastName: string;
  role: AdminRole;
  sourceId?: string;
}

export interface IBroadcast {
  id: string;
  attachments: IBroadcastAttachment[];
  createdAt: Timestamp;
  createdBy: string;
  errorsByPatientUid: string[];
  messageBody: string;
  patientUids: string[];
  status: BroadcastStatus;
  threadSubject: string;
}

export interface IBroadcastAttachment {
  description: string;
  filePath?: string;
  type: 'file' | 'link';
  linkUrl?: string;
}

export enum BroadcastStatus {
  DRAFT = 'draft',
  SENT = 'sent',
}

export type TSexAtBirth = 'male' | 'female' | 'other' | 'unknown';

export enum CommunicationPreference {
  EMAIL = 'email',
  SMS = 'sms',
}
interface ICareTeamLead {
  adminId: string;
  display: string;
  role: string;
}

interface IPatientPrograms {
  diabetes: {
    enrolled: boolean;
    enrolledOn: Timestamp | null;
  };
  hypertension: {
    enrolled: boolean;
    enrolledOn: Timestamp | null;
  };
  weight: {
    enrolled: boolean;
    enrolledOn: Timestamp | null;
  };
}

export enum BillingType {
  INSURANCE = 'insurance',
  SELF_PAY = 'self-pay',
}

export enum SubscriptionType {
  GRANDFATHERED = 'grandfathered',
  FINANCIAL_AID = 'financialAid',
  STANDARD = 'standard',
}

export enum FinancialAidStatus {
  APPROVED = 'approved',
  APPLICATION_SENT = 'application_sent',
  DENIED = 'denied',
  NOT_APPLICABLE = 'not_applicable',
}

export interface IPatient {
  uid: string;
  address: {
    lineOne: string;
    lineTwo?: string;
    city: string;
    state: string;
    zipCode: string;
  };
  billing: {
    autoCollection: boolean | null;
    balance: number;
    financialAidStatus: FinancialAidStatus;
    financialAidApprovedAt: Timestamp | null;
    subscriptionType: SubscriptionType;
    type: BillingType;
  };
  bloodPressureGoals: IBloodPressureGoals[];
  bloodPressureGoalsDiastolicDelta: number | null;
  bloodPressureGoalsSystolicDelta: number | null;
  canvasId: string;
  careTeamLead: ICareTeamLead | null;
  clinicalReviewState: {
    comment: string | null;
    date: Timestamp | null;
    state: ClinicalReviewStates;
  };
  clinicalReviewTasks?: {
    last: ClinicalReviewTask | null;
    next: ClinicalReviewTask | null;
  };
  communicationPreference: CommunicationPreference;
  createdAt: Timestamp;
  customerIOId: string;
  dateOfBirth: string;
  deactivatedReason: DeactivatedReason | null;
  deactivatedOn?: Timestamp | null;
  email: string;
  engagementActivity: {
    lastMessageReadAt: Timestamp | null;
    lastMessageReceivedAt: Timestamp | null;
    lastMessageSentAt: Timestamp | null;
    lastPhoneCallReceivedAt: Timestamp | null;
  };
  enrolledOn: Timestamp | null;
  firstName: string;
  firstReadingBloodPressure?: IPatientBloodPressureReading;
  firstReadingWeight?: IPatientWeightReading;
  hasConsentedToTreatment?: boolean;
  hasLoggedIn?: boolean;
  impiloId: string;
  isActive: boolean;
  isEnrolled: boolean;
  lastBloodPressureRisk: IBloodPressureRisk;
  lastName: string;
  lastReadingBloodPressure?: IPatientBloodPressureReading;
  lastReadingWeight?: IPatientWeightReading;
  phoneNumber: string;
  programs: IPatientPrograms;
  readingBloodPressureCount?: number;
  readingWeightCount?: number;
  segments: string[];
  sexAtBirth: TSexAtBirth;
  sourceId: string;
  stripeId: string;
}

/**
 * Reason for patient deactivation
 */
export enum DeactivatedReason {
  GLP1_NOT_COVERED = 'glp1_not_covered',
  GLP1_COST = 'glp1_cost',
  GLP1_MEDICATION_SHORTAGE = 'glp1_medication_shortage',
  PATIENT_ACHIEVED_GOAL = 'patient_achieved_goal',
  PATIENT_MOVED_STATES = 'patient_moved_states',
  PATIENT_NOT_SATISFIED = 'patient_not_satisfied',
  PATIENT_MOVED_PROVIDERS = 'patient_moved_providers',
  PATIENT_INSURANCE_ISSUES = 'patient_insurance_issues',
  COST = 'cost',
  MARLEY_INITIATED = 'marley_initiated',
}

export type NotAtBloodPressureGoalReason =
  | 'NO_DEVICE'
  | 'NO_READINGS'
  | 'SYSTOLIC'
  | 'DIASTOLIC';

export interface IBloodPressureGoals {
  atGoal: boolean;
  diastolic: number;
  id: string;
  notAtGoalReasons?: NotAtBloodPressureGoalReason[];
  systolic: number;
  text: string;
}

interface IPatientBloodPressureReading extends IBloodPressureReading {
  heartRate: number;
  id: string; // maps to item uid on readingBloodPressures collection
  readingTime: Timestamp;
}

export enum BloodPressureRisk {
  UNKNOWN = 'unknown',
  LOW = 'low',
  MODERATE = 'moderate',
  HIGH = 'high',
}

interface IBloodPressureRisk {
  id: string;
  risk: BloodPressureRisk;
  time: Timestamp;
}

interface IPatientWeightReading {
  id: string;
  bmi?: number | null; // body mass index calculated at the time of the reading
  readingTime: Timestamp; // maps to item uid on readingWeights collection
  value: number;
}

export interface IWatchlistPatient {
  uid: string;
  createdAt: Timestamp;
}

export enum BloodPressureSeverity {
  NORMAL = 'normal',
  ELEVATED = 'elevated',
  HYPERTENSION_STAGE_1 = 'hypertensionStage1',
  HYPERTENSION_STAGE_2 = 'hypertensionStage2',
  HYPERTENSION_CRISIS = 'hypertensiveCrisis',
}

export interface IBloodPressureReading {
  systolic: number;
  diastolic: number;
}

export interface TBloodPressureLatestReading extends IBloodPressureReading {
  timestamp: string;
}

export interface IBloodPressureOverview {
  latestReading: TBloodPressureLatestReading | null;
  readings: IBloodPressureReading[];
  severity: BloodPressureSeverity | null;
  twoWeekAverage: IBloodPressureReading | null;
}

export enum TaskStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum TaskType {
  OUT_OF_RANGE_GLUCOSE_READING = 'out_of_range_glucose_reading',
  OUT_OF_RANGE_READING = 'out_of_range_reading',
}

export interface ITaskData {
  patientUid: string;
}

export interface IOutOfRangeReadingTaskData extends ITaskData {
  readings: {
    diastolic: number;
    heartRate: number;
    readingTimestamp: Timestamp;
    systolic: number;
  }[];
}

export interface IOutOfRangeGlucoseReadingTaskData extends ITaskData {
  readings: {
    readingTimestamp: Timestamp;
    value: number;
  }[];
}

export interface ITask {
  id: string;
  assignee: string | null;
  createdAt: Timestamp;
  data: IOutOfRangeReadingTaskData | IOutOfRangeGlucoseReadingTaskData;
  status: TaskStatus;
  type: TaskType;
}

export enum PatientTrendTimePeriod {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum PatientTrendChangeType {
  WORSE = 'worse',
  BETTER = 'better',
}

export interface IPatientTrendReadingsSummary {
  averageSystolic: number;
  averageDiastolic: number;
  countReadings: number;
  risk: BloodPressureSeverity;
}

export interface IPatientTrend {
  id: string;
  patientUid: string;
  careTeamLead: ICareTeamLead | null;
  trend: {
    period: PatientTrendTimePeriod;
    last: IPatientTrendReadingsSummary;
    previous: IPatientTrendReadingsSummary;
    change: {
      type: PatientTrendChangeType;
      systolic: number;
      systolicPercent: number;
      diastolic: number;
      diastolicPercent: number;
      countReadings: number;
    };
  };
}

export interface IBPTrendAverageStat {
  avgSystolic: number;
  avgDiastolic: number;
  countReadings: number;
}

export interface IBPDeltaStat {
  delta: number;
  deltaPercent: number;
}

export interface IReadingsDeltaStat {
  prevCountReadings: number;
  countReadingsDelta: number;
}

export interface ClinicalReviewTask {
  date?: Timestamp | null;
  id: string;
  link?: string | null;
}

export enum ClinicalReviewStates {
  NOT_APPLICABLE = 'not_applicable',
  REVIEWABLE = 'reviewable',
  BLOCKED = 'blocked',
  CHASE_LIST = 'chase_list',
  REVIEWING = 'reviewing',
  CHART_PREP = 'chart_prep',
}

export type ReadingSource = 'impilo';

export interface IReadingWeight {
  id: string;
  createdAt: Timestamp;
  deviceId: string;
  outlier: boolean;
  patientUid: string;
  readingTime: Timestamp;
  receivedAt: Timestamp;
  source: ReadingSource;
  value: number;
}

export interface IReadingBloodPressure {
  id: string;
  createdAt: Timestamp;
  deviceId: string;
  diastolic: number;
  heartRate?: number | null;
  patientUid: string;
  readingTime: Timestamp;
  receivedAt: Timestamp;
  source: string;
  systolic: number;
}

export interface IReadingBloodGlucose {
  id: string;
  createdAt: Timestamp;
  deviceId: string;
  patientUid: string;
  readingTime: Timestamp;
  receivedAt: Timestamp;
  source: ReadingSource;
  value: number;
}

export interface Segment {
  id: string;
  name: string;
  description: string | null;
  blocksClinicalReview: boolean;
}
