import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, Switch, Text, useToast } from '@chakra-ui/react';
import MessageTabs from './MessageTabs';
import { Events, MessageTemplates, mixpanel } from '../../../../../analytics';
import { ChatBubbles } from '../../../../../assets';
import { IPatient, ITask } from '../../../../../types';
import { functions } from '../../../../../firebase';

interface IProps {
  patient: IPatient;
  task: ITask;
}

export default function TaskMessageManager({
  patient,
  task,
}: IProps): JSX.Element {
  const [showMessages, setShowMessages] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    resetState();
  }, [task.id]);

  useEffect(() => {
    return () => resetState();
  }, []);

  function resetState() {
    setShowMessages(false);
    setIsLoading(false);
    setIsSuccess(false);
  }

  async function handleSendMessage(
    message: string,
    threadSubject: string,
    messageTemplate: MessageTemplates,
  ): Promise<void> {
    setIsLoading(true);

    try {
      const sendPatientSourceMessage = httpsCallable(
        functions,
        'sendPatientSourceMessage',
      );

      await sendPatientSourceMessage({
        messageBody: message,
        memberId: patient.sourceId,
        threadSubject,
      });

      mixpanel.track(Events.MESSAGE_SENT, {
        context: 'Task',
        template: messageTemplate,
      });

      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      toast({
        description: 'An error occurred sending the message. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  function handleClick() {
    setShowMessages(!showMessages);
  }

  return (
    <Box p={4}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box
          alignItems="center"
          display="flex"
          gap={2}
          onClick={handleClick}
          flex={1}
          cursor="pointer"
        >
          <ChatBubbles />
          <Text fontWeight="semibold">Message patient</Text>
        </Box>

        <Switch
          onChange={({ currentTarget }) =>
            setShowMessages(currentTarget.checked)
          }
          isChecked={showMessages}
        />
      </Box>

      {showMessages && (
        <Box mt={4}>
          <MessageTabs
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSendMessage={handleSendMessage}
            task={task}
          />
        </Box>
      )}
    </Box>
  );
}
