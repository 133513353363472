import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
} from '@chakra-ui/react';
import { BroadcastDetailsTab } from '..';

interface IProps {
  broadcastErrorCount: number;
  onOpen: () => void;
  setSelectedTab: React.Dispatch<React.SetStateAction<BroadcastDetailsTab>>;
}

export default function BroadcastErrorAlert({
  broadcastErrorCount,
  onOpen,
  setSelectedTab,
}: IProps) {
  return (
    <Alert
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mt={2}
      status="error"
      borderRadius="md"
      py={3}
      px={4}
    >
      <Box display="flex">
        <AlertIcon />
        <Box>
          <AlertDescription>
            Broadcast failed to send to {broadcastErrorCount} patient
            {broadcastErrorCount === 1 ? '' : 's'}.
          </AlertDescription>
        </Box>
      </Box>
      <Box display="flex" gap={3}>
        <Button
          variant="unstyled"
          fontSize="sm"
          _hover={{ bgColor: '#fec4c4' }}
          px={2}
          py={2.5}
          onClick={() => setSelectedTab(BroadcastDetailsTab.PATIENTS)}
        >
          View Patients
        </Button>
        <Button
          fontSize="sm"
          variant="outline"
          borderColor="blackAlpha.300"
          _hover={{ bgColor: '#fec4c4' }}
          onClick={onOpen}
        >
          Dismiss
        </Button>
      </Box>
    </Alert>
  );
}
