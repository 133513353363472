import { Badge } from '@chakra-ui/react';
import { BroadcastStatus } from '../../types';

interface IProps {
  status: BroadcastStatus;
}

const optionsByStatus: Record<
  BroadcastStatus,
  { label: string; color: string }
> = {
  [BroadcastStatus.DRAFT]: {
    label: 'Draft',
    color: 'orange',
  },
  [BroadcastStatus.SENT]: {
    label: 'Sent',
    color: 'green',
  },
};

export default function BroadcastStatusBadge({
  status,
}: IProps): JSX.Element | null {
  // @TODO: Remove this as part of the broadcast migration in TECH-1676
  if (!status) {
    return null;
  }

  const { label, color } = optionsByStatus[status];

  return (
    <Badge variant="solid" colorScheme={color}>
      {label}
    </Badge>
  );
}
