import { Icon } from '@chakra-ui/react';

export default function Close(props: any): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="close">
          <path
            id="Vector"
            d="M9.0615 8.0002L12.0302 5.03145C12.1711 4.8908 12.2504 4.69995 12.2506 4.50087C12.2507 4.30178 12.1718 4.11079 12.0312 3.96989C11.8905 3.82899 11.6997 3.74974 11.5006 3.74956C11.3015 3.74939 11.1105 3.8283 10.9696 3.96895L8.00087 6.9377L5.03212 3.96895C4.89123 3.82806 4.70013 3.7489 4.50087 3.7489C4.30162 3.7489 4.11052 3.82806 3.96962 3.96895C3.82873 4.10985 3.74957 4.30095 3.74957 4.5002C3.74957 4.69946 3.82873 4.89056 3.96962 5.03145L6.93837 8.0002L3.96962 10.969C3.82873 11.1098 3.74957 11.3009 3.74957 11.5002C3.74957 11.6995 3.82873 11.8906 3.96962 12.0315C4.11052 12.1723 4.30162 12.2515 4.50087 12.2515C4.70013 12.2515 4.89123 12.1723 5.03212 12.0315L8.00087 9.0627L10.9696 12.0315C11.1105 12.1723 11.3016 12.2515 11.5009 12.2515C11.7001 12.2515 11.8912 12.1723 12.0321 12.0315C12.173 11.8906 12.2522 11.6995 12.2522 11.5002C12.2522 11.3009 12.173 11.1098 12.0321 10.969L9.0615 8.0002Z"
            fill="currentColor"
            fillOpacity="0.92"
          />
        </g>
      </svg>
    </Icon>
  );
}
