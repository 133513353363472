import { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Link, Text, useToast } from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { formatDate } from '../../utils';
import { Events, mixpanel } from '../../analytics';
import { ExternalLink } from '../../assets';
import { IPatient } from '../../types';
import { functions } from '../../firebase';
import DataFigureGridItem from '../DataFigureGridItem';
import { TAB_BAR_HEIGHT } from '.';

interface IProps {
  patient: IPatient;
  patientLoading: boolean;
}

const RecentActivity = forwardRef(
  (
    { patient, patientLoading }: IProps,
    ref: React.ForwardedRef<null>,
  ): JSX.Element => {
    const { engagementActivity, sourceId } = patient;
    const toast = useToast();
    const [unreadCount, setUnreadCount] = useState<number | null>(null);
    const [unreadCountLoading, setUnreadCountLoading] = useState(true);

    useEffect(() => {
      async function fetchUnreadMessagesCount() {
        if (!sourceId) {
          return;
        }

        try {
          const fetchData = httpsCallable(
            functions,
            'sourceGetPatientUnreadMessageCount',
          );

          const { data } = await fetchData({ sourceId });

          setUnreadCount(data as number);
        } catch (error) {
          toast({
            description: 'Error fetching unread messages count.',
            status: 'error',
            title: 'Uh oh!',
          });
        } finally {
          setUnreadCountLoading(false);
        }
      }

      fetchUnreadMessagesCount();
    }, [sourceId, toast]);

    return (
      <Box ref={ref} pt={6} scrollMargin={TAB_BAR_HEIGHT}>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Text fontWeight="medium">Recent Activity</Text>

          <Button
            rounded="md"
            variant="outline"
            size="sm"
            leftIcon={<ExternalLink />}
            as={Link}
            href={`${process.env.REACT_APP_SOURCE_URL}/members/${sourceId}`}
            target="_blank"
            onClick={() => {
              mixpanel.track(Events.EXTERNAL_LINK_CLICKED, {
                context: 'Patient',
                destination: 'Source',
                subcontext: 'Patient: Recent Activity',
              });
            }}
          >
            Open in Source
          </Button>
        </Box>

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
          <DataFigureGridItem
            label="# Threads Unread By Patient"
            isLoading={unreadCountLoading}
          >
            {`${unreadCount ?? 'N/A'}`}
          </DataFigureGridItem>
          <DataFigureGridItem
            isLoading={patientLoading}
            label="Patient Last Read Message On"
          >
            {formatDate(engagementActivity?.lastMessageReadAt?.toDate()) ||
              'N/A'}
          </DataFigureGridItem>
          <DataFigureGridItem
            isLoading={patientLoading}
            label="Patient Last Sent Message On"
          >
            {formatDate(engagementActivity?.lastMessageSentAt?.toDate()) ||
              'N/A'}
          </DataFigureGridItem>
          <DataFigureGridItem
            isLoading={patientLoading}
            label="Last Message Sent To Patient"
          >
            {formatDate(engagementActivity?.lastMessageReceivedAt?.toDate()) ||
              'N/A'}
          </DataFigureGridItem>
        </Box>
      </Box>
    );
  },
);

export default RecentActivity;
