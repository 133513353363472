import { Box, Text } from '@chakra-ui/react';
import CopyButton from './CopyButton';
import { TSnippetName } from '../../analytics';

interface IProps {
  copyEventName?: TSnippetName;
  figureText: string;
  label: string;
}

export default function DataFigureLabel({
  copyEventName,
  figureText,
  label,
}: IProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={1}
    >
      <Text
        textTransform="uppercase"
        fontSize="xs"
        fontWeight="bold"
        color="blackAlpha.700"
      >
        {label}
      </Text>

      {Boolean(figureText) && (
        <CopyButton
          copyEventName={copyEventName}
          label={`${label.toLowerCase()} figure`}
          text={figureText}
        />
      )}
    </Box>
  );
}
