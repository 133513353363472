import { forwardRef } from 'react';
import { Box, Link } from '@chakra-ui/react';
import { Events, mixpanel } from '../../analytics';
import { IPanelSection } from '.';

interface IProps {
  sections: IPanelSection[];
  sectionInFocus: IPanelSection | null;
  onTabClick: (section: IPanelSection) => void;
}

const PatientDetailsPanelTabs = forwardRef(
  (
    { sections, sectionInFocus, onTabClick }: IProps,
    ref: React.LegacyRef<HTMLDivElement>,
  ) => {
    function handleOnClick(section: IPanelSection) {
      mixpanel.track(Events.PATIENT_SECTION_VIEWED, {
        section: section.event_property,
      });

      onTabClick(section);
    }

    return (
      <Box
        ref={ref}
        bg="white"
        zIndex={100}
        position="sticky"
        top={0}
        borderBottom="1px solid"
        borderTop="1px solid"
        borderColor="blackAlpha.100"
        display="flex"
        flexDirection="row"
        overflowX="scroll"
        gap={6}
        px={6}
        boxShadow="sm"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {sections.map((s) => {
          // a null ref means the component for a specific section is null (i.e. bp data doesn't exist for this patient),
          // so don't display a tab for that non-existent section
          const isFocused = sectionInFocus?.title === s.title;
          if (!s.ref) {
            return null;
          }
          return (
            <Link
              key={s.title}
              ref={s.tabRef}
              _hover={{ textDecoration: 'none' }}
              fontWeight="medium"
              color={isFocused ? 'blue.600' : 'blackAlpha.600'}
              onClick={() => handleOnClick(s)}
              whiteSpace="nowrap"
              borderColor="blue.600"
              borderBottom={isFocused ? '2px solid' : undefined}
              py={2}
            >
              {s.title}
            </Link>
          );
        })}
      </Box>
    );
  },
);

export default PatientDetailsPanelTabs;
